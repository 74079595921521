export const userGroupMaps: any = {
  FREE: ["FREE"],
  PRO: ["FREE", "PRO"],
  TRIAL: ["FREE", "PRO"],
  PRO_PLUS: ["FREE", "PRO", "PRO_PLUS"],
  CREATOR_PLAT: ["FREE", "PRO", "PRO_PLUS", "CREATOR_PLAT"],
  INTERNAL: ["FREE", "PRO", "PRO_PLUS", "CREATOR_PLAT", "INTERNAL"],
};

export const getCardTitle = (card: {
  scopeUse: string | string[];
  scopeVis: string | string[];
  ui: { title: any };
}) => {
  const isAdminOnly =
    card.scopeUse.length === 1 &&
    card.scopeVis.length === 1 &&
    card.scopeUse.includes("INTERNAL") &&
    card.scopeVis.includes("INTERNAL");

  return `${isAdminOnly ? "*" : ""}${card.ui.title}${isAdminOnly ? "*" : ""}`;
};

export const needsPro = (cardScopes: string | string[]) => {
  return cardScopes.includes("PRO") && !cardScopes.includes("FREE");
};

export const needsProPlus = (cardScopes: string | string[]) => {
  return cardScopes.includes("PRO_PLUS") && !cardScopes.includes("FREE");
};

export const needsCreatorPlat = (cardScopes: string | string[]) => {
  return cardScopes.includes("CREATOR_PLAT") && !cardScopes.includes("FREE");
};

export const hasScope = (
  user: { userGroup: { type: string }; userScopes: string[] },
  requiredScopes: string[],
) => {
  if (!user?.userGroup?.type) return false;
  if (!requiredScopes.length) return true;

  return requiredScopes.some((x) =>
    userGroupMaps[user.userGroup.type].includes(x),
  );
};

export const filters = [
  { title: "proOnly", label: "Show Pro only Cards" },
  { title: "myCards", label: "Enabled Cards" },
];

export const filterKeys = filters.map((x) => x.title);
