import React from "react";
import { Flex, Box, Image } from "rebass/styled-components";
import styled from "styled-components";
import { Large3, Body, Headline } from "../../../../Components/Text";
import { SideRailContent } from "../../../../Components/Wrappers/Existing/contentRails.js";
import { CreatorCard } from "../../../../Components/CreatorCard";

import { Divider } from "../../../../Components/Divider";

// @John
// Single file import, Component with props
// <TypeIcons type={type} />
//
import { ReactComponent as Quotes } from "../../../../../assets/images/studio/quotes.svg";
import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { getCardTitle } from "../../../../Utilities/Studio";
import { TagRow } from "../../../../Components/CreatorCard/Components/TagRow";
import { LabeledIcon } from "../StudioIcons";
import { AddCard } from "../../../../Components/CreatorCard/Components/AddCard";
import { ReactComponent as Mythic } from "../../../../Assets/Tiers/Badges/mythic.svg";
import { ReactComponent as Legendary } from "../../../../Assets/Tiers/Badges/legendary.svg";
import { ReactComponent as Rare } from "../../../../Assets/Tiers/Badges/rare.svg";
import { ReactComponent as Premium } from "../../../../Assets/Tiers/Badges/premium.svg";
import { ReactComponent as Standard } from "../../../../Assets/Tiers/Badges/standard.svg";
import { Tooltip } from "../../../../Components/Tooltip";
import { RarityTooltipContent } from "../../../../Components/Tooltip/Components/RarityToolTIp";
import { Link } from "react-router-dom";
import { CampaignFootnote } from "../../../../Components/CampaignFootnote";

export function CardDetail({
  card,
  loading,
  handleToggle,
  setModal,
  setCreatorCardModal,
  onQuery,
  isModal,
  handleModalClose,
  hasScope,
}: any) {
  if (loading) return <CardLoading />;
  const showCampaignInfo = card?.ui?.campaign;
  const campaignBlurb = card?.ui?.campaign?.campaignBlurbs?.creatorCard;
  const color = card?.ui?.campaign?.color;

  return (
    <StyledSideRail enabled={true} showOnMobile={true}>
      <StyledHead justifyContent="space-between">
        <Flex flexDirection="column" minWidth="65%">
          <StyledCreatorCard
            isModal={isModal}
            card={card}
            setModal={setModal}
            handleCardClick={() => {}}
            handleModalClose={handleModalClose}
          />
        </Flex>

        <Flex
          minWidth="33%"
          flexDirection="column"
          ml={[0, 0, 0, 4]}
          mt={[3, 0]}
        >
          <Flex alignItems={["center"]} justifyContent="space-between">
            <StyledLarge3 mr={4}>{getCardTitle(card)}</StyledLarge3>
            <AddCard
              card={card}
              handleToggle={handleToggle}
              handleModalClose={handleModalClose}
              size={24}
            />
          </Flex>

          <Divider mt={4} />

          <CardMeta flexDirection="column" mt={2} maxWidth="100%">
            <Box flex={1}>
              <Flex flexDirection="row" justifyContent={"space-between"}>
                <Flex flexDirection="row">
                  <TagRow
                    card={card}
                    onQuery={onQuery}
                    setCreatorCardModal={setCreatorCardModal}
                    isModal={isModal}
                  />{" "}
                </Flex>

                <Flex flexShrink={0}>
                  <Tooltip
                    position={{
                      top: "-35px",
                      right: "-5px",
                    }}
                    hide={false}
                    align="right"
                    theme={getVariant(card.ui.rarity)}
                    timeout={true}
                    tooltipContent={
                      <RarityTooltipContent rarity={card.ui.rarity} />
                    }
                  >
                    <Box
                      onClick={(e) => {
                        setModal({
                          type: card.ui.rarity,
                          data: { card: card, hasScope: hasScope },
                        });
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <TierBadge rarity={card.ui.rarity} />
                    </Box>
                  </Tooltip>
                </Flex>
              </Flex>
              <Headline mt={6}>Description</Headline>
              <Body mt={2} color="chalk">
                {card.ui.explanation}
              </Body>
              <Headline mt={6} mb={3}>
                Supported Games
              </Headline>

              <Flex flexWrap="wrap" style={{ gap: "10px" }}>
                <LabeledIcon
                  category={card?.facet?.game[0] ?? ""}
                  onQuery={onQuery}
                  setCreatorCardModal={setCreatorCardModal}
                  queryType={"game"}
                  isModal={isModal}
                />
                <LabeledIcon
                  category={card?.facet?.game[1] ?? ""}
                  onQuery={onQuery}
                  setCreatorCardModal={setCreatorCardModal}
                  queryType={"game"}
                  isModal={isModal}
                />
                <LabeledIcon
                  category={card?.facet?.game[2] ?? ""}
                  onQuery={onQuery}
                  setCreatorCardModal={setCreatorCardModal}
                  queryType={"game"}
                  isModal={isModal}
                />
                <LabeledIcon
                  category={card?.facet?.game[3] ?? ""}
                  onQuery={onQuery}
                  setCreatorCardModal={setCreatorCardModal}
                  queryType={"game"}
                  isModal={isModal}
                />
                <LabeledIcon
                  category={card?.facet?.game[4] ?? ""}
                  onQuery={onQuery}
                  setCreatorCardModal={setCreatorCardModal}
                  queryType={"game"}
                  isModal={isModal}
                />
              </Flex>
            </Box>
          </CardMeta>

          {showCampaignInfo && (
            <>
              {" "}
              <Divider mt={4} />{" "}
              <CampaignFootnote description={campaignBlurb?.description} />
            </>
          )}
        </Flex>
      </StyledHead>
    </StyledSideRail>
  );
}

function CardLoading() {
  return (
    <StyledSideRail enabled={true} showOnMobile={true}>
      <SideRailContent>
        <Box>
          <LoadingSkeleton
            style={{ borderRadius: "10px", flex: 1 }}
            height={400}
          />
        </Box>

        <Divider mt={1} />
        <CardMeta mt={2}>
          <Box flex={1}>
            <LoadingSkeleton
              style={{ borderRadius: "10px", flex: 1, height: "100%" }}
            />
          </Box>
          <Box flex={1}>
            <LoadingSkeleton
              height={100}
              style={{ borderRadius: "10px", flex: 1, height: "100%" }}
            />
          </Box>
        </CardMeta>
      </SideRailContent>
    </StyledSideRail>
  );
}

export const TierBadge = ({ rarity }: any) => {
  //@chris manages badges
  if (rarity === 500) return <StyledMythic />; //mythic
  if (rarity === 400) return <StyledLegendary />; //legendary
  if (rarity === 300) return <StyledRare />; //rare
  if (rarity === 200) return <StyledPremium />; //premium
  if (rarity === 100) return <StyledStandard />; //standard
  return <></>;
};

export const getVariant = (rarity: any) => {
  if (rarity === 500) return "mythicTooltip"; //mythic
  if (rarity === 400) return "legendaryTooltip"; //legendary
  if (rarity === 300) return "rareTooltip"; //rare
  if (rarity === 200) return "premiumTooltip"; //premium
  if (rarity === 100) return "standardTooltip"; //standard
  return "";
};

const StyledMythic = styled(Mythic)`
  height: 36px;
  width: 36px;
  padding-top: 1px;
`;

const StyledLegendary = styled(Legendary)`
  height: 34px;
  width: 34px;
  padding-top: 1px;
`;

const StyledRare = styled(Rare)`
  height: 36px;
  width: 36px;
  padding-top: 1px;
`;

const StyledPremium = styled(Premium)`
  height: 36px;
  width: 36px;
  padding-top: 1px;
`;

const StyledStandard = styled(Standard)`
  height: 36px;
  width: 36px;
  padding-top: 1px;
`;

const StyledCreatorCard = styled(CreatorCard)`
  border-radius: 10px;
`;

const StyledHead = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${(props: any) => props.theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const StyledLarge3 = styled(Large3)`
  font-weight: 600;
`;

const StyledSideRail = styled(Flex)<any>`
  width: 100%;
  flex-direction: column;
`;

const CardMeta = styled(Flex)`
  gap: 30px;
  @media (max-width: ${(props: any) => props.theme.breaks.standard}) {
    flex-direction: column;
  }
`;

export const StyledQuotes = styled(Quotes)`
  width: 26px;
  height: 26px;
  position: relative;
  top: 0;
  left: 0;
`;
