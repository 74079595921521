export type TStudioIconProps = {
  type: EStudioIconType;
  size: number | String[];
  styles?: any;
};
export enum EStudioIconType {
  Music = "Music",
  Transitions = "Transitions",
  IntrosOutros = "Intros / Outros",
  Intros = "Intros",
  Overlays = "Overlays",
  Memes = "Memes",
  Thumbnails = "Thumbnails",
  SpecialFX = "Special FX",
  AudioFX = "Audio FX",
  Outros = "Outros",
  CS2 = "CS2",
  CSGO = "CS:GO",
  Dota2 = "Dota 2",
  Fortnite = "Fortnite",
  LoL = "LoL",
}
