import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Flex } from "rebass/styled-components";

import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import {
  ContainerWrapper,
  ContentContainer,
  PinnedFooterRow,
  SaveButton,
  StyledModal,
} from "./styledComponents";
import {
  FullPageBackgroundContainer,
  Page,
  ResponsiveColumn,
  StaticColumn,
  StyledDivider,
} from "../../../StyledComponents";
import { Header } from "../../Header";
import { useTranslation } from "react-i18next";
import { SignaturePreview } from "../../SignatureSelector/Components/SignaturePreview";
import { SignatureSelector } from "../../SignatureSelector";
import { LoadingSpinner } from "../../Lottie";
import { SignatureSelectorViewModel } from "../../SignatureSelector/ViewModel";
import { SignatureSelectorState } from "../../../State/loadoutSelector";
import { Button } from "../../Button";
import { useWindowSize } from "../../../../hooks";
import theme from "../../../../theme/themeThree";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;
export const SignatureSelectorModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const { t } = useTranslation();
  const MEDIA_PREFIX = `${REACT_APP_S3_CLIP_BUCKET}/static/ftue`;
  const { width = 0 } = useWindowSize();
  const standardToNumber = parseInt(theme.breaks.standard.replace("px", ""));
  if (allstarModalState.isOpen !== ModalType.SignatureSelector) return <></>;
  return (
    <>
      <StyledModal
        isModalOpen={allstarModalState.isOpen === ModalType.SignatureSelector}
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.SignatureSelector : ModalType.None,
          })
        }
        handleModalClose={undefined}
        disableOutsideClose={undefined}
      >
        <ContainerWrapper
          flexDirection={"column"}
          style={{ position: "relative" }}
          className="total-wrapper"
        >
          <ContentContainer>
            <FullPageBackgroundContainer
              backgroundImage={`${MEDIA_PREFIX}/9_cs.webp`}
            />
            <ResponsiveColumn>
              <Flex flexDirection={"column"}>
                <Header
                  headerText={t("Set your Signature look")}
                  subHeaderText={t(
                    "Want to brand your clips? Update your theme, style, and settings to personalize your clips",
                  )}
                />
              </Flex>
              <StyledDivider />
              {width > standardToNumber && <SignaturePreview />}
            </ResponsiveColumn>
            <StaticColumn>
              <Page>
                {width <= standardToNumber && (
                  <>
                    <SignaturePreview />
                  </>
                )}
                <SignatureSelector />
                <Flex>
                  <Save />
                </Flex>
              </Page>
            </StaticColumn>
          </ContentContainer>
        </ContainerWrapper>
      </StyledModal>
    </>
  );
};

const Save = () => {
  const { saveSignature } = SignatureSelectorViewModel();
  const [signatureSelectorState, setSignatureSelectorState] = useRecoilState(
    SignatureSelectorState,
  );

  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const [loading, setLoading] = useState(false);

  return (
    <PinnedFooterRow>
      <Button
        size="small"
        flex={true}
        classification="roundedFilledAlt"
        onClick={async () => {
          setAllstarModalState({
            isOpen: ModalType.None,
            data: {},
            functions: {},
          });
        }}
      >
        Close
      </Button>

      {loading && <LoadingSpinner width={30} height={30} />}

      {!loading && (
        <SaveButton
          size="small"
          classification="roundedFilled"
          disabled={!signatureSelectorState.allowSave}
          onClick={async () => {
            setLoading(true);
            await saveSignature({ isFtue: false });
            setLoading(false);
          }}
        >
          Save & Close
        </SaveButton>
      )}
    </PinnedFooterRow>
  );
};
