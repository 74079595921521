import React from "react";
import { Box, Flex, Image } from "rebass/styled-components";
import { MdVolumeUp, MdVolumeOff } from "react-icons/md";
import { space, color } from "styled-system";
import styled from "styled-components";
import { HiOutlineStar } from "react-icons/hi";
import { MdBrush } from "react-icons/md";
import { TGameLogoProps, TWithStudioIcon } from "./@types";
import { useHistory } from "react-router-dom";
import { ESupportedGames } from "../../@types";
import cs2SVG from "../../Assets/GameLogos/cs2.svg";
import csgoSVG from "../../Assets/GameLogos/csgo.svg";
import dotaSVG from "../../Assets/GameLogos/dota.svg";
import leagueSVG from "../../Assets/GameLogos/league.svg";
import fortniteSVG from "../../Assets/GameLogos/fortnite.svg";
/*  Our Typings */

/*  Our LOCAL component imports */

/*  Our GLOBAL component imports */

//Not sure this is considered an icon, but its usually small enough to be one imo
export function Callout(props: any) {
  return (
    <Box variant={props.variant ? props.variant : "callout"}>
      {props.children}
    </Box>
  );
}

const StyledUnmuted = styled(MdVolumeUp).attrs((props: any) => ({
  ...props,
}))((props) => props.styles, space, color);

export const UnMutedIcon = (props: any) => {
  return <StyledUnmuted {...props} />;
};

UnMutedIcon.defaultProps = {
  size: 24,
};

const StyledMuted = styled(MdVolumeOff).attrs((props) => ({
  ...props,
}))((props: any) => props.styles, space, color);

export const MutedIcon = (props: any) => {
  return <StyledMuted {...props} />;
};

MutedIcon.defaultProps = {
  size: 24,
};

export const SubscriptionsIcon = styled(HiOutlineStar).attrs((props: any) => ({
  ...props,
}))((props) => props.styles, space, color);

const Wrapper = styled(Flex)`
  max-height: 29px;

  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const AllstarStudioIcon = styled<any>(Box)`
  display: flex;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-right: ${(props: any) => (props.$shrinkMobile ? "0" : "5px")};
  }
`;

export const WithStudio = ({ to, mt, $shrinkMobile }: TWithStudioIcon) => {
  const history = useHistory();
  return (
    <Wrapper
      variant="studio"
      mt={mt ? mt : 0}
      onClick={() => {
        history.push(`${to ? to : `/studio/intro`}`);
      }}
    >
      <AllstarStudioIcon
        $shrinkMobile={$shrinkMobile}
        size="16px"
        as={MdBrush}
      />
    </Wrapper>
  );
};

export function GameLogos({ type, size, styles }: TGameLogoProps) {
  const defaultStyles = { marginRight: "8px" };
  styles = { ...defaultStyles, ...styles };
  switch (type) {
    case ESupportedGames.CS2:
      return <Image src={cs2SVG} size={size} {...styles} />;
    case ESupportedGames.CSGO:
      return <Image src={csgoSVG} size={size} {...styles} />;
    case ESupportedGames.DOTA2:
      return <Image src={dotaSVG} size={size} {...styles} />;
    case ESupportedGames.FORTNITE:
      return <Image src={fortniteSVG} size={size} {...styles} />;
    case ESupportedGames.LOL:
      return <Image src={leagueSVG} size={size} {...styles} />;
    default:
      return <Image src={leagueSVG} size={size} {...styles} />;
  }
}
