import { atom, RecoilState } from "recoil";
import { TMatchHistory, TSteamFriend } from "../Views/MatchHistoryCS2/@types";
import { ESupportedGames } from "../@types";

export type TMatchHistoryState = {
  totalMatches: number;
  totalAvailableClips: number;
  steamId: string;
  matchHistoryData: TMatchHistory[];
  currentPageHistory: TMatchHistory[];
  selected: TMatchHistory;
  indexOfSelected: number;
  clipsRequested: TClipsRequested[];
  montagesRequested: TMontageRequest[];
  page: number;
  displayMobileMoments: boolean;
  steamFriends: TSteamFriend[];
  hasASignature: boolean;
};

export type TClipsRequested = {
  round: number;
  matchId: string;
  steamId: string;
  ts: number;
};

export type TMontageRequest = {
  type: "MH" | "SH";
  matchId: string;
  steamIds: string[];
  ts: number;
  trackId: string;
};

interface IMatchPreviewStatus {
  success: boolean;
  message: string;
}

export type TMatchPreviewStatus = {
  [key: string | number]: IMatchPreviewStatus;
};

export const MatchHistorySession: RecoilState<TMatchHistoryState> = atom({
  key: "MatchHistorySession_V0.2",
  default: {
    totalMatches: 0,
    totalAvailableClips: 0,
    steamId: "",
    matchHistoryData: [] as TMatchHistory[],
    currentPageHistory: [] as TMatchHistory[],
    selected: {} as TMatchHistory,
    indexOfSelected: 0,
    clipsRequested: [] as TClipsRequested[],
    montagesRequested: [] as TMontageRequest[],
    page: 1,
    displayMobileMoments: false as boolean,
    steamFriends: [] as TSteamFriend[],
    hasASignature: true as boolean,
  },
});

export const MatchPreviewStatus: RecoilState<TMatchPreviewStatus> = atom({
  key: "MatchPreviewStatus_V0.1",
  default: {},
});

export const MatchHistoryGame = atom({
  key: "MatchHistoryGame_V0.1",
  default: ESupportedGames.ALL,
});

export const MatchHistoryViewable = atom({
  key: "MatchHistoryViewable_V0.1",
  default: {
    id: 0,
  },
});

export const MatchHistoryPanelOpen = atom({
  key: "MatchHistoryPanelOpen_V0.1",
  default: {
    lol: false,
  },
});
