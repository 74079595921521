import React, { forwardRef } from "react";
import styled from "styled-components";
import { HiCheck } from "react-icons/hi";
import { GoDash } from "react-icons/go";

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: ${(props) => props.theme.checkbox.width}px;
  height: ${(props) => props.theme.checkbox.height}px;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Indicator = styled.span`
  background: transparent;
  border: 2px solid
    ${(props) =>
      props.error
        ? props.theme.colors.error
        : props.borderColor || props.theme.colors.ice};
  border-radius: 6px;
  color: transparent;
  display: inline-flex;
  height: ${(props) => props.theme.checkbox.height}px;
  position: absolute;
  align-items: center;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  transition: background-color 0.1s ease, border-color 0.1s ease;
  vertical-align: middle;
  width: ${(props) => props.theme.checkbox.width}px;
  z-index: 1;
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.error
      : props.borderColor || props.theme.colors.chalkAlpha40};
  input:focus + &,
  input:hover + & {
    border-color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.envy};
  }

  input.partial + &,
  input:checked + & {
    background-color: ${(props) => props.theme.colors.envy};
    border-color: ${(props) => props.theme.colors.envy};
    color: ${(props) => props.theme.colors.ice};
  }
`;

const Checkmark = styled(HiCheck)`
  height: 16px;
  width: 16px;
  position: relative;
`;

const Partial = styled(GoDash)`
  height: 16px;
  width: 16px;
  position: relative;
`;

const Checkbox = forwardRef((props, ref) => {
  return (
    <>
      <Input
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        type="checkbox"
        name={props.name}
        className={props.partial ? "partial" : false}
        id={props.id}
        ref={ref}
        onChange={props.onChange}
        onClick={(e) => {
          if (props.onClick) {
            props.onClick(e);
          }
        }}
      />
      <Indicator error={props.error} borderColor={props.borderColor}>
        {props.partial ? <Partial /> : <Checkmark />}
      </Indicator>
    </>
  );
});

export { Checkbox };
