import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { ECrossSitePromotionType } from "../../@types";
import { AllstarSiteData, PartnerViewSession } from "../../State";
import { PromoSession } from "../../State/promoSession";
import { FirstLandTakeover } from "./Components/FirstLandTakeover";
import { SideRailAd } from "./Components/SideRailAd";
import { CrossSitePromotionViewModel } from "./ViewModel";

type CrossSitePromotionProps = {
  type?: ECrossSitePromotionType;
  doneLoadingCallback?: (promotionsData: any) => boolean;
  loadDataOnly?: boolean;
};

export const CrossSitePromotion = ({
  type,
  loadDataOnly,
}: CrossSitePromotionProps) => {
  const [partnerViewSession, setPartnerViewSession] =
    useRecoilState(PartnerViewSession);
  const { promotionsData, promotionsLoading }: any =
    CrossSitePromotionViewModel();
  const [siteData, setSiteData] = useRecoilState(AllstarSiteData);
  const [loadedSiteData, setLoadedSiteData] = useState(false);

  const [promoSession, setPromoSession] = useRecoilState(PromoSession);

  useEffect(() => {
    if (promotionsData && loadDataOnly && !promotionsLoading) {
      // console.log(promotionsData);
      setPromoSession({
        ...promoSession,
        ...promotionsData.promotions,

        loaded: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionsLoading]);

  if (promotionsLoading) return <></>;

  switch (type) {
    case ECrossSitePromotionType.FirstLandTakeover:
      if (promotionsData?.promotions?.activeSiteFirstLandModal?.length)
        return (
          <FirstLandTakeover
            overlaySlides={promotionsData?.promotions?.activeSiteFirstLandModal}
          />
        );
      else return <></>;
    case ECrossSitePromotionType.SideRailAd:
      if (promotionsData?.promotions?.activeSiteSideRailAd)
        return (
          <SideRailAd {...promotionsData?.promotions?.activeSiteSideRailAd} />
        );
      else return <></>;

    case ECrossSitePromotionType.VideoPlayerCTA:
      if (
        partnerViewSession?.overlayConfig?.display &&
        promotionsData?.promotions?.activeSiteVideoPlayerPromos?.length &&
        !partnerViewSession.promotion.display
      ) {
        setPartnerViewSession({
          ...partnerViewSession,
          promotion: {
            ...promotionsData?.promotions?.activeSiteVideoPlayerPromos[0],
            display: true,
          },
        });
      }
      return <></>; // we dont need to return content here, but we need the viewmodel to gather data and IF it is a partnerView session, set the data. For the overlay
    case ECrossSitePromotionType.CarouselSlide:
      if (promotionsData?.promotions?.activeCarouselPromos?.length) {
        if (loadDataOnly && !loadedSiteData) {
          setLoadedSiteData(true);
          //needs to acceept multiple sources

          const promosToDisplay = [];

          for (const promotion of promotionsData?.promotions
            ?.activeCarouselPromos) {
            promosToDisplay.push({
              ...promotion,
              video: promotion.image,
              image: promotion.image,
              buttonCopy: promotion.buttonCopy,
            });
          }
          setSiteData({
            ...siteData,
            promotions: promosToDisplay,
          });
          return <></>;
        } else {
          return <></>;
          // return (
          //   <CarouselPromotion
          //     {...promotionsData?.promotions?.siteCarouselSlide}
          //   />
          // );
        }
      } else {
        if (!loadedSiteData && !promotionsData?.promotions?.siteCarouselSlide) {
          setLoadedSiteData(true);
          setSiteData({
            ...siteData,
            promotions: undefined,
          });
        }

        return <></>;
      }

    default:
      return <></>;
  }
};
