import { useEffect, useState, useRef } from "react";
import { useGetUser } from "./userStore";
import { Api, getWelcomeQuery, URLS } from "./utils";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useCallback } from "react";
import { useMemo } from "react";
// import { useIsDesktopApp } from "./MVVM/Hooks/useIsDesktopApp";

export function useFetch(config, useHandler) {
  /**
   * react-query lite. Abstracts data fetching/mutations and appends auth headers
   *
   * @params
   * config: string|object|undefined - config passed to axios. For get requests, the url string can be passed
   * useHandler: boolean - For requests that should be triggered instantly, the hook initiates the
   *    api call on first render. If you'd rather control when the request is made (e.g on post), then
   *    set useHander to true. The hook returns the fetch handler so you can call whenever with the
   */

  const [query, setQuery] = useState({
    status: useHandler ? "idle" : "loading",
    error: null,
    data: null,
    statusCode: null,
  });
  let stringified = undefined;

  if (config && !useHandler) {
    // poor man's deep clone
    stringified = JSON.stringify(
      typeof config === "string" ? { url: config } : config,
    );
  }

  const fetch = useCallback(async (fetchConfig, authToken) => {
    setQuery({
      status: "loading",
      error: null,
      data: null,
    });

    try {
      const data = await Api(fetchConfig, authToken);
      setQuery({
        status: "success",
        error: null,
        data,
      });
    } catch (e) {
      setQuery({
        status: "error",
        error: e.response?.data ?? e,
        statusCode: e.response?.status,
        data: null,
      });
    }
  }, []);

  const handler = useMemo(() => ({ fetch, query }), [fetch, query]);

  useEffect(() => {
    if (!useHandler) {
      const fetchConfig = JSON.parse(stringified);
      fetch(fetchConfig);
    }
  }, [stringified, useHandler, fetch]);

  return handler;
}

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches,
  );

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    if ("addEventListener" in media) {
      media.addEventListener("change", listener);
      return () => media.removeEventListener(listener);
    } else {
      media.addListener(listener);
      return () => media.removeListener(listener);
    }
  }, [matches, query]);

  return matches;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

//this is broken i need to figure out how to make it work!
export function useResize(myRef) {
  const [width, setWidth] = useState(myRef.current.offsetWidth);
  const [height, setHeight] = useState(myRef.current.offsetHeight);

  const handleResize = () => {
    setWidth(myRef.current.offsetWidth);
    setHeight(myRef.current.offsetHeight);
  };

  useEffect(() => {
    myRef.current && myRef.current.addEventListener("resize", handleResize);
    return () => {
      myRef.current.removeEventListener("resize", handleResize); //eslint-disable-line
    };
  }, [myRef]); //eslint-disable-line

  return { width, height };
}

export function useTimeout(milliseconds, callback) {
  useEffect(() => {
    const timer = setTimeout(callback, milliseconds);
    return () => clearTimeout(timer);
  }, []); //eslint-disable-line
}

export function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;

      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current], // eslint-disable-line
  );
  return [ref, value];
}

export function useIsMounted() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted.current;
}

export function useCanPublishClip() {
  // this is bad, this is just isAdmin.
  const user = useGetUser();
  return user?.admin;
}

export function useCanShowClipDate(clip) {
  const user = useGetUser();
  if (!user || !clip) return false;

  return (
    user.admin ||
    clip._id === user._id ||
    ["williamgarnett37@gmail.com"].includes(user.sub)
  );
}

export function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}
