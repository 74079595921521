import React from "react";
import { HiChevronDown } from "react-icons/hi";
import styled from "styled-components";
import { Flex, Image } from "rebass/styled-components";

/*  Our Typings */

/*  Our LOCAL component imports */

/*  Our GLOBAL component imports */
import theme from "../../../../../theme/themeThree";
import { Footnote } from "../../../../Components/Text";
import audioSVG from "../../../../../assets/images/studio/tags/audio.svg";
import overlaySVG from "../../../../../assets/images/studio/tags/overlays.svg";
import musicSVG from "../../../../../assets/images/studio/tags/music.svg";
import memesSVG from "../../../../../assets/images/studio/tags/memes.svg";
import outrosSVG from "../../../../../assets/images/studio/tags/outro_icon.svg";
import introsSVG from "../../../../../assets/images/studio/tags/intros.svg";
import transitionsSVG from "../../../../../assets/images/studio/tags/transitions.svg";
import thumbnailsSVG from "../../../../../assets/images/studio/tags/thumbnails.svg";
import specialfxSVG from "../../../../../assets/images/studio/tags/specialFX.svg";
import hashtagSVG from "../../../../../assets/images/studio/tags/hashtag.svg";
import cs2SVG from "../../../../Assets/GameLogos/cs2.svg";
import csgoSVG from "../../../../Assets/GameLogos/csgo.svg";
import dotaSVG from "../../../../Assets/GameLogos/dota.svg";
import leagueSVG from "../../../../Assets/GameLogos/league.svg";
import fortniteSVG from "../../../../Assets/GameLogos/fortnite.svg";
import { useTranslation } from "react-i18next";
import { TStudioIconProps } from "../../@types";

//I think we need to determine what type we are dealing with before the function
//mechanic, type or theme.
export function LabeledIcon({
  category,
  onQuery,
  text,
  setCreatorCardModal,
  queryType,
  isModal,
  styles,
  onClick,
  borderWidth = 1,
  ...props
}: any) {
  const studioTagProps = getStudioTagProps(category);
  const displayText = text ?? studioTagProps.text;
  const { t } = useTranslation("translation", { keyPrefix: "studioFacet" });

  if (category === "") return <></>;
  return (
    <>
      {isModal ? (
        <StudioTag
          alignItems="center"
          flexDirection="row"
          flexShrink="1"
          {...studioTagProps}
          {...props}
          onClick={(e: any) => {
            if (onQuery) {
              onQuery(true, queryType, category, true);
              e.preventDefault();
              e.stopPropagation();
              if (setCreatorCardModal) {
                setCreatorCardModal(false);
              }
            }
          }}
        >
          <StudioIcon type={category} size={16} />
          <StudioModalFootnote>
            {t(displayText, { defaultValue: displayText })}
          </StudioModalFootnote>
        </StudioTag>
      ) : (
        <StudioTag
          alignItems="center"
          borderWidth={borderWidth}
          flexDirection="row"
          flexShrink="1"
          {...studioTagProps}
          {...props}
          onClick={(e: any) => {
            if (onClick) {
              onClick();
            }
            if (onQuery) {
              onQuery(true, queryType, category, true);
              e.preventDefault();
              e.stopPropagation();
              if (setCreatorCardModal) {
                setCreatorCardModal(false);
              }
            }
          }}
        >
          <StudioIcon type={category} size={14} />
          <StudioIconFootnote>
            {t(displayText, { defaultValue: displayText })}
          </StudioIconFootnote>
        </StudioTag>
      )}
    </>
  );
}

//Not done yet, will return studio svg icon or default if type is bad
export function StudioIcon({ type, size, styles }: TStudioIconProps) {
  const defaultStyles = { marginRight: "8px" };
  styles = { ...defaultStyles, ...styles };
  switch (type) {
    case "Music":
      return <StudioCategoryIcon src={musicSVG} size={size} {...styles} />;
    case "Transitions":
      return (
        <StudioCategoryIcon src={transitionsSVG} size={size} {...styles} />
      );
    case "Intros / Outros":
      return <StudioCategoryIcon src={introsSVG} size={size} {...styles} />;
    case "Intros":
      return <StudioCategoryIcon src={introsSVG} size={size} {...styles} />;
    case "Overlays":
      return <StudioCategoryIcon src={overlaySVG} size={size} {...styles} />;
    case "Memes":
      return <StudioCategoryIcon src={memesSVG} size={size} {...styles} />;
    case "Thumbnails":
      return <StudioCategoryIcon src={thumbnailsSVG} size={size} {...styles} />;
    case "Special FX":
      return <StudioCategoryIcon src={specialfxSVG} size={size} {...styles} />;
    case "Audio FX":
      return <StudioCategoryIcon src={audioSVG} size={size} {...styles} />;
    case "Outros":
      return <StudioCategoryIcon src={outrosSVG} size={size} {...styles} />;
    case "CS2":
      return <StudioCategoryIcon src={cs2SVG} size={size} {...styles} />;
    case "CS:GO":
      return <StudioCategoryIcon src={csgoSVG} size={size} {...styles} />;
    case "Dota 2":
      return <StudioCategoryIcon src={dotaSVG} size={size} {...styles} />;
    case "Fortnite":
      return <StudioCategoryIcon src={fortniteSVG} size={size} {...styles} />;
    case "LoL":
      return <StudioCategoryIcon src={leagueSVG} size={size} {...styles} />;
    default:
      return <StudioCategoryIcon src={hashtagSVG} size={size} {...styles} />;
  }
}

function getStudioTagProps(category: any) {
  switch (category) {
    case "Music": {
      return {
        borderColor: "#273D56",
        hoverBorderColor: "#639FFA",
        text: "Music",
      };
    }
    case "Audio FX": {
      return {
        borderColor: "#4A715F",
        hoverBorderColor: "#9FF4C1",
        text: "Audio",
      };
    }
    case "Intros / Outros": {
      return {
        borderColor: "#406779",
        hoverBorderColor: "#86DBFF",
        text: "Intros / Outros",
      };
    }
    case "Intros": {
      return {
        borderColor: "#406779",
        hoverBorderColor: "#86DBFF",
        text: "Intros",
      };
    }
    case "Outros": {
      return {
        borderColor: "#645993",
        hoverBorderColor: "#AA95FE",
        text: "Outros",
      };
    }
    case "Overlays": {
      return {
        borderColor: "#2D6757",
        hoverBorderColor: "#5ADAAC",
        text: "Overlays",
      };
    }
    case "Memes": {
      return {
        borderColor: "#6A6A40",
        hoverBorderColor: "#EDE175",
        text: "Memes",
      };
    }
    case "Thumbnails": {
      return {
        borderColor: "#714A60",
        hoverBorderColor: "#FF95C2",
        text: "Thumbnails",
      };
    }
    case "Special FX": {
      return {
        borderColor: "#71284C",
        hoverBorderColor: "#FF4392",
        text: "Special FX",
      };
    }
    case "Transitions": {
      return {
        borderColor: "#71533D",
        hoverBorderColor: "#FDAA6E",
        text: "Transitions",
      };
    }

    default: {
      return {
        borderColor: theme.colors.chalkAlpha20,
        hoverBorderColor: theme.colors.chalkAlpha60,
        text: category !== "" ? category : "Default",
      };
    }
  }
}

export const Chevron = styled(HiChevronDown).attrs(() => ({
  size: 20,
}))<any>`
  color: ${(props: any) => (props.isExpanded ? props.theme.colors.envy : null)};

  ${(props: any) => (props.isexpanded ? "transform: rotate(180deg);" : null)};
  cursor: pointer;
`;

const StudioTag = styled(Flex)<any>`
  border-radius: 20px;
  min-width: 100px;
  border-width: ${(props) => {
    return props.borderWidth;
  }}px;
  border-style: solid;

  border-color: ${(props: any) => {
    return props.borderColor;
  }};
  padding: 4px 15px;
  &:hover {
    border-color: ${(props: any) => {
      return props.hoverBorderColor;
    }};
    cursor: pointer;
  }
  max-height: 81px;
  align-items: center;
  line-height: 1;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    padding: 2px 10px;
    max-height: 30px;
    min-width: 90px;
  }
`;

const StudioIconFootnote = styled(Footnote)`
  font-size: 12px;
  font-weight: 500;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    font-size: 10px;
  }
`;

const StudioModalFootnote = styled(Footnote)`
  font-size: 14px;
  font-weight: 500;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    font-size: 12px;
  }
`;

const StudioCategoryIcon = styled(Image)<any>``;
