import React from "react";
import { Flex, Image } from "rebass/styled-components";
import { FloatingContainer, Modal } from "../Wrappers";
import styled from "styled-components";
import { Title1, Body, Headline, Title3, Subheader } from "../Text";
import { ReactComponent as Pro } from "../../../assets/images/badges/pro-badge.svg";
import { ReactComponent as Top } from "../../../assets/images/badges/top-badge.svg";
import { ReactComponent as New } from "../../../assets/images/badges/newIcon.svg";
import { ReactComponent as Exlusive } from "../../../assets/images/badges/exclusive-badge.svg";
import { ReactComponent as Legendary } from "../../../MVVM/Assets/Tiers/Badges/legendary.svg";
import { ReactComponent as Rare } from "../../../MVVM/Assets/Tiers/Badges/rare.svg";
import { ReactComponent as Premium } from "../../../MVVM/Assets/Tiers/Badges/premium.svg";
import { ReactComponent as Standard } from "../../../MVVM/Assets/Tiers/Badges/standard.svg";
import { ReactComponent as LegendaryText } from "../../../MVVM/Assets/Tiers/Badges/legendary-text.svg";
import { ReactComponent as RareText } from "../../../MVVM/Assets/Tiers/Badges/rare-text.svg";
import { ReactComponent as PremiumText } from "../../../MVVM/Assets/Tiers/Badges/premium-text.svg";
import { ReactComponent as StandardText } from "../../../MVVM/Assets/Tiers/Badges/standard-text.svg";
import { ReactComponent as Logo } from "../../../MVVM/Assets/Nfluence/nfluence-logo.svg";
import { Button, Link } from "../Button";
import { TIER_STRING_MAP, URLS } from "../../Utilities/Maps";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { AllstarUser, MontageSession, YoutubeShareModal } from "../../State";
import { EView } from "../../Views/CreateMontage";
import Close from "../../../assets/images/floatingannouncement/close.svg"; // eslint-disable-line
import { ECrossSitePromotionType } from "../../@types";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { CrossSitePromotion } from "../../Views/CrossSitePromotion";
import { useIsMobileApp } from "../../Hooks/useIsMobileApp";
import {
  IBaseModalProps,
  IProFeatureModalProps,
  TCampaignCreatorCardModal,
  TCreatorCardTierModalProps,
} from "./@types";

export const ProductAnnouncement = ({ isOpen, setModalOpen }: any) => {
  return (
    <>
      <Modal
        isModalOpen={isOpen}
        setModalOpen={setModalOpen}
        handleModalClose={() => {
          setModalOpen(false);
        }}
        disableOutsideClose={false}
      >
        <FloatingContainer
          flexDirection="column"
          alignItems="stretch"
          justifyContent="center"
          maxWidth="525px"
        >
          <>
            {" "}
            <CloseIcon
              size={36}
              src={Close}
              onClick={() => {
                setModalOpen(false);
              }}
            />
            <CrossSitePromotion
              type={ECrossSitePromotionType.FirstLandTakeover}
            />
          </>
        </FloatingContainer>
      </Modal>
    </>
  );
};

export const CreatorCardNewModal = ({ isOpen, setIsOpen }: IBaseModalProps) => {
  return (
    <Modal
      isModalOpen={isOpen}
      setModalOpen={setIsOpen}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <NewBadge />
          <Flex flexDirection="column">
            <Title1 mt={9} mb={6}>
              New Creator Card
            </Title1>
            <Body mb={6}>Available to all users for a limited time</Body>
            <Flex justifyContent="center">
              {" "}
              <Headline
                mb={1}
                color="ice"
                variant="link.title"
                onClick={() => {
                  document.location.href = "/upgrade";
                }}
              >
                Learn about all the subscription options
              </Headline>
            </Flex>

            <Body mb={5}>to gain access to more creator cards!</Body>
            <Button
              size="small"
              mb={7}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Okay, great!
            </Button>
          </Flex>
        </Flex>
      </FloatingContainer>
    </Modal>
  );
};

export const CreatorCardTopModal = ({
  isOpen,
  setIsOpen,
  handleModalClose,
  disableOutsideClose,
}: IBaseModalProps) => {
  return (
    <Modal
      isModalOpen={isOpen}
      setModalOpen={setIsOpen}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <TopBadge />
          <Flex flexDirection="column">
            <Title1 mt={9}>Rewards Card</Title1>

            <Body mb={5} mt={4}>
              You can unlock this card by participating in Allstar Giveaways,
              getting Clip of the Week or contributing to our Discord channel.
              Only a select few Allstar users will get access to these cards,
              they're kind of special.
            </Body>
            <Button
              size="small"
              flex={true}
              mb={7}
              onClick={() => {
                window.open(
                  "/upgrades://discord.com/invite/playsharestar",
                  "_blank",
                );
                setIsOpen(false);
              }}
            >
              Join Discord
            </Button>
          </Flex>
        </Flex>
      </FloatingContainer>
    </Modal>
  );
};

export const CreatorCardOGModal = ({
  isOpen,
  setIsOpen,
  handleModalClose,
  disableOutsideClose,
}: IBaseModalProps) => {
  return (
    <Modal
      isModalOpen={isOpen}
      setModalOpen={setIsOpen}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <ExclusiveBadge />
          <Flex flexDirection="column">
            <Title1 mt={9}>Who's an OG? You.</Title1>
            <Flex justifyContent="center" fontSize="75px" pb={6}>
              <span role="img" aria-label="">
                🙏
              </span>
            </Flex>
            <Body mb={5}>
              You were here since day one, thanks for supporting us before
              anyone else. Enjoy this special card, exclusive only to true OGs
              like you.
            </Body>
          </Flex>
        </Flex>
      </FloatingContainer>
    </Modal>
  );
};

export const CampaignCreatorCardModal = ({
  card,
  isOpen,
  setIsOpen,
  handleModalClose,
  disableOutsideClose,
  handleCardToggle,
}: TCampaignCreatorCardModal) => {
  const campaignBlurb = card?.ui?.campaign?.campaignBlurbs?.creatorCard;
  return (
    <Modal
      isModalOpen={isOpen}
      setModalOpen={setIsOpen}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <StyledPartnerLogo src={campaignBlurb?.image} />
          <Flex flexDirection="column">
            <Title1 mt={4}>Allstar Competition Card!</Title1>
            <Body mt={4} mb={5}>
              {campaignBlurb?.description}
            </Body>

            <Button
              size="small"
              mb={4}
              to={campaignBlurb?.linkUrl}
              classification={"main"}
            >
              {campaignBlurb?.linkText}
            </Button>

            <Button
              size="small"
              mb={2}
              onClick={() => {
                handleCardToggle(card, true);
                setIsOpen(false);
              }}
              classification={"secondary"}
            >
              No thanks, enable this card
            </Button>
          </Flex>
        </Flex>
      </FloatingContainer>
    </Modal>
  );
};

const StyledPartnerLogo = styled(Image)`
  width: 100px;
`;

export const ProModal = ({
  isOpen,
  setIsOpen,
  body,
}: IProFeatureModalProps) => {
  const history = useHistory();
  const [montageSession] = useRecoilState(MontageSession);
  return (
    <Modal
      isModalOpen={isOpen}
      setModalOpen={setIsOpen}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <ProBadge />
          <Flex flexDirection="column">
            <Title1 mt={9}>Pro Feature</Title1>
            <Flex justifyContent="center">
              {" "}
              <Headline
                mt={4}
                mb={5}
                W
                color="ice"
                variant="link.title"
                onClick={() => {
                  try {
                    window.rudderanalytics.track(`Pro Modal - Button Click`, {
                      view: EView[montageSession.view],
                    });
                  } catch {}

                  history.push(URLS.UPGRADE);
                }}
              >
                Allstar Pro
              </Headline>
            </Flex>

            <Body mb={5}>{body}</Body>

            <Button
              size="small"
              mb={7}
              onClick={() => {
                try {
                  window.rudderanalytics.track(`Pro Modal - Button Click`, {
                    view: EView[montageSession.view],
                  });
                } catch {}
                setIsOpen(false);
              }}
              to={URLS.UPGRADE}
            >
              Upgrade Now
            </Button>
          </Flex>
        </Flex>
      </FloatingContainer>
    </Modal>
  );
};

export const CreatorCardTierModal = ({
  state,
  setState,
  handleModalClose,
  disableOutsideClose,
  handleCardToggle,
}: TCreatorCardTierModalProps) => {
  const allstarUser = useRecoilValue(AllstarUser);
  const history = useHistory();
  const { isMobileApp } = useIsMobileApp();
  const TIER_CONTENT_MAP = {
    500: {
      tier: TIER_STRING_MAP[state.tier],
      badge: <StyledLegendary />,
      badgeText: <LegendaryText />,
      title: i18next.t(`studio.modal.platTitle`),
      link: {
        copy: i18next.t(`studio.modal.platLink`),
        href: "/upgrade",
      },
      subheader: i18next.t(`studio.modal.platSubheader`),
      button: {
        copy: i18next.t(`studio.modal.platButton`),
        href: "/upgrade",
      },
    },
    400: {
      tier: TIER_STRING_MAP[state.tier],
      badge: <StyledLegendary />,
      badgeText: <LegendaryText />,
      title: i18next.t(`studio.modal.platTitle`),
      link: {
        copy: i18next.t(`studio.modal.platLink`),
        href: "/upgrade",
      },
      subheader: i18next.t(`studio.modal.platSubheader`),
      button: {
        copy: i18next.t(`studio.modal.platButton`),
        href: "/upgrade",
      },
    },
    300: {
      tier: TIER_STRING_MAP[state.tier],
      badge: <StyledRare />,
      badgeText: <RareText />,
      title: i18next.t(`studio.modal.rareTitle`),
      link: {
        copy: i18next.t(`studio.modal.rareLink`),
        href: "/upgrade",
      },
      subheader: i18next.t(`studio.modal.rareSubheader`),
      button: {
        copy: i18next.t(`studio.modal.rareButton`),
        href: "/upgrade",
      },
    },
    200: {
      tier: TIER_STRING_MAP[state.tier],
      badge: <StyledPremium />,
      badgeText: <PremiumText />,
      title: i18next.t(`studio.modal.proTitle`),
      link: {
        copy: i18next.t(`studio.modal.proLink`),
        href: "/upgrade",
      },
      subheader: i18next.t(`studio.modal.proSubheader`),
      button: {
        copy: i18next.t(`studio.modal.proButton`),
        href: "/upgrade",
      },
    },
    100: {
      tier: TIER_STRING_MAP[state.tier],
      badge: <StyledStandard />,
      badgeText: <StandardText />,
      title: i18next.t(`studio.modal.standardTitle`),
      link: {
        copy: i18next.t(`studio.modal.standardLink`),
        href: "/upgrade",
      },
      subheader: i18next.t(`studio.modal.standardSubheader`),
      button: {
        copy: i18next.t(`studio.modal.standardButton`),
        href: "/upgrade",
      },
    },
  };

  const content = TIER_CONTENT_MAP[state.tier];

  const getTo = () => {
    if (allstarUser?.loggedIn) return content.link.href;
    else {
      handleCardToggle(state?.data?.card, true);
      return undefined;
    }
  };

  const { t } = useTranslation(`translation`, {
    keyPrefix: "studio.modal",
  });

  if (!state.isOpen || !content) return <></>;

  return (
    <Modal
      isModalOpen={state.isOpen}
      setModalOpen={setState}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
          >
            <Flex mb={[5, 5, 0, 0]} justifyContent="center">
              {" "}
              {content.badge}
            </Flex>
            <Flex justifyContent="center">{content.badgeText}</Flex>
          </Flex>
          <Flex flexDirection="column">
            <Title1 my={4}>{content.title}</Title1>

            {!isMobileApp && (
              <>
                {" "}
                <Flex justifyContent="center">
                  {" "}
                  <Headline
                    mt={4}
                    mb={5}
                    W
                    color="ice"
                    variant="link.title"
                    onClick={() => {
                      window.rudderanalytics.track(
                        `${TIER_STRING_MAP[state.tier]} Modal - Button Click`,
                      );
                      history.push(content.link.href);
                    }}
                  >
                    {content.link.copy}
                  </Headline>
                </Flex>
              </>
            )}

            <Body>{content.subheader}</Body>

            <>
              {!isMobileApp && (
                <>
                  {" "}
                  {state?.data?.hasScope ? (
                    <>
                      {" "}
                      <Button
                        size="small"
                        mb={3}
                        mt={5}
                        onClick={() => {
                          handleCardToggle(state?.data?.card, true);
                          setState({ ...state, isOpen: false });
                          window.rudderanalytics.track(
                            `${TIER_STRING_MAP[state.tier]} - Button Click`,
                          );
                        }}
                      >
                        {t("platButton")}
                      </Button>
                    </>
                  ) : (
                    <Button
                      size="small"
                      mb={3}
                      onClick={() => {
                        setState({ ...state, isOpen: false });
                        window.rudderanalytics.track(
                          `${TIER_STRING_MAP[state.tier]} - Button Click`,
                        );
                      }}
                      to={getTo()}
                    >
                      {!allstarUser?.loggedIn ? (
                        <>Signup</>
                      ) : (
                        <>{content.button.copy}</>
                      )}
                    </Button>
                  )}
                </>
              )}
            </>
            {isMobileApp && (
              <StyledSubheader>
                {" "}
                Subscriptions are always available at our website Allstar.gg
              </StyledSubheader>
            )}
          </Flex>
        </Flex>
      </FloatingContainer>
    </Modal>
  );
};
export const YoutubeUnavailable = () => {
  const { t } = useTranslation(`translation`, {
    keyPrefix: "youtube.modal",
  });
  const [youtubeModalOpen, setYoutubeModalOpen] =
    useRecoilState(YoutubeShareModal);
  if (!youtubeModalOpen.open) return <></>;
  return (
    <Modal
      isModalOpen={youtubeModalOpen.open}
      setModalOpen={setYoutubeModalOpen}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer>
        <CloseIcon
          size={36}
          src={Close}
          onClick={() => {
            setYoutubeModalOpen({ open: false });
          }}
        />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Flex flexDirection="column">
            <Title1 mb={2}>Whoops!</Title1>
            <Title3 justifyContent="center" mb={4}>
              {" "}
              {t("title")}
            </Title3>
            <Body mb={2}>
              {t("body")}

              <Link
                variant="link.title"
                to="/support"
                onClick={() => {
                  setYoutubeModalOpen({ open: false });
                }}
              >
                {t("supportLink")}
              </Link>
            </Body>
            <Button
              size="small"
              mt={6}
              onClick={() => {
                setYoutubeModalOpen({ open: false });
              }}
            >
              Okay, Got it!
            </Button>
          </Flex>
        </Flex>
      </FloatingContainer>
    </Modal>
  );
};

const CompanyLogo = styled(Logo)`
  height: 45px;
  width: auto;
  -webkit-flex-sh
  flex-shrink: 0;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-bottom: 20px;
  }
`;

// eslint-disable-next-line
const CloseIcon = styled(Image)`
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.navy};
  border-radius: 999px;
  &:hover {
    background-color: ${(props) => props.theme.colors.ned};
    transition: 0.3s;
  }
`;

const ExclusiveBadge = styled(Exlusive)`
  top: 0%;
  position: absolute;
  z-index: 99;
  width: 47px;
  height: 70px;
`;

const StyledPremium: any = styled(Premium)`
  top: -7%;
  position: absolute;
  z-index: 99;
  width: 60px;
  height: 60px;
`;

const StyledLegendary: any = styled(Legendary)`
  top: -7%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  z-index: 99;
  width: 60px;
  height: 60px;
`;

const StyledRare: any = styled(Rare)`
  top: -7%;
  position: absolute;
  z-index: 99;
  width: 60px;
  height: 60px;
`;
const StyledStandard: any = styled(Standard)`
  top: -7%;
  position: absolute;
  z-index: 99;
  width: 60px;
  height: 60px;
`;

const ProBadge = styled(Pro)`
  top: 0%;
  position: absolute;
  z-index: 99;
  width: 47px;
  height: 70px;
`;

const NewBadge = styled(New)`
  top: 0%;
  position: absolute;
  z-index: 99;
  width: 47px;
  height: 70px;
`;

const TopBadge = styled(Top)`
  top: 0%;
  position: absolute;
  z-index: 99;
  width: 47px;
  height: 70px;
`;

const StyledSubheader = styled(Subheader)`
  color: ${(props) => props.theme.colors.ice};
  font-weight: 500;
  padding-top: 25px;
`;
