import React, { useEffect } from "react";
import { Box, Flex, Image } from "rebass/styled-components";
import { Body } from "../../Components/Text";
import styled from "styled-components";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const Dreamhack2024 = () => {
  useEffect(() => {
    window.rudderanalytics.page("Dreamhack2024", window.location.pathname);
  }, []);

  return (
    <PageWrapper>
      <Wrapper>
        <Flex justifyContent="center" mt={9}>
          <Image
            src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/dreamhack2024/logos.png`}
          />
        </Flex>
        <StyledHeader>
          <StyledHeadline mb={6}>Allstar.GG at DreamHack 2024</StyledHeadline>
          <Body color="chalk">
            We are excited to announce that Allstar.gg will be at DreamHack
            Dallas this year, bringing an array of exciting activities and
            exclusive offers.
          </Body>
        </StyledHeader>

        <MapContainer justifyContent="space-around" style={{ gap: 34 }} mb={8}>
          <BotContainer
            style={{ gap: 34, flexBasis: "33%" }}
            flexDirection="column"
          >
            <Image
              width="100%"
              src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/dreamhack2024/bot.png`}
            />
            <StyledCard flexDirection="column" justifyContent="center">
              <StyledSubHeadline mb={4}>
                Visit Us At Booth #225
              </StyledSubHeadline>
              <Body color="chalk" fontSize={1}>
                And experience firsthand demonstrations of our cloud based
                content platform, grab some awesome swag, and take advantage of
                our free trials for premium subscriptions.
              </Body>
            </StyledCard>
          </BotContainer>
          <StyledFlex>
            <MapCard>
              <Image
                width="100%"
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/dreamhack2024/map.png`}
              />
            </MapCard>
          </StyledFlex>
        </MapContainer>

        <StyledFlex mb={8} style={{ gap: 34 }}>
          <FlexOne>
            <StyledCard flexDirection="column">
              <Image
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/dreamhack2024/cloutSwag.png`}
              />
              <StyledSubHeadline mb={4}>Awesome Swag</StyledSubHeadline>
              <Body color="chalk">
                Grab Hoodies, Stickers, Allstar Pins, Drawstring Backpacks,
                Exclusive DreamHack Posters, and much more!
              </Body>
            </StyledCard>
          </FlexOne>
          <FlexOne>
            <StyledCard flexDirection="column" justifyContent="space-evenly">
              <StyledSubHeadline mb={4}>Exclusive Giveaway</StyledSubHeadline>
              <Body color="chalk">
                Enter for a chance to win a GEFORCE RTX 3080!
              </Body>
              <Image
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/dreamhack2024/cloutProcessor.png`}
              />
            </StyledCard>
          </FlexOne>
          <FlexOne>
            <StyledCard flexDirection="column">
              <Image
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/dreamhack2024/cloutPlat.png`}
              />
              <StyledSubHeadline mb={4}>
                Free Premium Allstar Subscriptions
              </StyledSubHeadline>
              <Body color="chalk">
                Get free premium tier subscriptions to Allstar!
              </Body>
            </StyledCard>
          </FlexOne>
        </StyledFlex>

        <StyledFlex mb={100} style={{ gap: 34 }}>
          <StyledCard style={{ gap: 34 }}>
            <Image
              src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/dreamhack2024/panel.png`}
              alignSelf={[
                "flex-start",
                "center",
                "center",
                "flex-start",
                "center",
              ]}
            />
            <Flex
              flexDirection="column"
              justifyContent="space-evenly"
              textAlign="left"
            >
              <StyledSubHeadline mb={4}>
                Special Panel Event: The Future of Frag in the New Content Era
              </StyledSubHeadline>
              <Body>Saturday, June 1 @ 10:30AM CST</Body>
              <Body color="chalk">
                Featuring: John DeHart, Gregg Dellarocca, and Anthony Perfetto
              </Body>
            </Flex>
          </StyledCard>
          <StyledCard alignItems="center">
            <StyledSubHeadline mb={4} textAlign="center">
              We Are Excited To Meet You!
            </StyledSubHeadline>
          </StyledCard>
        </StyledFlex>
      </Wrapper>
    </PageWrapper>
  );
};

const MapContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-direction: column;
  }
`;

const BotContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-direction: row;
  }
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const StyledFlex = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const FlexOne = styled(Flex)`
  flex-basis: 33.3%;

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: block;
    width: 100%;
    flex-basis: 100%;
  }
`;

const PageWrapper = styled(Box)`
  margin: 0 auto;
  max-width: 1240px;
  padding-left: 100px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    padding-left: 0;
  }
`;

const Wrapper = styled(Box)`
  padding: 0 34px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    padding: 0 20px;
  }
`;

const StyledHeadline = styled(Body)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.text.large2.fontSize};
  font-weight: ${({ theme }) => theme.text.large3bold.fontWeight};
  line-height: normal;

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    font-size: ${({ theme }) => theme.text.large2.fontSize};
  }

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.text.title1.fontSize};
  }
`;

const StyledSubHeadline = styled(Body)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.text.title1.fontSize};
  font-weight: ${({ theme }) => theme.text.large3bold.fontWeight};
  line-height: normal;
`;

const StyledHeader = styled(Box)`
  margin: 48px auto;
  text-align: center;
  width: 60%;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 100%;
  }
`;

const StyledCard = styled(Flex)`
  background: ${({ theme }) => theme.colors.gray850};
  border-radius: 16px;
  padding: 34px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const MapCard = styled(StyledCard)`
  width: 100%;
`;

export default Dreamhack2024;
