import React from "react";
import { Flex } from "rebass/styled-components";

import { Headline } from "../../../components/text";
import { getClipTitle } from "../../Utilities/Clip";
import {
  TwitterShare,
  FacebookShare,
  ClipboardShare,
  WhatsAppShare,
  TelegramShare,
  RedditShare,
  EmailShare,
} from "../Button/Share";

interface ShareContentsProps {
  clip?: any;
  card?: any;
  shareLink?: string;
}

export function ShareContents({ clip, card, shareLink }: ShareContentsProps) {
  const link =
    shareLink ||
    (card
      ? `https://${window.location.hostname}/studio/${card._id}`
      : `https://${window.location.hostname}/clip?clip=${clip.shareId}`);

  const title = card ? card.ui.title : getClipTitle(clip);

  return (
    <>
      <Flex
        flexDirection="column"
        alignContent="center"
        maxWidth="490px"
        width="100%"
      >
        <Flex
          mb={[5, 7, 7, 7]}
          flexDirection="row"
          justifyContent="space-between"
        >
          <WhatsAppShare link={link} clip={clip}></WhatsAppShare>
          <FacebookShare link={link} clip={clip}></FacebookShare>{" "}
          <TwitterShare link={link} title={title} clip={clip}></TwitterShare>
          <RedditShare link={link} title={title} clip={clip}></RedditShare>
          <TelegramShare link={link} title={title} clip={clip}></TelegramShare>
          <EmailShare link={link} subject={title || ""}></EmailShare>
        </Flex>
        <Headline mb={4}>Copy Link</Headline>
        <ClipboardShare link={link} clip={clip}></ClipboardShare>
      </Flex>
    </>
  );
}
