import React from "react";
import styled, { css } from "styled-components";
import { Validation } from "../../atoms/form/validation";
import { Subheader } from "../../text";
import { Checkbox } from "../../atoms/form/checkbox";
import { Box } from "rebass/styled-components";
import theme from "../../../theme/themeThree";

const containerStyle = css`
  display: ${(props) => (props.flex ? "flex" : "inline-block")};
  padding-left: ${theme.checkbox.height + 15}px;
  padding-top: 2px;
  position: relative;
  vertical-align: middle;

  a {
    font-size: inherit;
    white-space: nowrap;
  }
`;

const Container = styled(Box)`
  ${containerStyle}
`;
const Label = styled.label`
  cursor: pointer;
  flex: ${(props) => props.flex};
  ${containerStyle};
`;

export function CheckboxWithLabel({ wrap, flex, ...props }) {
  const Wrapper = wrap ? (
    <Label flex={flex} htmlFor={props.checkbox?.id} />
  ) : (
    <Container />
  );

  return (
    <>
      {props.error ? (
        <Validation mb={3}>
          {props.error.message ? props.error.message : "Required"}
        </Validation>
      ) : (
        <></>
      )}
      <Wrapper.type
        variant={props.applySpace ? "form" : null}
        {...Wrapper.props}
      >
        <Checkbox
          {...props.checkbox}
          onClick={props.onClick}
          error={props.error}
        />
        <Subheader>{props.children}</Subheader>
      </Wrapper.type>
    </>
  );
}
