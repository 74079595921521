import { RecoilState, atom } from "recoil";

type TPinnedClipWithError = {
  clipId: string;
  errorMessage: string;
};

export const PinnedClipWithError: RecoilState<TPinnedClipWithError> = atom({
  key: "PinnedClipWithError_V0.1",
  default: {
    clipId: "",
    errorMessage: "",
  },
});
