import React from "react";
import styled from "styled-components";
import { Box } from "rebass/styled-components";

const StyledBox = styled(Box)`
  border-radius: 9px;
  background-color: ${(props) => props.theme.colors.midnight};
  color: ${(props) => props.theme.colors.ice};
  width: 100%;
  max-width: 425px;

  @supports (backdrop-filter: blur(70px)) {
    & {
      background-color: ${(props) => props.theme.colors.midnightAlpha50};
      backdrop-filter: blur(70px);
    }
  }
`;

export function FloatingDialog(props) {
  return (
    <StyledBox px={[6, 8]} py={[6, 8]} {...props}>
      {props.children}
    </StyledBox>
  );
}
