import styled from "styled-components";
import { Image } from "rebass/styled-components";

import { FloatingContainer } from "../../Wrappers";

export const StyledFloatingContainer = styled(FloatingContainer)`
  align-items: stretch;
  background: ${({ theme }) => theme.colors.darkerNed};
  border-radius: 16px;
  border-width: 0;
  border: 1px solid ${({ theme }) => theme.colors.ned};
  box-shadow: 0 0 0px #233438;
  flex-direction: column;
  justify-content: center;
  max-width: 1400px;
  padding: 34px;
  position: absolute;
  top: 20px;
  width: 72%;
  z-index: 101;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    width: 90%;
  }
`;

export const CloseIcon = styled(Image)`
  position: absolute;
  right: -38px;
  top: 0px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.ned};
  border-radius: 999px;

  &:hover {
    background-color: ${(props) => props.theme.colors.darkerNed};
    transition: 0.3s;
  }
`;
