import Axios from "axios";
import { getClipTitle } from "../Utilities/Clip";
import { statsigClient } from "../../App";

declare global {
  interface Window {
    rudderanalytics: any;
  }
}

const getItem = (key: string) => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

const track = (event: string, properties: Record<string, string>): void => {
  statsigClient.getAsyncContext().then(({ stableID }) => {
    window.rudderanalytics.track(event, {
      ...properties,
      statsigCustomIDs: ["stableID", stableID],
    });
  });
};

export const analytics = {
  track,
};

// Deprecated: Prefer using non-default export
export default {
  track,
  page: (page: string): void => {
    window.rudderanalytics.page(page);
  },
  identify: (userId: string, traits?: Object): void => {
    window.rudderanalytics.identify(userId, { ...traits });
  },
  updateTraits(traits?: Object) {
    const state = getItem("allstar-state");

    const userId = state?.userData?.decoded?._id;

    if (userId) {
      window.rudderanalytics.identify(userId, traits);
      return;
    } else {
      window.rudderanalytics.identify(traits);
    }
  },
};

export const trackClipSocialShare = ({
  platform,
  handler,
  clip,
  queue,
  data,
  aspectRatio,
}: any) => {
  // handler({
  //   url: `https://jsonplaceholder.typicode.com/todos/1`,
  //   method: "GET",
  // });
  // return;
  console.log("HEWRE");
  window.rudderanalytics.track("Clip Shared", {
    clipID: clip._id,
    game: clip.game,
    tags: clip.clipProperties,
    length: clip.clipLength,
    clipOwner: clip.user?._id,
    shareDestination: platform,
    shareId: clip?.shareId,
  });

  const analyticsProperties = {
    clipName: getClipTitle(clip),
    game: clip.game,
    tags: clip.clipProperties,
    length: clip.clipLength,
    total_reactions: clip.totalReactions,
    total_views: clip.views,
    shareDestination: platform,
    shareId: clip?.shareId,
  };

  const uploadPlatforms = ["YouTube", "TikTok"];

  if (handler && uploadPlatforms.includes(platform)) {
    handler({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/items/clip/upload${
        queue === true ? "?queue=true" : ""
      }`,
      data: {
        _id: clip._id,
        platform,
        data,
        aspectRatio,
        analytics: analyticsProperties,
      },
    });
  } else {
    postApiSegment({
      event: "Creator - Clip Shared",
      userId: clip.user?._id,
      properties: analyticsProperties,
    });
  }
};

export const trackCreatorCardShare = (card: any) => {
  window.rudderanalytics.track("Creator Card Shared", {
    cardId: card._id,
    badge: card.ui.badge,
    category: card.ui.category,
    cost: card.ui.cost,
    rarity: card.ui.rarity,
    uses: card.ui.uses,
    useType: card.ui.useType,
  });
};

export async function postApiSegment(data: any) {
  const opt: any = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/analytics/segment`,
    data: data,
  };
  try {
    return Axios(opt);
  } catch (e: any) {
    return e.response;
  }
}
