import React from "react";
import { NavLink } from "../../atoms/nav/link";
import { FormLink } from "../../atoms/form/link";
import { Button } from "../../atoms/form/button";
import theme from "../../../theme/themeThree";
import { Box, Flex, Image } from "rebass/styled-components";
import { PaddedWrapper } from "../../wrappers/paddedWrapper";
import { NavListPrimary } from "./listPrimary";
import { AMark as Brand } from "../../logo";
import { FiX } from "react-icons/fi";
import { DiscordSocialButton } from "../../atoms/form/button/social";
import { Divider } from "../../atoms/divider";
import { UserIcon, SettingsIcon } from "../../atoms/icons";
import { SubscriptionsIcon } from "../../../MVVM/Components/Icons";
import styled from "styled-components";
import { HiSupport } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { URLS } from "../../../MVVM/Utilities/Maps";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../MVVM/State/modals";
import { ModalType } from "../../../MVVM/@types";

const StyledNavMobile = styled(Flex)`
  display: ${({ open }) => (open ? "block" : "none")};
  overflow-y: auto;
`;

const BrandLogo = styled(Brand)`
  overflow: visible;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
  }
`;

const XIcon = styled(FiX)`
  font-size: 1.75em;
`;

const StyledFormLink = styled(FormLink)`
  font-size: ${(props) => props.theme.text.headline.fontSize};
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  min-width: 100%;
  min-height: 60px;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.chalk};
`;

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export function NavMobile({
  payload,
  open,
  setOpen,
  showModal,
  logoutOnClick,
}) {
  const { pathname } = useLocation();
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const { t } = useTranslation("translation", {
    keyPrefix: "nav.listDropdown",
  });
  return (
    <StyledNavMobile
      variant="navMobile"
      open={open}
      onClick={() => setOpen(!open)}
    >
      <PaddedWrapper flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          height="60px"
          width="100%"
        >
          <NavLink to="/">
            <BrandLogo variant="logo.nav" />
          </NavLink>
          <XIcon />
        </Flex>
        <Divider width="100%"></Divider>
        <Flex
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
          flexDirection="column"
          mt={4}
        >
          {payload.loggedIn ? (
            <>
              <StyledNavLink
                mb={4}
                onClick={() => {
                  window.rudderanalytics.track("Signature - Open Modal", {
                    where: "Nav Menu",
                  });
                  setAllstarModalState({
                    isOpen: ModalType.SignatureSelector,
                    functions: {},
                    data: {},
                  });
                }}
              >
                <Image
                  src={`${REACT_APP_S3_CLIP_BUCKET}/static/loadouts/sig.svg`}
                  height={22}
                  width={22}
                  mr={3}
                />
                Manage Signature
              </StyledNavLink>
              <StyledNavLink mb={4} to={`/profile?user=${payload.user._id}`}>
                <UserIcon mr={3} size={22} />
                {t("profile")}
              </StyledNavLink>
              <StyledNavLink mb={4} to="/accountsettings">
                <SettingsIcon mr={3} size={22} />
                {t("accountSettings")}
              </StyledNavLink>
              <StyledNavLink mb={4} to="/support">
                <Box mr={3}>
                  {" "}
                  <HiSupport size={22} />
                </Box>
                {t("support")}
              </StyledNavLink>
              <StyledNavLink mb={4} to={URLS.UPGRADE}>
                <Box mr={3}>
                  {" "}
                  <SubscriptionsIcon size={22} />
                </Box>
                {t("upgrade")}
              </StyledNavLink>
            </>
          ) : (
            <></>
          )}

          <NavListPrimary
            mb={theme.margins.navLinks}
            showModal={showModal}
            loggedin={payload.loggedIn}
            isMobile={true}
          />
          {payload.loggedIn ? (
            <>
              <StyledNavLink
                to="#"
                onClick={logoutOnClick}
                mt={2}
                color="chalk"
              >
                {t("signOut")}
              </StyledNavLink>
              <Box style={{ width: "100%", height: "120px" }}></Box>
            </>
          ) : (
            <>
              <DiscordSocialButton to="/discordinvite">
                Join our Discord
              </DiscordSocialButton>
              <Divider width="100%" mt={5}>
                or
              </Divider>
              <Button to="/signup" mb={4} mt={2}>
                Sign up for Allstar
              </Button>
              <Box style={{ marginBottom: "200px" }}>
                <StyledFormLink mt={4} to={`/login?next=${pathname}`}>
                  Already a member? Login
                </StyledFormLink>
              </Box>
            </>
          )}
        </Flex>
      </PaddedWrapper>
    </StyledNavMobile>
  );
}
