import React, { useEffect } from "react";
import styled from "styled-components";
import { FullScreenWrapper } from "../../../components/wrappers/fullScreenWrapper";
import { Image, Flex } from "rebass/styled-components";
import { Body } from "../../../components/text";
import CreatorVideo from "../../../assets/images/studio/marketing/creatorfx.mp4";
import Stack from "../../../assets/images/studio/marketing/card-stack.png";

const Freq = styled(Flex)`
  font-size: 16px;
  font-weight: 600;
  padding-left: 25px;
  padding-right: 25px;
`;

const FloatyImage = styled(Image)`
  z-index: 99;
  position: absolute;
  top: ${(props) => props.y}%;
  left: ${(props) => (props.left ? `${props.left}%` : null)};
  right: ${(props) => (props.right ? `${props.right}%` : null)};

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    left: ${(props) => (props.left ? `${(30 + props.left * -1) * -1}%` : null)};
    right: ${(props) =>
      props.right ? `${(20 + props.right * -1) * -1}%` : null};
  }
`;

const FloatyImageHideOnMobile = styled(FloatyImage)`
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
`;

const StyledVideo = styled.video`
  border-radius: 25px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
  }
`;

const ContentColumn = styled(Flex)`
  position: relative;
  width: 35%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
  }
`;

const SpeedoColumn = styled(ContentColumn)`
  justify-content: center;
  width: 65%;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ContentContainer = styled(Flex)`
  margin: auto;
  max-width: ${(props) => props.theme.sizes[8]}px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    flex-direction: column-reverse;
    justify-content: center;
    max-width: 100%;
  }
`;

const StyledFullScreenWrapper = styled(FullScreenWrapper)`
  position: relative;
`;

const CreatorFx = () => {
  useEffect(() => {
    window.rudderanalytics.page("Pro Landing Upgrade");
  });
  return (
    <StyledFullScreenWrapper>
      <FloatyImageHideOnMobile src={Stack} maxWidth="550px" right="-7" y="0" />

      <ContentContainer justifyContent="space-between">
        <ContentColumn pl={[0, 0, 8]} py={[9, 9, 0]}>
          <Flex alignItems="flex-start" mb={7} flexDirection="column">
            <Freq
              mb={6}
              variant="studio"
              flexDirection="row"
              alignItems="center"
            >
              Play your cards
            </Freq>

            <Body>
              Sync your kills to the beat with our large selection of music,
              time that perfect meme, or change camera angles on that last kill.
              Giving you pro level edits without you having to do a thing!
            </Body>
          </Flex>
        </ContentColumn>
        <SpeedoColumn pl={[0, 0, 10]}>
          <StyledVideo alignItems="flex-end" autoPlay muted loop>
            <source src={CreatorVideo} type="video/mp4" />
          </StyledVideo>
        </SpeedoColumn>
      </ContentContainer>
    </StyledFullScreenWrapper>
  );
};

export default CreatorFx;
