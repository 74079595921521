import { atom } from "recoil";

export const cotdPlayer = atom({
  key: "cotdPlayer", // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});

export const feedPlayer = atom({
  key: "feedPlayer", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const muteState = atom({
  key: "muteState", // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});
