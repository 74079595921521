import { atom } from "recoil";
import { TClip } from "../Views/Setup/Components/@types";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export type TFlowState =
  | "default"
  | "profile"
  | "games"
  | "platforms"
  | "steam"
  | "sharecode"
  | "socials"
  | "download"
  | "claim"
  | "finalizing"
  | "signature"
  | "riotLinking";

export type TFTUEProps = {
  hasCompleted: boolean;
  stepVerified: boolean;
  stateLoaded: boolean;
  ftueComplete: boolean;
  step: TFlowState;
  stepHistory: TFlowState[];
  clips?: string[];
  partner?: string;
  platformsSelected: string[];
  autoLinkGamersclub: boolean;
  redirectToSharecodes: boolean;
  submittedUsername: boolean;
  isFromPartner: boolean;
  avatar: string;
  signatureNextButtonEnabled: boolean;

  linkingErrorMessage: string;
  preloadedStep: string;
  claimClips: {
    clips: TClip[];
    dataCount: number;
    isClaimableSession: boolean;
    alreadySent: number[];
    sentAll: boolean;
    loadingState: boolean;
  };
};

export const FTUEProfileState = atom({
  key: "FTUEProfileState",
  default: {
    error: false,
    success: false,
    errorMsg: "",
    successMsg: "Profile updated!",
    username: "",
    country: "",
    avatar: "",
    loadedRandomAvatar: false,
    shouldSaveAvatar: true,
  },
});

export const FTUEGameState = atom({
  key: "FTUEGameState",
  default: {
    gameSelection: [
      {
        name: "CS2",
        selected: false,
        iconUrl: `${REACT_APP_S3_CLIP_BUCKET}/static/ftue/cs2.svg`,
        id: "7302",
      },
      {
        name: "Dota 2",
        selected: false,
        iconUrl: `${REACT_APP_S3_CLIP_BUCKET}/static/ftue/dota.svg`,
        id: "570",
      },
      {
        name: "Fortnite",
        selected: false,
        iconUrl: `${REACT_APP_S3_CLIP_BUCKET}/static/ftue/fortnite.svg`,
        id: "407",
      },
      {
        name: "League",
        selected: false,
        iconUrl: `${REACT_APP_S3_CLIP_BUCKET}/static/ftue/lol.svg`,
        id: "101",
      },
    ],
  },
});

export const FTUEState = atom<TFTUEProps>({
  key: "FTUEState_V0.0.1",
  default: {
    stepVerified: false,
    ftueComplete: false,
    stateLoaded: false,
    hasCompleted: false,
    platformsSelected: [],
    autoLinkGamersclub: false,
    redirectToSharecodes: false,
    submittedUsername: false,
    isFromPartner: false,
    partner: "",
    avatar: "",
    signatureNextButtonEnabled: false,
    linkingErrorMessage: "",
    preloadedStep: "",
    claimClips: {
      clips: [],
      dataCount: 0,
      isClaimableSession: false,
      alreadySent: [],
      sentAll: false,
      loadingState: true,
    },
    step: "profile",
    stepHistory: [],
  },
});
