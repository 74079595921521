import React, { useEffect, useState } from "react";
import { RiShareForwardFill } from "react-icons/ri";
import styled from "styled-components";
import { Tooltip } from "../../Tooltip";
import { MdSendToMobile } from "react-icons/md";
import { ReactComponent as NavStudio } from "../../../Assets/Studio/nav-studio.svg";
import { BsQuestionLg } from "react-icons/bs";
import { HiOutlinePencil, HiDownload } from "react-icons/hi";
import { Button } from "..";
import { useTranslation } from "react-i18next";
import { FaDiscord, FaYoutube } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { BsLink45Deg } from "react-icons/bs";
import { SiTiktok } from "react-icons/si";

const StyledEditTitleIcon = styled(HiOutlinePencil)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
`;

const StyledEditTitle = styled(Button)`
  ${(props) => (props.hideOnDesktop ? "display:none" : null)};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => (props.hideOnDesktop ? "display:block" : null)};
  }
`;

export function OverlayEditTitle({
  size,
  children,
  classification,
  mr,
  onClick,
}: any) {
  classification = classification ? classification : "secondary";
  const { t } = useTranslation("translation", { keyPrefix: "partnerOverlay" });
  return (
    <>
      <Tooltip
        position={{
          top: "7px",
          padding: "7px",
          right: "45px",
        }}
        type="hover"
        theme="helpTooltip"
        align="right"
        tooltipContent={t("editClip")}
      >
        <StyledEditTitle
          title={t("editClip")}
          flex={true}
          mr={mr}
          size={size}
          classification={classification}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <StyledEditTitleIcon />
          {children}
        </StyledEditTitle>
      </Tooltip>
    </>
  );
}

const StyledButton = styled(Button)``;

export function OverlayRemix({
  size,
  children,
  classification,
  mr,
  onClick,
}: any) {
  const { t } = useTranslation("translation", { keyPrefix: "partnerOverlay" });
  return (
    <>
      <Tooltip
        position={{
          top: "7px",
          padding: "7px",
          right: "48px",
        }}
        type="hover"
        theme="helpTooltip"
        align="right"
        tooltipContent={t("addFx")}
      >
        <StyledButton
          title={t("addFx")}
          flex={true}
          mr={mr}
          size={size}
          classification={classification}
          log={"remix"}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <StyledRemixIcon />
          {children}
        </StyledButton>
      </Tooltip>
    </>
  );
}

export function OverlayMobile({
  size,
  children,
  classification,
  mr,
  onClick,
}: any) {
  const { t } = useTranslation("translation", { keyPrefix: "partnerOverlay" });
  return (
    <>
      <Tooltip
        position={{
          top: "7px",
          padding: "7px",
          right: "48px",
        }}
        type="hover"
        theme="helpTooltip"
        align="right"
        tooltipContent={t("mobile.tooltip")}
      >
        <StyledButton
          flex={true}
          mr={mr}
          size={size}
          classification={classification}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <StyledMobileIcon />
          {children}
        </StyledButton>
      </Tooltip>
    </>
  );
}
export function OverlaySettings({
  size,
  children,
  classification,
  mr,
  onClick,
}: any) {
  const { t } = useTranslation("translation", { keyPrefix: "partnerOverlay" });
  return (
    <>
      <Tooltip
        position={{
          top: "7px",
          padding: "7px",
          left: "48px",
          "@media (max-width: 650px)": {
            top: "2px",
            left: "40px",
          },
        }}
        type="hover"
        theme="helpTooltip"
        align="right"
        tooltipContent={t("settings.tooltip")}
      >
        <StyledButton
          flex={true}
          mr={mr}
          size={size}
          classification={classification}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <StyledSettingsIcon />
          {children}
        </StyledButton>
      </Tooltip>
    </>
  );
}
export function OverlayInfo({
  size,
  children,
  classification,
  mr,
  onClick,
}: any) {
  const { t } = useTranslation("translation", { keyPrefix: "partnerOverlay" });
  return (
    <>
      <Tooltip
        position={{
          top: "7px",
          padding: "7px",
          left: "48px",
        }}
        type="hover"
        theme="helpTooltip"
        align="right"
        tooltipContent={t("info.tooltip")}
      >
        <StyledButton
          flex={true}
          mr={mr}
          size={size}
          classification={classification}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <StyledInfoIcon />
          {children}
        </StyledButton>
      </Tooltip>
    </>
  );
}

export function OverlayDownload({
  size,
  children,
  classification,
  mr,
  onClick,
}: any) {
  const { t } = useTranslation("translation", { keyPrefix: "partnerOverlay" });
  return (
    <>
      <Tooltip
        position={{
          top: "7px",
          padding: "7px",
          right: "48px",
        }}
        type="hover"
        theme="helpTooltip"
        align="right"
        tooltipContent={t("download")}
      >
        <StyledButton
          title={t("download")}
          flex={true}
          mr={mr}
          size={size}
          classification={classification}
          log={"remix"}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <StyledDownloadIcon />
          {children}
        </StyledButton>
      </Tooltip>
    </>
  );
}

const StyledRemixIcon = styled(NavStudio)`
  position: relative;
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colors.ice};
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
`;

const StyledSettingsIcon = styled(BsGearFill)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
`;

const StyledMobileIcon = styled(MdSendToMobile)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
`;

const StyledInfoIcon = styled(BsQuestionLg)`
  position: relative;
  color: ice;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
`;
const StyledDownloadIcon = styled(HiDownload)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
`;

const StyledShare = styled(RiShareForwardFill)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
` as any;

const StyledTiktok = styled(SiTiktok)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
` as any;
const StyledDiscord = styled(FaDiscord)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
` as any;
const StyledYoutube = styled(FaYoutube)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
` as any;
const StyledCopy = styled(BsLink45Deg)`
  position: relative;
  width: 20px;
  height: 20px;
  @media (max-width: 650px) {
    width: 15px;
    height: 15px;
  }
` as any;

let StyledOverlayShare = styled(Button)``;

export function OverlayShare({
  size,
  children,
  classification,
  mr,
  onClick,
  href,
  type,
}: any) {
  classification = classification ? classification : "secondary";
  const { t } = useTranslation("translation", { keyPrefix: "partnerOverlay" });
  let [tooltipText, setTooltipText] = useState(t("shareClip"));

  useEffect(() => {
    if (type === "link")
      setTimeout(() => {
        if (tooltipText === "Link Copied!") setTooltipText(t("shareClip"));
      }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipText]);

  return (
    <>
      <Tooltip
        position={{
          top: "7px",
          padding: "7px",
          right: "45px",
        }}
        type="hover"
        theme="helpTooltip"
        align="right"
        tooltipContent={tooltipText}
        hide={type !== "link"}
      >
        <StyledOverlayShare
          title="Share"
          flex={true}
          mr={mr}
          size={size}
          classification={classification}
          log={"share"}
          onClick={() => {
            if (type === "link") {
              const translation = t("linkCopied");
              setTooltipText(translation);
              navigator.clipboard.writeText(href);
            }
            onClick();
          }}
        >
          <ShareIcon type={type} />

          {children}
        </StyledOverlayShare>
      </Tooltip>
    </>
  );
}

const ShareIcon = ({ type }: any) => {
  switch (type) {
    case "discord":
      return <StyledDiscord pb={2} />;
    case "youtube":
      return <StyledYoutube pb={2} />;
    case "tiktok":
      return <StyledTiktok pb={2} />;
    case "link":
      return <StyledCopy pb={2} />;
    default:
      return <StyledShare pb={2} />;
  }
};
