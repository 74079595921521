import React, { lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import Navigation from "./navigation";
import { ThemeProvider } from "styled-components";
import theme from "./theme/themeThree";
import { RecoilRoot } from "recoil";
import { StatsigClient } from "@statsig/js-client";

import { UserStatePreFill } from "./MVVM/Hooks/userStatePreFill";
import { GlobalStyles } from "./theme/globalStyles";
import { StatsigProvider } from "./MVVM/Providers/Statsig";

const { REACT_APP_STATSIG_SDK_KEY } = process.env;

export const statsigClient = new StatsigClient(REACT_APP_STATSIG_SDK_KEY, {
  environment: { tier: process.env.NODE_ENV },
});

statsigClient.initializeSync();

const LazyIframe = lazy(() =>
  import("./MVVM/Views/Iframe" /* webpackChunkName: 'iframe' */),
);

export default function () {
  return (
    <RecoilRoot id="root">
      <StatsigProvider client={statsigClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Router history={history}>
            <UserStatePreFill force={false} />
            <Switch>
              <Route exact path="/iframe" component={LazyIframe} />
              <Navigation />
            </Switch>
          </Router>
        </ThemeProvider>
      </StatsigProvider>
    </RecoilRoot>
  );
}
