import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
} from "@apollo/client";

import {
  MATCH_UPLOAD_UPDATE,
  REQUEST_UPLOAD_MATCH,
} from "../../../GraphQL/demoUploader";

export type TSupportedInputTypes = "FACEIT" | "VALVE" | "URL";

export type TRequestUploadReplyStatus =
  | "READY"
  | "DUPLICATE_REQUEST"
  | "RATE_LIMIT"
  | "INVALID"
  | "ERROR";

interface IReturnObject {
  requestUploadMatch: {
    status: TRequestUploadReplyStatus;
    message: string;
    matchId?: string;
    uploadMatchId?: string;
    presignedUrl?: string;
  };
}

interface IReturnUploadUpdate {
  matchUploadUpdate: {
    success: boolean;
  };
}

export type TMatchUploadStatus =
  | "Submitted"
  | "Uploaded"
  | "Error"
  | "Parsing"
  | "User-Aborted"
  | "Completed"
  | "Duplication";

export interface IMyMatchUploads {
  status: TRequestUploadReplyStatus;
  provider: TSupportedInputTypes;
  trackId: string;
  externalReplayLink: string;
  replayLinkStore: string;
}

export const DemoUploadViewModel = (): {
  loading: boolean;
  matchUploadUpdate: (
    opt: MutationFunctionOptions,
  ) => Promise<FetchResult<IReturnUploadUpdate>>;
  requestMatchUpload: (
    opt: MutationFunctionOptions,
  ) => Promise<FetchResult<IReturnObject>>;
} => {
  const [requestMatchUpload, { loading }] = useMutation(REQUEST_UPLOAD_MATCH);
  const [matchUploadUpdate] = useMutation(MATCH_UPLOAD_UPDATE);

  return {
    loading,
    matchUploadUpdate,
    requestMatchUpload,
  };
};
