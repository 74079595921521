import styled from "styled-components";
import { Body } from "../../../Text";
import { ReactComponent as ProIcon } from "../../../../../assets/images/pro/new/PRO-icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/pro/new/PLUS-icon.svg";
import { ReactComponent as PlatinumIcon } from "../../../../../assets/images/pro/new/PLATINUM-icon.svg";
export const StyledPercentOffContainer = styled<any>(Body)`
  position: absolute;
  top: 19px;
  right: -2px;
  transform: rotate(45deg);
  font-weight: 500;
  z-index: 1;
`;
export const StyledProBadge = styled(ProIcon)`
  width: 20px;
  height: 20px;
`;

export const StyledPlusBadge = styled(PlusIcon)`
  width: 20px;
  height: 20px;
`;

export const StyledPlatinumBadge = styled(PlatinumIcon)`
  width: 20px;
  height: 20px;
`;
