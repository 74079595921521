import React, { useEffect } from "react";
import { Body } from "../../components/text";
import Legal from "./index";
import styled from "styled-components";
import Toggle from "../../components/atoms/toggle";
import { Box, Flex } from "rebass/styled-components";
import { Divider } from "../../components/atoms/divider";
import { Link } from "../../components/atoms/link";
import { useMutation } from "@apollo/client";
import { EDIT_CCPA } from "../../MVVM/GraphQL/editCCPA";
import { useUser } from "../../MVVM/Hooks/useUser";

const StyledBody = styled(Body)`
  line-height: 1.444;
  font-size: 1.125rem;
`;

const ToggleShow = ({ onClick, isToggled }) => {
  return (
    <Flex
      my={8}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <StyledBody>
        <strong>Do not sell my personal information</strong>
      </StyledBody>

      <Box px={4}>
        <Toggle
          onChange={(e) => {
            onClick({ isToggled: e });
          }}
          checked={isToggled}
        />
      </Box>
    </Flex>
  );
};

function LocationToggle({ onClick, isToggled }) {
  return (
    <Flex flexDirection={"column"} alignItems="flex-start">
      <Flex>
        {/* Show page for users in California */}
        <ToggleShow onClick={onClick} isToggled={isToggled} />
      </Flex>
    </Flex>
  );
}

export default function Information() {
  const { allstarUser, getFreshProfile, logout } = useUser();

  const [editCCPA] = useMutation(EDIT_CCPA, {
    onError: (err) => {
      console.log(err);
    },
    onCompleted: async () => {
      try {
        await getFreshProfile();
      } catch (e) {
        logout();
      }
    },
  });

  const toggleCCPA = ({ isToggled }) => {
    editCCPA({
      variables: {
        ccpaPrivacyPreference: isToggled,
      },
    });
  };

  useEffect(() => {
    window.rudderanalytics.page("Legal", "Do not sell info");
  });
  // let [state, setState] = useState({
  //   error: false,
  //   loading: true,
  //   showWarning: false,
  //   emailPrefs: [],
  //   activePrefs: [],
  // });

  // useEffect(() => {
  //   (async () => {
  //     let res = await getUsersContactPreferences();
  //     if (!(res.status === 200) || !res?.data?.data?.email)
  //       return setState({
  //         error: true,
  //         errorMsg: `Something went wrong retrieving your Email Preferences. Please contact support on Discord or email us at support@allstar.gg.`,
  //       });
  //     else {
  //       //Notes for adding a preference to this component.
  //       //1. add a mocked doc
  //       //2. check if response already contains a doc for the user
  //       //3. if the user doesnt have it, push it as an active preference to the activePrefs array
  //       //4. Thats it. The context in the mocked doc needs to match the key in the EMAIL_PREFS array above, or it wont render.
  //       let activePrefs = [];

  //       //1
  //       let mockedMarketing = { status: true, context: "Do not Sell" };

  //       //2
  //       let hasMarketing =
  //         res?.data?.data?.email.filter((pref) => {
  //           return pref.context === "Do not Sell";
  //         }).length > 0;

  //       //3
  //       if (!hasMarketing) activePrefs.push(mockedMarketing.context);

  //       let active = res?.data?.data?.email.filter((pref) => {
  //         return pref.status;
  //       });
  //       active.forEach((pref) => {
  //         activePrefs.push(pref.context);
  //       });

  //       return setState({
  //         ...state,
  //         error: false,
  //         loading: false,
  //         emailPrefs: res?.data?.data?.email,
  //         activePrefs: activePrefs,
  //       });
  //     }
  //   })();
  // }, []); //eslint-disable-line

  // const handleChange = async (isToggled, key) => {
  //   let onOrOff = isToggled ? "on" : "off";
  // let activePrefs = state.activePrefs;

  // if (isToggled && !activePrefs.includes(key)) {
  //   activePrefs.push(key);
  // } else if (!isToggled && activePrefs.includes(key)) {
  //   activePrefs = activePrefs.filter((pref) => {
  //     return !(pref === key);
  //   });
  // }

  // setState({ ...state, activePrefs: activePrefs, showWarning: true });
  // let res = await setUsersContactPreferences(key, onOrOff);
  // if (!(res.status === 200) || !res?.data?.data?.email)
  //   return setState({
  //     error: true,
  //     errorMsg: `Something went wrong. Please contact support on Discord or email us at support@allstar.gg.`,
  //   });
  // else {
  //   window.rudderanalytics.track(`${key} - Do not sell Toggle ${onOrOff}`, {});
  // }
  // };

  return (
    <Legal>
      <StyledBody>Last updated on January 5th, 2023</StyledBody>
      <div style={{ paddingBottom: 100 }}>
        <StyledBody>&nbsp;</StyledBody>
        <StyledBody>
          <strong>Allstar Gaming, Inc.</strong>
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>Do not sell my personal information</u>
          </strong>
        </StyledBody>
        <br></br>
        <StyledBody>
          Your rights under the California Consumer Privacy Act
        </StyledBody>
        <br></br>
        <StyledBody>
          The California Consumer Privacy Act (CCPA) provides you with rights
          regarding how your data or personal information is treated. Under the
          legislation, California residents can choose to opt out of the “sale”
          of their personal information to third parties. Based on the CCPA
          definition, “sale” refers to data collection for the purpose of
          creating advertising and other communications.{" "}
          <a href="https://oag.ca.gov/privacy/ccpa">
            Learn more about CCPA and your privacy rights.
          </a>
        </StyledBody>
        <br></br>
        {allstarUser?.loggedIn && (
          <>
            {" "}
            <StyledBody>
              <strong>
                <u>How to opt out</u>
              </strong>
            </StyledBody>
            <br></br>
            <StyledBody>
              By clicking on the toggle below, we will no longer collect or sell
              your personal information. This applies to both third-parties and
              the data we collect to help personalize your experience on our
              website or through other communications. For more information,
              view our <Link to="/legal/privacy-policy">privacy policy.</Link>
            </StyledBody>
            {/* {EMAIL_CONTEXTS.map((context, i, array) => {
          return ( */}
            <Divider mt={8}></Divider>
            <LocationToggle
              onClick={toggleCCPA}
              isToggled={allstarUser?.user?.profile?.ccpaPrivacyPreference}
            />
          </>
        )}
      </div>
    </Legal>
  );
}
