import { FiChevronDown } from "react-icons/fi";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import theme from "../../../theme/themeThree";

export const RegionSelectorColumn = styled(Flex)`
  padding: 32px;
  gap: 32px;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
  background: var(--Gray-750, #1c2a34);
  border-radius: 9px;
  max-width: 500px;
`;

export const TitleAndSubtitle = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const SelectRegionWithLabel = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  position: relative;
`;

export const ButtonsFlex = styled(Flex)`
  display: flex;
  justify-content: end;
  align-items: center;
  align-self: stretch;
`;

interface IRegionSelector {
  isOpen: boolean;
  inline?: boolean;
  disabled?: boolean;
}
export const DropdownSelectorContainer = styled(Flex)<IRegionSelector>`
  padding: 16px;
  justify-content: space-between;
  position: relative;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Gray-850, #141E26);
  appearance: none;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat, repeat;
  background-position: right 1rem center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%23f7fcfd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  border: 1px solid rgba(143, 169, 175, 0.1);

  ${(props) =>
    props.inline &&
    `
    background-color: var(--Gray-750, #1C2A34);
    width: 140px;
    min-width: 115px;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
  `}

  ${(props) =>
    props.disabled &&
    `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%238fa9af99' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

    cursor: not-allowed;
  
    
  `}
  }
  div[value="no-selection"] {
    color: ${theme.colors.chalk};
  }

  ${(props) =>
    props.isOpen &&
    `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M5 12.5L10 7.5L15 12.5' stroke='%234DBD94' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    border: 1px solid var(--Envy-100, #4dbd94);
    color: var(--Envy-100, #4dbd94);
  `}
`;
export const DropdownOption = styled(Box)`
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  &:hover {
    background: var(--Gray-850, #141e26);
  }
`;

export const Chevron = styled(FiChevronDown)`
  position: absolute;
`;
interface IDropdownContainer {
  inline?: boolean;
}
export const DropdownContainer = styled(Flex)<IDropdownContainer>`
  display: flex;
  overflow-y: auto;
  width: 100%;
  max-height: 300px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  z-index: 100;
  top: 115%;
  border-radius: 8px;
  border: 1px solid var(--Chalk-20, rgba(143, 169, 175, 0.2));
  background: var(--Ned-100, #1c2a34);
  left: 0px;
  ${(props) =>
    props.inline &&
    `
    width: 320px;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
  `}
  ::-webkit-scrollbar-corner {
    background: var(--Chalk-20, rgba(143, 169, 175, 0.2));
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--Chalk-20, rgba(143, 169, 175, 0.2));
    background-clip: content-box;

    border: 4px solid transparent;
    border-radius: 999px;
  }
`;
