import React, { useEffect, useRef, MutableRefObject } from "react";
import { Box } from "rebass/styled-components";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { StyledPlayer } from "./styledComponents";

export type TSourceType = { src: string; type: string };
interface IVideoPlayerProps {
  mp4Source?: string;
  hlsSource?: string;
  options?: videojs.PlayerOptions;
  refForVideoPlayer: MutableRefObject<videojs.Player | null>;
  onPlayStart?: () => void;
}

export const VideoPlayer = ({
  mp4Source,
  hlsSource,
  options,
  refForVideoPlayer,
  onPlayStart,
  ...props
}: IVideoPlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const sources: TSourceType[] = [];
      const videoElement = document.createElement("video-js");

      if (mp4Source)
        sources.push({
          src: mp4Source,
          type: "video/mp4",
        });
      if (hlsSource)
        sources.push({
          src: hlsSource,
          type: "application/x-mpegURL",
        });

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, {
        controls: true,
        fluid: true,
        sources,
        ...options,
      });
    }

    if (refForVideoPlayer) refForVideoPlayer.current = playerRef.current;
  }, [mp4Source, hlsSource, options, refForVideoPlayer]);

  useEffect(() => {
    if (playerRef.current) {
      if (hlsSource) {
        return playerRef.current.src(hlsSource);
      }
      if (mp4Source) {
        return playerRef.current.src(mp4Source);
      }
    }
  }, [mp4Source, hlsSource]);

  useEffect(() => {
    const player = playerRef.current;

    player?.on("play", () => {
      const playerVolume = localStorage.getItem("playerVolume");

      if (!player.currentTime() && onPlayStart) onPlayStart();
      if (playerVolume) player.volume(parseFloat(playerVolume));
    });

    player?.on("volumechange", () => {
      localStorage.setItem("playerVolume", `${player.volume()}`);
    });

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef, onPlayStart]);

  return (
    <StyledPlayer>
      <Box data-vjs-player {...props}>
        <Box ref={videoRef} />
      </Box>
    </StyledPlayer>
  );
};
