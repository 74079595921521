import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

import {
  CREATE_LOADOUT,
  GET_CONFLICTING_CARDS,
  GET_MY_SIGNATURE,
  LOADOUT_SELECTOR_QUERY,
  TOGGLE_LOADOUT,
} from "../../GraphQL/loadoutSelector";
import { useUser } from "../../Hooks/useUser";
import { SignatureSelectorState } from "../../State/loadoutSelector";
import { AllstarModalState } from "../../State/modals";
import { ModalType } from "../../@types";
import { MUTATE_TOGGLE_CREATORCARD } from "../../GraphQL/studioFacet";
import { StudioSession } from "../../State/studioSession";
import { MUTATE_PROFILE } from "../../GraphQL/mutateUser";
import { hasScope } from "../../Utilities/Studio";
import {
  getHighestRequiredUsergroup,
  userGroupTypeToDisplayString,
} from "../../Utilities/User";
import { MatchHistorySession } from "../../State/matchHistory";
import { FTUEState } from "../../State/ftue";

export const SignatureSelectorViewModel = () => {
  const [getSelectableLoadouts] = useLazyQuery(LOADOUT_SELECTOR_QUERY, {
    fetchPolicy: "cache-first",
  });
  const [signatureSession, setSignatureSession] = useRecoilState(
    SignatureSelectorState,
  );
  const [studioSession, setStudioSession] = useRecoilState(StudioSession);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const { allstarUser, getFreshProfile } = useUser();
  const [createLoadout] = useMutation(CREATE_LOADOUT, {});

  const [getMySignature] = useLazyQuery(GET_MY_SIGNATURE, {
    fetchPolicy: "network-only",
  });

  const [toggleLoadout] = useMutation(TOGGLE_LOADOUT, {});
  const [toggleCardState] = useMutation(MUTATE_TOGGLE_CREATORCARD, {
    onError: (err) => {},
  });
  const [mutateProfile] = useMutation(MUTATE_PROFILE);

  const [getConflictingCardIds] = useLazyQuery(GET_CONFLICTING_CARDS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ checkLoadoutAndCreatorCardConflicts }) => {
      if (checkLoadoutAndCreatorCardConflicts.length > 0) {
        return setAllstarModalState({
          isOpen: ModalType.DisableConflictingCards,
          data: {},
          functions: {
            onConfirm: async () => {
              await toggleCardState({
                variables: {
                  cardIds: checkLoadoutAndCreatorCardConflicts,
                  isActive: false,
                },
              });

              if (allstarModalState?.functions?.onComplete)
                allstarModalState.functions.onComplete();
              setStudioSession({
                ...studioSession,
                userActivated: studioSession.userActivated.filter(
                  (card: { _id: string }) =>
                    !checkLoadoutAndCreatorCardConflicts.includes(card._id),
                ),
              });
              setAllstarModalState({
                ...allstarModalState,
                isOpen: ModalType.None,
                functions: {},
                data: {},
              });
            },
          },
        });
      }

      if (allstarModalState?.functions?.onComplete)
        allstarModalState.functions.onComplete();
      setAllstarModalState({
        ...allstarModalState,
        isOpen: ModalType.None,
        functions: {},
        data: {},
      });
    },
  });
  const [matchHistoryState, setMatchHistoryState] =
    useRecoilState(MatchHistorySession);
  const [ftueRecoilState, setFtueRecoilState] = useRecoilState(FTUEState);

  useEffect(() => {
    if (allstarUser?.loggedIn && !signatureSession.fetchedMySignature) {
      getMySignature({
        variables: {
          user: allstarUser?.user?._id,
          signature: true,
        },
      }).then(({ data }) => {
        const genreClipCards = data?.getLoadouts?.clipCards?.filter(
          (card: { card: { effect: string } }) =>
            card.card.effect === "FACET_LOOKUP",
        );
        const selectedGenresCards = genreClipCards?.map(
          (card: { card: { _id: string } }) => card.card,
        );

        if (data?.getLoadouts) {
          setSignatureSession((prev) => {
            return {
              ...prev,
              mySignature: data.getLoadouts,
              allowSave: true,
              hasASignature: true,
              selectedGenres: selectedGenresCards,
              fetchedMySignature: true,
              selectedCountryCode:
                allstarUser?.user?.profile?.profile?.countryCode || "",
              customStyleSelectionToPreload:
                data?.getLoadouts?.metadataCustomStyleSelection,
            };
          });
        } else {
          return setSignatureSession((prev) => {
            return {
              ...prev,
              fetchedMySignature: true,
              selectedCountryCode:
                allstarUser?.user?.profile?.profile?.countryCode || "",
            };
          });
        }
      });
    }
    if (!allstarUser?.loggedIn) {
      setSignatureSession((prev) => {
        return {
          ...prev,
          fetchedMySignature: true,
        };
      });
    }
  }, [
    allstarUser?.loggedIn,
    allstarUser?.user?._id,
    signatureSession.hasASignature,
    getMySignature,
    setSignatureSession,
    signatureSession.fetchedMySignature,
    allstarUser?.user?.profile?.profile?.countryCode,
  ]);

  async function updateCountryCode(countryCode: string) {
    if (!countryCode) return;
    try {
      const response = await mutateProfile({
        variables: { countryCode: countryCode },
      });
      await getFreshProfile();

      if (response?.data) {
        window.rudderanalytics.track("Signature - Country Flag Saved", {});
      }
    } catch (e) {}
  }

  const saveSignature = async ({ isFtue }: { isFtue: boolean }) => {
    const selectedNone =
      !signatureSession.selectedSignature.metadataThemeName &&
      !signatureSession.selectedSignature.metadataStyleName;

    const allSelectedStyles = signatureSession.selectedDropdownStyles.map(
      (style) => style.name,
    );
    const allScopesFromSelected = signatureSession.allLoadouts
      .filter((loadout) =>
        allSelectedStyles.includes(loadout.metadataStyleName),
      )
      .map((loadout) => loadout.scopeUse)
      .flat();

    const uniqueScopes = [...new Set(allScopesFromSelected)];

    if (!allstarUser.loggedIn)
      return setAllstarModalState({
        isOpen: ModalType.Signup,
        data: { action: "signup" },
        functions: {},
      });

    if (!hasScope(allstarUser?.user, uniqueScopes)) {
      const requiredScope = getHighestRequiredUsergroup(uniqueScopes);

      return setAllstarModalState({
        isOpen: ModalType.Paywall,
        data: {
          type: "signature",
          isFtue: isFtue,
          requiredScope: requiredScope,
          tier: userGroupTypeToDisplayString(requiredScope),
        },
        functions: {},
      });
    }

    const genresToSave = selectedNone
      ? []
      : signatureSession.selectedGenres.map((genre) => {
          return { card: genre._id };
        });

    const cardsToSave = [];
    const allCards = signatureSession.allLoadouts
      .map((loadout) =>
        loadout.clipCards.map((card) => {
          return {
            ...card,

            metadataStyleName: loadout.metadataStyleName,
          };
        }),
      )
      .flat();

    for (const style of signatureSession.selectedDropdownStyles) {
      const toggleName = style.toggleNameItWasSelectedFor;
      const styleName = style.name;
      const cardToSave = allCards.find(
        (card) =>
          card.settingsUiToggleName === toggleName &&
          card.metadataStyleName === styleName &&
          signatureSession.selectedToggleNames.includes(toggleName),
      );
      if (!cardToSave) continue;
      cardsToSave.push({
        card: cardToSave.card._id,
        countryFlagEnabled: signatureSession.selectedToggleNames.includes(
          signatureSession.selectedSignature.metadataThemeCountryFlagToggleName,
        ),
      });
    }

    const customStylesEligibleForSaving =
      signatureSession.selectedDropdownStyles.filter((style) => {
        return signatureSession.selectedToggleNames.includes(
          style.toggleNameItWasSelectedFor,
        );
      });

    const customStylesFormatted = customStylesEligibleForSaving.map((style) => {
      return {
        metadataStyleName: style.name,
        settingsUiToggleName: style.toggleNameItWasSelectedFor,
      };
    });
    const response = await createLoadout({
      variables: {
        clipCards: [...cardsToSave, ...genresToSave],
        metadataCustomStyleSelection: customStylesFormatted,
        signature: true,
        metadataThemeName: signatureSession.selectedSignature.metadataThemeName,
        metadataStyleName: signatureSession.selectedSignature.metadataStyleName,
        metadataThemeCountryFlagEnabled:
          signatureSession.selectedToggleNames.includes(
            signatureSession.selectedSignature
              .metadataThemeCountryFlagToggleName,
          ),
        active: !selectedNone,
      },
    });

    if (
      signatureSession.selectedCountryCode !==
      allstarUser?.user?.profile?.profile?.countryCode
    ) {
      await updateCountryCode(signatureSession.selectedCountryCode);
    }

    const { data } = response;
    const styleNames = customStylesEligibleForSaving.map((style) => style.name);
    const allStyleNamesAreTheSame = styleNames.every(
      (name) => name === styleNames[0],
    );

    window.rudderanalytics.track("Signature - Save Signature", {
      theme: signatureSession.selectedSignature.metadataThemeName,
      style: signatureSession.selectedSignature.metadataStyleName,
      hasMusic: !!signatureSession.selectedGenres.length,
      mixAndMatch: !allStyleNamesAreTheSame,
      countryFlagEnabled: signatureSession.selectedToggleNames.includes(
        signatureSession.selectedSignature.metadataThemeCountryFlagToggleName,
      ),

      removed: !!(
        !signatureSession.selectedSignature.metadataThemeName &&
        signatureSession.mySignature.metadataThemeName
      ),
      ...signatureSession.selectedSignature.clipCards.reduce((obj, card) => {
        return {
          ...obj,
          [card.settingsUiToggleName]:
            signatureSession.selectedToggleNames.includes(
              card.settingsUiToggleName,
            ),
        };
      }, {}),
    });

    const musicGenresClipCards = data.createLoadout.clipCards.filter(
      (card: { card: { effect: string } }) =>
        card.card.effect === "FACET_LOOKUP",
    );
    const selectedGenres = musicGenresClipCards.map(
      (card: { card: { effect: string } }) => card.card,
    );

    setSignatureSession({
      ...signatureSession,
      mySignature: data.createLoadout,
      hasASignature: !!data.createLoadout,
      selectedGenres: selectedGenres,
    });
    setMatchHistoryState((prev) => {
      return {
        ...prev,
        hasASignature: !!data.createLoadout,
      };
    });
    //ISFTUE
    if (isFtue)
      setFtueRecoilState({
        ...ftueRecoilState,
        stepVerified: false,
        step: "games",
        stepHistory: [...ftueRecoilState.stepHistory, "signature"],
      });

    if (data?.createLoadout?.active)
      return await getConflictingCardIds({
        variables: {
          cardIds: signatureSession.selectedSignature.clipCards
            .filter((card) => card.enabled)
            .map((card) => card.card._id),
        },
      });

    return setAllstarModalState({
      isOpen: ModalType.None,
      data: {},
      functions: {},
    });
  };

  return {
    getSelectableLoadouts,
    createLoadout,
    toggleLoadout,
    getConflictingCardIds,
    getMySignature,
    updateCountryCode,
    saveSignature,
  };
};
