import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import Lottie from "lottie-react";

import { FloatingContainer, PaddedWrapper } from "../../../Wrappers";
import { MenuDivider } from "../../../Divider";
import Remixing from "../../../../../animations/remixing.json";
import { Link } from "../../../Button";
import { URLS } from "../../../../Utilities/Maps";
import { Title3 } from "../../../Text";
import { Text } from "rebass/styled-components";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";
import { getQueryParam } from "../../../../Utilities/QueryParam";
import { useIsMobileApp } from "../../../../Hooks/useIsMobileApp";

const RemixProcessing = ({ closeModal }: { closeModal?: () => void }) => {
  const history = useHistory();
  const redirectSecs = 5;
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const { isMobileApp } = useIsMobileApp();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const next = getQueryParam("next");
      if (
        isMobileApp &&
        window.location.href.includes(URLS.MATCH_HISTORY) &&
        closeModal
      )
        return closeModal();
      if (next?.includes("match-history")) return history.push(next);
      history.push(URLS.PROFILE, { fromRemix: true });
      setAllstarModalState({
        ...allstarModalState,
        isOpen: ModalType.None,
      });
    }, redirectSecs * 1000);

    return () => clearTimeout(timeoutId);
  }, [allstarModalState, setAllstarModalState, history]);

  return (
    <>
      <PaddedWrapper
        my={[6, 8, 10]}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <StyledFloatingContainer justifyContent="center" alignItems="center">
          <Title3 mb={3}>Remixing clip...</Title3>
          <Lottie
            animationData={Remixing}
            style={{ height: "140px", width: "140px" }}
            loop={true}
          />
          <MenuDivider mt={3} mb={7} mx={0} />
          <Title3>Your clip will be viewable on your profile soon.</Title3>
          <Text color="chalk" size="small" pt={3} pb={2}>
            <Link
              to={{
                pathname: URLS.PROFILE,
                state: { fromRemix: true },
              }}
              variant="link.title"
            >
              Click here
            </Link>{" "}
            if you are not redirected within {redirectSecs} seconds.
          </Text>
        </StyledFloatingContainer>
      </PaddedWrapper>
    </>
  );
};

const StyledFloatingContainer = styled(FloatingContainer)`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`;

export default RemixProcessing;
