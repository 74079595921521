import React, { useState } from "react";
import { Title3, Body } from "../text";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { MatchHistoryWidget } from "../../MVVM/Components/MatchHistoryWidget";
import { Button } from "../../MVVM/Components/Button";
import { useInitializeExperiment } from "../../MVVM/Hooks/useInitializeExperiment";
import { useUser } from "../../MVVM/Hooks/useUser";

const Wrapper = styled(Box)`
  position: relative;
  text-align: left;
  background-color: ${(props) => {
    return props.theme.colors.transparent;
  }};
  border-top: 2px solid
    ${(props) => {
      return props.theme.colors.navy;
    }};
  padding-top: 0px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  height: 700px;
`;

const StyledImage = styled(Box)`
  display: block;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  height: 500px;
  background: #18252d;
  background: -webkit-linear-gradient(to bottom, #18252d, #1c2a34);
  background: linear-gradient(to bottom, #18252d, #1c2a34);
  background-size: cover;
`;

const StyledGrid = styled(Box)`
  display: grid;
  margin: auto;
  width: 100%;
  ${(props) => `
  grid-row-gap: ${props.singleRow ? 0 : props.theme.space[5]}px;
  grid-column-gap: ${props.theme.space[6]}px;
  grid-template-columns: repeat(3, 1fr);

  @media
  (min-width: ${props.theme.sizes[4] * 3 + props.theme.space[7] * 2 + 1}px)
  {
    grid-template-columns: repeat(4, 1fr);
  }

  @media
  (max-width: ${props.theme.sizes[2] * 3 + props.theme.space[7] * 2 + 1}px)
  {
  max-width: ${props.theme.sizes[4] * 2 + props.theme.space[4] * 1}px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media
  (max-width: ${props.theme.sizes[2] * 2 + props.theme.space[7] + 1}px)
  {
  max-width: ${props.theme.sizes[4]}px;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${props.singleRow ? 0 : props.theme.space[7]}px;
  grid-column-gap: ${props.theme.space[4]}px;
}
`}
`;

const StyledBody = styled(Body)`
  max-width: 540px;
  text-align: center;
`;

const StyledTitle3 = styled(Title3)`
  font-weight: 600;
`;

export function ContentAnnouncement({
  title,
  subtitle,
  svg,
  button,
  isOwnProfile,
}) {
  const [shouldHideWidget, setShouldHideWidget] = useState(false);
  const [widgetLoaded, setWidgetLoaded] = useState(false);

  const displayOriginalEmptyState = widgetLoaded && shouldHideWidget;
  const displayWidget = !shouldHideWidget && isOwnProfile;

  if (displayOriginalEmptyState)
    return (
      <Wrapper>
        <Flex alignItems={"center"} justifyContent="center" mb={8} mt={6}>
          <Flex
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
          >
            {svg}
            <StyledTitle3 my={3}>{title}</StyledTitle3>
            <StyledBody color="chalk">{subtitle}</StyledBody>
            <>{button}</>
          </Flex>
        </Flex>

        <Flex>
          <StyledGrid
            flexDirection="row"
            flexWrap="wrap"
            justifyContent={["center", "center", "flex-start", "flex-start"]}
          >
            <StyledImage />
            <StyledImage />
            <StyledImage />
            <StyledImage />
          </StyledGrid>
        </Flex>
      </Wrapper>
    );

  if (displayWidget)
    return (
      <Wrapper>
        <Flex alignItems={"center"} justifyContent="center" mb={8} mt={6}>
          <Flex
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
          >
            {svg}
            <StyledTitle3 my={3}>{title}</StyledTitle3>
            <StyledBody color="chalk">
              But with the magic of Allstar you can start creating right now.
              We've added some of your most recent moments below, just click
              create and a clip will land on your profile in no time!
            </StyledBody>
          </Flex>
        </Flex>

        <>
          <MatchHistoryWidget
            header={"Your recent moments"}
            onFinishedLoading={() => setWidgetLoaded(true)}
            onHideWidget={() => setShouldHideWidget(true)}
          />
        </>
      </Wrapper>
    );

  return <></>;
}
