const RIOT_REGION_DATA = [
  { valueToSave: "EUN1", valueToDisplay: "Europe Nordic & East (EUN1)" },
  { valueToSave: "EUW1", valueToDisplay: "Europe West (EUW1)" },
  { valueToSave: "LA1", valueToDisplay: "Latin America North (LA1)" },
  { valueToSave: "LA2", valueToDisplay: "Latin America South (LA2)" },
  { valueToSave: "NA1", valueToDisplay: "North America (NA1)" },
  { valueToSave: "OC1", valueToDisplay: "Oceania (OC1)" },
  { valueToSave: "RU", valueToDisplay: "Russia (RU)" },
  { valueToSave: "TR1", valueToDisplay: "Turkey (TR1)" },
  { valueToSave: "ME1", valueToDisplay: "Middle East (ME1)" },
  { valueToSave: "JP1", valueToDisplay: "Japan (JP1)" },
  { valueToSave: "KR", valueToDisplay: "Korea (KR)" },
  { valueToSave: "PH2", valueToDisplay: "Philippines (PH2)" },
  { valueToSave: "SG2", valueToDisplay: "Singapore (SG2)" },
  { valueToSave: "TW2", valueToDisplay: "Taiwan (TW2)" },
  { valueToSave: "TH2", valueToDisplay: "Thailand (TH2)" },
  { valueToSave: "VN2", valueToDisplay: "Vietnam (VN2)" },
];

export const getRiotSelectionData = (inline: boolean) => {
  if (inline)
    return [
      { valueToSave: "NONE", valueToDisplay: "Region" },
      ...RIOT_REGION_DATA,
    ];
  return [
    { valueToSave: "NONE", valueToDisplay: "Select a Region" },
    ...RIOT_REGION_DATA,
  ];
};
