import React from "react";
import { Flex } from "rebass/styled-components";
import { useHistory } from "react-router-dom";

import {
  ClipPreviewThumb,
  ClipRemixWrapper,
  StyledText,
  StyledUsernameText,
  SubTitle,
} from "../styledComponents";
import { dateFormatWithDistanceSuffix } from "../../../Utilities/Clip";
import { IClipPreview } from "../@types";
import { ClipRemix } from "../../../Components/Clip/Components/ClipRemix";
import { analytics } from "../../../Analytics";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

interface IClipPreviewProps {
  clip: IClipPreview;
  closeModal?: () => void;
  hideRemix?: boolean;
  legacyClipPage?: boolean;
}

const ClipPreview = ({
  clip,
  closeModal,
  hideRemix,
  legacyClipPage,
}: IClipPreviewProps) => {
  const history = useHistory();
  const handleClick = () => {
    const url = legacyClipPage
      ? `/clip?clip=${clip.shareId}`
      : `/clip/${clip.shareId}`;

    analytics.track("Clip Preview - Clicked", {});

    history.push(url);
    if (closeModal) closeModal();
  };

  return (
    <Flex flexDirection="column">
      <ClipPreviewThumb
        onClick={() => handleClick()}
        src={`${REACT_APP_S3_CLIP_BUCKET}/${clip.clipImageThumb}`}
      />
      {!hideRemix && (
        <ClipRemixWrapper style={{ width: "100%" }}>
          <ClipRemix
            views={clip.views}
            followUnfollow={() => {}}
            clip={clip}
            setFollowModal={true}
            onClick={undefined}
            classification={undefined}
            alignment={undefined}
          />
        </ClipRemixWrapper>
      )}
      <SubTitle onClick={() => handleClick()}>
        {clip.clipTitle.replace(/"/, "")}
      </SubTitle>
      <StyledUsernameText>
        {clip.username ?? clip.steamHandle}
      </StyledUsernameText>
      <Flex style={{ gap: "4px" }}>
        <StyledText>{clip.views} views</StyledText>
        <StyledText>&bull;</StyledText>
        <StyledText>
          {dateFormatWithDistanceSuffix(clip.createdDate)}
        </StyledText>
      </Flex>
    </Flex>
  );
};

export { ClipPreview };
