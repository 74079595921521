import { gql } from "@apollo/client";

export const CHECK_FEATURE_ACCESS = gql`
  query checkFeatureAccess($feature: String) {
    checkFeatureAccess(feature: $feature)
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      metadata {
        noEmailSet
        hasSharecodes
      }
      user {
        _id
        admin
        avatar
        google {
          active
        }

        userGroup {
          active
          type
          updated
          createdDate
          expires
          paymentDate
        }

        riot {
          puuid
          riotId
          active
          usedForSignUp
          region
        }
        discord {
          username
          id
          avatar
        }
        faceit {
          nickname
          player_id

          usedForSignUp
        }
        tiktok {
          hasUploadScope
          display_name
          usedForSignUp
        }
        steam {
          steamid
          personaname
          active
          usedForSignUp
        }
        scope
        rank
        credits {
          providerName
          updated
          createdDate
        }
        discordSignUp
        emailVerified
        createdDate
        username
        ftue {
          hasCompleted
          isCS
          step
          stepHistory
          page
          skip {
            default
            profile
            games
            platforms
            steam
            sharecode
            socials
            download
            claim
          }
        }
      }
      stats {
        totalClips
        totalViews
      }
      profile {
        id
        ccpaPrivacyPreference
        profile {
          countryCode
          locale
          socials {
            twitch
            tiktok
            steam
            instagram
            twitter
            youtube
          }
          youtubeChannels
        }

        username
        createdDate
        appVersion
        gamersClub {
          enabled
          steamId64
        }
        settings {
          usernameChange
        }
        following
      }
    }
  }
`;

export const LOGIN = gql`
  query Query($email: String!, $password: String!) {
    authentication(email: $email, password: $password)
  }
`;

export const SIGNUP = gql`
  mutation Mutation($email: String!, $password: String!) {
    signup(email: $email, password: $password) {
      errors {
        code
        field
        message
      }
      success
      token
    }
  }
`;
