import React from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

const StyledBox = styled(Flex)`
  flex-direction: column;
  position: relative;
  min-height: calc(100% - (3rem * 2));
  margin: 3rem auto;
  border-radius: 20px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  transform: translateY(0) scale(1);
  transition: opacity 0.2s ease, transform 0.3s ease,
    -webkit-transform 0.3s ease;
  transition-delay: 0.2s;
  opacity: 1;
  max-width: 80%;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    width: 80%;
    max-width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 100%;
    max-width: 100%;
    overflow: auto;
  }
`;

export function BottomContainer(props) {
  let { px, py, ...rest } = props;
  return (
    <StyledBox
      variant="bottomContainer"
      px={px ? px : [4]}
      py={py ? py : [4]}
      {...rest}
    >
      {props.children}
    </StyledBox>
  );
}
