import { useStatsigUser } from "@statsig/react-bindings";

import { User } from "../../Model/User";

export const useStatsigSync = () => {
  const { user, updateUserAsync } = useStatsigUser();

  const syncStatsigUser = async () => {
    const userModel = User.getInstance();
    const decoded = await userModel.DecodeToken();

    await updateUserAsync({
      ...user,
      email: decoded?.sub,
      userID: decoded?._id,
    });
  };

  return { syncStatsigUser };
};
