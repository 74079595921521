import React from "react";
import {
  CreatorCardNewModal,
  CreatorCardOGModal,
  CreatorCardTierModal,
  CreatorCardTopModal,
  ProModal,
} from "../../../../Components/Modals";

export function CreatorCardModals({
  modal,
  setModal,
  handleCardToggle,
  isMobileApp,
}: any) {
  const handleClose = () => {
    setModal({ type: "" });
  };

  return (
    <>
      <CreatorCardTierModal
        state={{
          isOpen: [500, 400, 300, 200, 100].includes(modal.type),
          tier: modal.type,
          data: modal.data,
        }}
        setState={handleClose}
        handleModalClose={() => {}}
        disableOutsideClose={false}
        handleCardToggle={handleCardToggle}
      />
      <CreatorCardNewModal
        isOpen={modal === "new"}
        setIsOpen={handleClose}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      />
      <CreatorCardTopModal
        isOpen={modal === "top"}
        setIsOpen={handleClose}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      />
      <CreatorCardOGModal
        isOpen={modal === "exclusive"}
        setIsOpen={handleClose}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      />
    </>
  );
}

type ProFeatureModalProps = {
  modal: { body: string; isOpen: boolean };
  setModal: (modal: { body: string; isOpen: boolean }) => void;
};
export function ProFeatureModal({ modal, setModal }: ProFeatureModalProps) {
  const handleClose = () => {
    setModal({ body: "", isOpen: false });
  };
  return (
    <ProModal
      isOpen={modal.isOpen}
      setIsOpen={handleClose}
      handleModalClose={() => {}}
      disableOutsideClose={false}
      body={modal.body}
    />
  );
}
