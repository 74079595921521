import React, { useMemo, useState, useEffect } from "react";
import { Box, Flex } from "rebass/styled-components";
import { SettingsContainer } from "../../../components/molecules/settingsContainer";
import { LabeledSelect } from "../../../components/atoms/form/labeledSelect";
import { Error } from "../../../components/atoms/form/error";
import { LabeledInputText } from "../../../components/atoms/form/labeledInput";
import { Button } from "../../../components/atoms/form/button";
import { Success } from "../../../components/atoms/form/success";
import { AlertIcon } from "../../../components/atoms/icons";
import Axios from "axios";
import { useForm } from "react-hook-form";
import countryList from "react-select-country-list";
import { DividerAlt } from "../../../components/atoms/dividerAlt";
import { URLS } from "../../../utils";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledSettingsContainer = styled(SettingsContainer)`
  ${(props) => {
    if (props.isPopup) {
      return `
      background: none;
      border: none;
      padding:0px;`;
    }
  }}
`;
function extractTopList(options) {
  const COUNTRIES = [
    "Bangladesh",
    "Brazil",
    "India",
    "Pakistan",
    "Philippines",
    "United States",
  ];
  let topList = [];
  options.forEach((country) => {
    if (COUNTRIES.includes(country.label)) {
      topList.push(country);
    }
  });
  return topList;
}

export function CustomizeProfile({
  allstarUser,
  getFreshProfile,
  isPopup,
  setModalOpen,
}) {
  const options = useMemo(() => countryList().getData(), []);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const {
    handleSubmit,
    register,
    reset,
    formState,
    formState: { errors },
  } = useForm();
  const topList = extractTopList(options);
  const { t } = useTranslation(`translation`, {
    keyPrefix: "profile.account",
  });
  let [state, setState] = useState({
    error: false,
    success: false,
    errorMsg: "",
    successMsg: "Profile updated!",
    username: allstarUser?.user?.profile?.username,
    country: allstarUser?.user?.profile?.profile?.countryCode || "",
    socials: {
      youtube: {
        name: "Youtube",
        prefix: "https://youtube.com/channel/",
        username: "",
      },
      twitch: {
        name: "Twitch",
        prefix: "https://twitch.tv/",
        username: "",
      },
      tiktok: {
        name: "TikTok",
        prefix: "https://tiktok.com/",
        username: "",
      },
      twitter: {
        name: "Twitter / X",
        prefix: "https://twitter.com/",
        username: "",
      },
      instagram: {
        name: "Instagram",
        prefix: "https://instagram.com/",
        username: "",
      },
      steam: {
        name: "Steam",
        prefix: "https://steam.com/",
        username: "",
      },
    },
  });

  useEffect(() => {
    if (!state.success && !state.error) return;
    setTimeout(() => {
      return setState((state) => {
        return {
          ...state,
          success: false,
          error: false,
          errorMsg: "",
        };
      });
    }, 5000);
  }, [state.success, state.error]);

  const onSubmit = async (form) => {
    form = await extractDirtyFields(form);

    if (!!form.username) {
      let response = await checkUsername(form.username);
      if (response.status !== 200) {
        return setState({
          ...state,
          error: true,
          success: false,
          errorMsg: response.data.error
            ? response.data.error
            : response.data.msg,
        });
      }
    }
    // await updateProfileData(form);
  };

  async function checkUsername(username) {
    const opt = {
      method: "GET",
      url: `${BASE_URL}/users/username?username=${username}`,
      data: username,
    };
    let validUsername = await usernameRegex(username);
    if (validUsername) {
      try {
        return await Axios(opt);
      } catch (e) {
        return e.response;
      }
    } else {
      //i know this is weird, but im mimicking a response if the regex is bad
      return { data: { status: 400, msg: "Invalid Username" } };
    }
  }
  async function usernameRegex(username) {
    return /^[a-zA-Z0-9](?!.*(--|__|-_|_-))[a-zA-Z0-9-_]{2,16}[a-zA-Z0-9]$/gi.test(
      username,
    );
  }

  async function extractDirtyFields(form) {
    let dirty = {};
    Object.keys(form).forEach((key) => {
      if (formState.dirtyFields.hasOwnProperty(key)) {
        dirty[key] = form[key];
      }
    });
    return dirty;
  }

  async function updateProfileData(form) {
    //removing any empty fields as a last check before submitting, this handles weird form states that are unaccounted for. We only want to submit fields that are not empty and have changed.
    form = Object.fromEntries(
      Object.entries(form).filter(([_, v]) => v !== ""),
    );
    if (Object.keys(form).length === 0) return;

    const opt = {
      method: "PATCH",
      url: `${BASE_URL}/items/profile/@me?token=${token}&skipClips=true`,
      data: form,
    };

    try {
      let response = await Axios(opt);

      if (response.status === 201) {
        await getFreshProfile();
        reset(form);
        if (isPopup) {
          if (setModalOpen) {
            setModalOpen(false);
          }
        }
        return setState({
          ...state,
          error: false,
          success: true,
          username: formState.dirtyFields.hasOwnProperty("username")
            ? form.username
            : state.username,
          errorMsg: "",
        });
      }
      if (response.status === 204) {
        return setState({
          ...state,
          error: true,
          success: false,
          errorMsg: "You can't set your username more than once.",
        });
      }
    } catch (e) {
      return setState({
        ...state,
        error: true,
        success: false,
        errorMsg: e.response && e.response.data ? e.response.data.msg : "",
      });
    }
  }

  return (
    <StyledSettingsContainer
      title={isPopup ? null : t("yourProfile")}
      mb={5}
      isPopup={isPopup}
    >
      {isPopup ? <></> : <DividerAlt></DividerAlt>}

      {allstarUser.user?.profile?.settings?.usernameChange > 0 || isPopup ? (
        <></>
      ) : (
        <>
          <Flex alignItems="center" pb={5}>
            <Box position="relative">
              <AlertIcon size={30} color="envy"></AlertIcon>
            </Box>
            <Box pl={[5, 5, 3, 3]}>
              You only get 1 chance at setting your username, so make it count!
            </Box>
          </Flex>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabeledInputText
          mt={isPopup ? 0 : 6}
          input={{
            id: "username",
            name: "username",
            defaultValue: formState.dirtyFields.hasOwnProperty("username")
              ? ""
              : state.username,

            disabled: allstarUser.user?.profile?.settings?.usernameChange > 0,
            ...register("username", {
              required: false,
            }),
          }}
          label="username"
          error={errors.username}
          placeholder={t("username")}
        />

        {isPopup ? (
          <></>
        ) : (
          <>
            <Box pt={5}>
              <LabeledSelect
                input={{
                  id: "countryCode",
                  name: "countryCode",
                  ...register("countryCode", {
                    required: false,
                  }),
                }}
                placeholder={t("country")}
                options={options}
                topList={topList}
                defaultValue={state.country ? state.country : ""}
              />
            </Box>

            {Object.keys(state.socials).map((social) => {
              const socialData = state.socials[social];
              return (
                <>
                  {" "}
                  <LabeledInputText
                    mt={isPopup ? 0 : 6}
                    input={{
                      id: socialData.name,
                      name: socialData.name,
                      defaultValue: formState.dirtyFields.hasOwnProperty(
                        socialData.name,
                      )
                        ? ""
                        : socialData.username,

                      disabled: false,
                      ...register(socialData.name, {
                        required: false,
                      }),
                    }}
                    error={errors.username}
                    placeholder={socialData.name}
                    prefix={socialData.prefix}
                    forcePlaceholderTop={true}
                  />
                </>
              );
            })}
          </>
        )}

        {state.error ? <Error> {state.errorMsg}</Error> : <></>}
        {state.success ? <Success>{state.successMsg}</Success> : <></>}

        <Flex pt={[5]} justifyContent="flex-start">
          <Button size="small" flex={true} mt={3} disabled={!formState.isDirty}>
            {t("saveProfile")}
          </Button>
          <Button
            type="button"
            size="small"
            flex={true}
            classification="subtle"
            ml={4}
            mt={3}
            onClick={() => {
              if (isPopup) {
                history.push(`/${URLS.ACCOUNT_SETTINGS}`);
              }
              reset({ dirtyFields: false, isSubmitted: false });
              setState({
                ...state,
                error: false,
                success: false,
                errorMsg: "",
              });
            }}
          >
            {t("cancel")}
          </Button>
        </Flex>
      </form>
    </StyledSettingsContainer>
  );
}
