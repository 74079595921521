import React, { useEffect } from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import { useRecoilState } from "recoil";

import Remixing from "../../../animations/remixing.json";
import { Subheader } from "../Text";
import { AllstarModalState } from "../../State/modals";
import { ModalType } from "../../@types";
import { getQueryParam } from "../../Utilities/QueryParam";
import { ProcessingButton } from "../../Views/MatchHistory/styledComponents";

type TProcessingProps = {
  total?: number;
  user?: string;
  clickable?: boolean;
};

const lottieStyle = {
  height: 36,
  width: 36,
};

export function ProcessingNotification({
  total,
  user,
  clickable,
}: TProcessingProps) {
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const showPendingClips = getQueryParam("showPendingClips");

  useEffect(() => {
    if (!showPendingClips) return;

    setAllstarModalState({
      data: {
        user: user,
      },
      functions: {},
      isOpen: ModalType.TransitTime,
    });
  }, [user, setAllstarModalState, showPendingClips]);

  return (
    <>
      <ProcessingButton
        flex={true}
        clickable={clickable}
        disabled={!clickable}
        onClick={() => {
          if (!clickable) return;
          setAllstarModalState({
            data: {
              user: user,
            },
            functions: {},
            isOpen: ModalType.TransitTime,
          });
        }}
      >
        {total && <StyledNumber>{total}</StyledNumber>}
        <StyledPendingText>Pending Clips</StyledPendingText>
        <Lottie animationData={Remixing} style={lottieStyle} loop={true} />
      </ProcessingButton>
    </>
  );
}

const StyledNumber = styled(Subheader)`
  font-weight: 600;
  color: ${(props) => props.theme.colors.envy};
  padding: 5px;
  background-color: ${(props) => props.theme.colors.midnightAlpha50};
  border-radius: 999px;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  align-items: center;
  &:hover {
    background-color: ${(props) => props.theme.colors.midnightAlpha50};
  }
`;

const StyledPendingText = styled(Subheader)`
  font-weight: 500;
  color: ${(props) => props.theme.colors.ice};
  margin-right: 10px;
  align-items: center;
`;
