import { atom } from "recoil";
import { ModalType } from "../@types";

export interface IClipFullModal {
  isOpen: ModalType;
  shareId: string;
}

export const AllstarModalState = atom({
  key: "AllstarModalState_v0.1",
  default: {
    data: {} as any,
    functions: {} as any,
    isOpen: ModalType.None,
  },
});

export const ClipFullModalState = atom({
  key: "ClipFullModalState_v0.1",
  default: { isOpen: ModalType.None, shareId: "" } as IClipFullModal,
});
