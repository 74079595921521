import React, { useState, memo } from "react";

import { NavLink } from "../atoms/nav/link";
import { AMark } from "../logo";
import { Button } from "../atoms/form/button";
import styled, { keyframes } from "styled-components";
import theme from "../../theme/themeThree";
import { Flex, Box } from "rebass/styled-components";
import { useRecoilState } from "recoil";
import { NavListPrimary } from "./nav/listPrimary";
import { FiMenu } from "react-icons/fi";
import { ShowOnMedium } from "../utils";
import { NavMobile } from "./nav/mobile";
import { Avatar } from "../atoms/avatar";
import { NavListDropdown } from "./nav/listDropdown";
import { Tooltip } from "../atoms/tooltip";
import { useUser } from "../../MVVM/Hooks/useUser";
import { ReactComponent as Credits } from "../../MVVM/Assets/CreateMontage/montage.svg";
import {
  AvatarBorder,
  getAvatarBorderLevel,
  getAvatarImage,
} from "../../MVVM/Utilities/User";
import { MontageCredits } from "../../MVVM/Components/MontageCreditsDropdown";
import { NovuComponent } from "../../MVVM/Components/NovuComponent";
import { DividerAlt } from "../../MVVM/Components/Divider";
import { LedgerState } from "../../MVVM/State/ledger";

function AuthenticatedNavAction({ user, logout }) {
  const avatarBorderLevel = getAvatarBorderLevel(user);

  const [ledgerState] = useRecoilState(LedgerState);

  return (
    <>
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="flex-end"
        style={{ gap: "10px", marginBottom: "10px" }}
      >
        <DropdownContainer
          position={{
            bottom: "calc(100% - 20px)",
            left: "-0px",
          }}
          tooltipContent={<MontageCredits />}
        >
          <CreditsClickable>
            <CreditIcon />
            <TotalCreditsContainer>
              <Box>
                {ledgerState.localBalance > 100
                  ? "\u221E"
                  : ledgerState.localBalance}
              </Box>
            </TotalCreditsContainer>
          </CreditsClickable>
        </DropdownContainer>
        <NovuComponent />
        <DropdownContainerAvatar
          position={{
            bottom: "calc(100% - 15px)",
            left: "-10px",
          }}
          tooltipContent={
            <NavListDropdown
              name="profile-dropdown"
              loggedInUser={user}
              logoutOnClick={() => {
                logout(user._id);
              }}
            />
          }
        >
          <AvatarContainer>
            <AvatarBorder size="sideRail" type={avatarBorderLevel}>
              <Avatar size="nav" image={getAvatarImage(user)} />
            </AvatarBorder>
          </AvatarContainer>
        </DropdownContainerAvatar>
      </Flex>
    </>
  );
}

const Nav = memo(({ showModal }) => {
  const [open, setOpen] = useState(false);
  const { allstarUser, logout } = useUser();

  const pathname = window.location.href.replace(window.location.origin, "");
  const [download] = useState({
    link: null,
    version: null,
    loading: true,
  });

  return (
    <>
      <Flex sx={{ ...theme.navBase }} variant="nav">
        <StyledWrapper alignItems="center" alignContent="center">
          <NavLinksContainer
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Flex
              alignItems="center"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <StyledLogoLink to="/" className="logo">
                <BrandLogo id="allstar-logo" variant="logo.nav" />
              </StyledLogoLink>
              <DividerAlt width="60%" marginTop="0px" />
              <NavListPrimary
                mr={theme.margins.navLinks}
                showModal={showModal}
                loggedin={allstarUser.loggedIn}
                alignItems="center"
              />
            </Flex>
            {allstarUser.loggedIn ? (
              <AuthenticatedNavAction
                user={allstarUser.loggedIn ? allstarUser.user : null}
                download={download}
                logout={logout}
              />
            ) : (
              <>
                <Flex
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    size="tiny"
                    classification="roundedFilledAlt"
                    mb={4}
                    style={{ width: "85%" }}
                    to={`/login?next=${pathname}`}
                  >
                    Login
                  </Button>
                  <AnimatedButton
                    size="tiny"
                    classification="roundedFilled"
                    mb={4}
                    style={{
                      width: "85%",
                    }}
                    to={`/register?next=${pathname}`}
                  >
                    Sign Up
                  </AnimatedButton>
                </Flex>
              </>
            )}
          </NavLinksContainer>
          <StyledShowOnMedium>
            <Flex
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                margin: "auto",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 15,
                marginLeft: 15,
                marginTop: 2,
              }}
            >
              <NavLink to="/">
                <BrandLogo variant="logo.nav" />
              </NavLink>
              <MenuIcon onClick={() => setOpen(!open)} />
            </Flex>
          </StyledShowOnMedium>
        </StyledWrapper>
      </Flex>
      <StyledShowOnMedium variant="navPlaceholder" />
      <NavMobile
        open={open}
        setOpen={setOpen}
        showModal={showModal}
        payload={allstarUser}
        logoutOnClick={() => {
          logout(allstarUser.user._id);
        }}
      />
    </>
  );
});

export { Nav };

const gradient = keyframes`
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
`;

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const AnimatedButton = styled(Button)`
  background: linear-gradient(-45deg, #72439a, #1278a1, #4dbd94);
  background-size: 200% 200%;
  animation:
    ${gradient} 15s ease infinite,
    ${shakeAnimation} 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
`;

const TotalCreditsContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.turboAlpha10};
  color: ${(props) => props.theme.colors.turbo};
  border-radius: 6px;
  min-width: 24px;
  height: 24px;
  padding: 5px;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
`;

const CreditIcon = styled(Credits)`
  height: 24px;
  width: 24px;
`;

const CreditsClickable = styled(Flex)`
  height: 90px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  width: 70px;
  align-items: center;
  padding-left: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 8px;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.darkerNed};
  }
`;

const DropdownContainer = styled(Tooltip)`
  &:hover {
    cursor: default;
  }
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    padding: 0;
  }
`;

const StyledShowOnMedium = styled(ShowOnMedium)``;

const MenuIcon = styled(FiMenu)`
  font-size: 1.75em;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    align-items: flex-end;
  }
`;

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const DropdownContainerAvatar = styled(Tooltip)`
  margin-top: 8px;
  &:hover {
    cursor: default;
  }
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    padding: 0;
  }
`;

const AvatarContainer = styled(Flex)`
  width: 46px;
  background: ${(props) => props.theme.colors.darkerNed};
  padding: 3px;
  border-radius: 999px;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.midnightAlpha50};
  }
`;

const StyledLogoLink = styled(NavLink)`
  flex: 0 1 auto;
  min-height: 70px;
  margin-top: 10px;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    align-items: flex-start;
    min-height: 30px;
  }
`;

const NavLinksContainer = styled(Flex)`
  flex: 0 0 auto;
  height: 100%;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
`;

const BrandLogo = styled(AMark)`
  max-height: 40px;
  overflow: visible;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
  }
`;
