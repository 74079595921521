import React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components";

const KeyElement = styled(Box)`
  text-transform: capitalize;
`;

const ValueElement = styled(Box)``;

export function Tag(props: any) {
  return (
    <Box
      variant="tag"
      className={!props.onClick ? "no-hover" : undefined}
      onClick={props.onClick}
      id={props.id}
    >
      <KeyElement variant="tagKey">{props.title}</KeyElement>
      {["string", "number"].indexOf(typeof props.value) !== -1 ? (
        <ValueElement>{props.value}</ValueElement>
      ) : null}
    </Box>
  );
}
