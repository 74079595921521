import React from "react";
import { Box, Flex, Image } from "rebass/styled-components";
import styled from "styled-components";

import { useIsMobileApp } from "../../Hooks/useIsMobileApp";
import { useIsDesktopApp } from "../../Hooks/useIsDesktopApp";

interface CampaignFootnoteProps {
  description?: string;
  verifiedBadge?: string | null;
}

const CampaignFootnote: React.FC<CampaignFootnoteProps> = ({
  description,
  verifiedBadge,
}) => {
  const { isMobileApp } = useIsMobileApp();
  const { isDesktop } = useIsDesktopApp();

  if (isMobileApp || isDesktop) return <></>;
  return (
    <FootnoteContainer>
      <Flex fontSize="1" color="chalk">
        {verifiedBadge && (
          <Box>
            <StyledSvg
              src={verifiedBadge}
              paddingTop="5px"
              marginRight="12px"
            />
          </Box>
        )}
        {description}
      </Flex>
    </FootnoteContainer>
  );
};

export { CampaignFootnote };

const StyledSvg = styled(Image)`
  width: 20px;
  height: 20px;
  align-self: end;
`;

const FootnoteContainer = styled(Flex)`
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
`;
