import React, { ReactNode, useEffect, useState } from "react";
import { Log, SDKType, StatsigClientInterface } from "@statsig/client-core";
import { StatsigContext } from "@statsig/react-bindings";

interface IStatsigProviderProps {
  children: ReactNode | ReactNode[];
  client: StatsigClientInterface;
}

const StatsigProvider = ({ client, children }: IStatsigProviderProps) => {
  const [renderVersion, setRenderVersion] = useState(0);

  useEffect(() => {
    const onValuesUpdated = () => {
      setRenderVersion((v) => v + 1);
    };

    SDKType._setBindingType("react");

    client.$on("values_updated", onValuesUpdated);

    return () => {
      client.shutdown().catch((error) => {
        Log.error("An error occured during shutdown", error);
      });

      client.off("values_updated", onValuesUpdated);
    };
  }, [client]);

  return (
    <StatsigContext.Provider value={{ renderVersion, client }}>
      {children}
    </StatsigContext.Provider>
  );
};

export { StatsigProvider };
