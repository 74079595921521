import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

export const ModalContainer = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 10px;
  background: rgba(0, 0, 0, 0.75);
`;

export const ModalDialog = styled(Flex)`
  position: relative;
  width: auto;
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 48px);
  max-width: 1000px;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`;
