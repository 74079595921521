import React, { useState, useEffect } from "react";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { StaticContainer } from "../../components/wrappers/staticContainer";
import { PageHeader } from "../../components/molecules/pageHeader";
import { Box } from "rebass/styled-components";
import { Error } from "../../components/atoms/form/error";
import { FormLink } from "../../components/atoms/form/link";
import { Button } from "../../components/atoms/form/button";
import { Row as FormRow } from "../../components/molecules/form/row";
import { InputWithLabel } from "../../components/molecules/form/inputWithLabel";
import { CheckboxWithLabel } from "../../components/molecules/form/checkboxWithLabel";
import { useForm } from "react-hook-form";
import { ContentAnnouncement } from "../../components/molecules/contentAnnouncement";
import Axios from "axios";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";

const AboveGradientContent = styled(PaddedWrapper).attrs({
  display: "box",
})`
  margin-top: -80px;
  position: relative;
  max-width: 535px;
`;

const FormContainer = styled(Box)`
  margin: auto;
  max-width ${(props) => props.theme.sizes[3]}px;
`;

const Gradient = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    transparent,
    ${(props) => props.theme.colors[props.theme.comingSoon.bg]} 80%
  );
`;

const StyledPaddedWrapper = styled(PaddedWrapper)`
  position: relative;
`;

export default function ComingSoon() {
  let [state, setState] = useState({
    done: false,
    loading: false,
    error: false,
  });
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = async (form) => {
    if (state.loading) {
      return;
    }

    setState({
      ...state,
      loading: true,
    });

    const opt = {
      method: "POST",
      url: "https://mktg.allstar.gg/form/submit?formId=12",
      data: { mauticform: form.mauticform },
      withCredentials: true,
    };

    try {
      let response = await Axios(opt);
      if (response.status === 200) {
        window.rudderanalytics.track("Interest - Studio Sign Up", {
          path: window.location.pathname,
        });
        return setState({
          done: true,
          loading: false,
          error: false,
        });
      } else {
        return setState({
          ...state,
          loading: false,
          error: true,
        });
      }
    } catch (e) {
      return setState({
        ...state,
        loading: false,
        error: true,
        errorMsg: e.message, //'Server error',
      });
    }
  };

  useEffect(() => {
    window.rudderanalytics.page("ComingSoon", window.location.pathname);
  });

  return (
    <>
      <PageHeader
        title="Coming soon: Allstar Studio"
        subtitle="A new revolution in content creation is about to begin!"
      />
      <StaticContainer py={[7, 7, 10]}>
        <StyledPaddedWrapper>
          <Gradient />
        </StyledPaddedWrapper>
        <AboveGradientContent>
          <ContentAnnouncement
            title="Try it out first!"
            subtitle="Want to help us test Allstar Studio, or just want to know when we're ready to launch? Drop us your email and we'll be in touch!"
          />
          <FormContainer>
            {state.done ? (
              <Box textAlign="center" mb={[5, 5, 0]}>
                Great &mdash; you're on the list! We'll email you when Allstar
                Studio is ready to test out.
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputWithLabel
                  input={{
                    id: "email",
                    name: "mauticform[email_address]",
                    placeholder: "Email",
                    ...register("mauticform[email_address]", {
                      required: true,
                      validate: (value) =>
                        isEmail(value) ? true : "Invalid email address",
                    }),
                  }}
                  error={errors.mauticform && errors.mauticform.email_address}
                  label="Email"
                  applySpace={true}
                />

                <FormRow>
                  <CheckboxWithLabel
                    checkbox={{
                      id: "agree",
                      name: "agree",
                      ref: register({
                        required:
                          "You must read and agree to the Terms of Use and Privacy Policy",
                      }),
                    }}
                    error={errors.agree}
                  >
                    I have read and agree to Allstar’s{" "}
                    <FormLink to="/legal/terms-of-use">Terms of Use</FormLink>{" "}
                    and{" "}
                    <FormLink to="/legal/privacy-policy">
                      Privacy Policy
                    </FormLink>
                  </CheckboxWithLabel>
                </FormRow>

                <input
                  type="hidden"
                  ref={register()}
                  name="mauticform[formId]"
                  value="12"
                />
                <input
                  type="hidden"
                  ref={register()}
                  name="mauticform[return]"
                  value=""
                />
                <input
                  type="hidden"
                  ref={register()}
                  name="mauticform[formName]"
                  value="studiocomingsoon"
                />
                <input
                  type="hidden"
                  ref={register()}
                  name="mauticform[messenger]"
                  value="1"
                />

                {state.error && state.errorMsg ? (
                  <Error>{state.errorMsg}</Error>
                ) : (
                  <></>
                )}

                <Button disabled={state.loading} type="submit">
                  {state.loading ? "Submitting..." : "Submit"}
                </Button>
              </form>
            )}
          </FormContainer>
        </AboveGradientContent>
      </StaticContainer>
    </>
  );
}
