import React, { useState } from "react";
import { PaddedWrapper } from "../../../../Components/Wrappers";
import { FloatingContent } from "../../../../Components/Wrappers";
import { FloatingContainer } from "../../../../Components/Wrappers";
import { Redirect } from "react-router";
import { Error, InputWithLabel } from "../../../../Components/Inputs";
import { Button } from "../../../../Components/Button";
import { Row } from "../../../../Components/Form";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";

export default function TikTokEmail() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let error = params.get("error");
  if (error) return <Redirect to="/signup?error=access_denied" />;
  return (
    <PaddedWrapper
      my={[6, 8, 10]}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <FloatingContainer>
        <FloatingContent title="Finish TikTok Sign up">
          <Form />
        </FloatingContent>
      </FloatingContainer>
    </PaddedWrapper>
  );
}

export function Form() {
  let [state, setState] = useState({
    loading: false,
    error: false,
    errorMsg: null,
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { code } = useQuery();
  const onSubmit = async (form: any) => {
    if (state.loading) {
      return;
    }
    setState({
      ...state,
      loading: true,
    });
    localStorage.setItem("tiktokEmail", form.email);
    window.location.href = `/oauth/tiktok?code=${code}`;
    setState({
      ...state,
      errorMsg: null,
      loading: false,
      error: false,
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel
          input={{
            id: "email",
            placeholder: "Email",
            ...register("email", {
              required: true,
              validate: (value: any) =>
                isEmail(value) ? true : "Invalid email address",
            }),
          }}
          error={errors.email}
          label="Email"
          applySpace={true}
        />
        {state.error && state.errorMsg ? (
          <Error>{state.errorMsg}</Error>
        ) : (
          <></>
        )}
        <Row>
          <Button disabled={state.loading} type="submit">
            {state.loading ? "Submitting..." : "Complete Signup"}
          </Button>
        </Row>
      </form>
    </>
  );
}
function useQuery() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let code = params.get("code");
  return { code };
}
