import React, { useEffect } from "react";
import { Body } from "../../components/text";
import Legal from "./index";
import { Link } from "../../components/atoms/link";
import styled from "styled-components";

const StyledBody = styled(Body)`
  line-height: 1.444;
  font-size: 1.125rem;
  text-align: left;
`;

export default function Privacy() {
  useEffect(() => {
    window.rudderanalytics.page("Legal", "Privacy");
  });
  const isMobileApp = window.location.pathname.includes("mobile-app-link");

  return (
    <Legal>
      <StyledBody>Last updated on January 5th, 2023</StyledBody>
      <div style={{ paddingBottom: 100 }}>
        <StyledBody>&nbsp;</StyledBody>
        <StyledBody>
          <strong>Allstar Gaming, Inc.</strong>
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Website Privacy Policy</strong>
        </StyledBody>
        <StyledBody>&nbsp;</StyledBody>
        <StyledBody>
          <strong>
            <u>Introduction</u>
          </strong>
        </StyledBody>
        <br></br>
        <StyledBody>
          ALLSTAR GAMING, INC. (<strong>"Company"</strong> or
          <strong>&nbsp;"We"</strong>) respects your privacy and is committed to
          protecting it through our compliance with this Privacy Policy
          (the&nbsp;
          <strong>“Policy”</strong>).
        </StyledBody>
        <br></br>
        <StyledBody>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit the website located at{" "}
          <Link to="">https://allstar.gg </Link> (our "<strong>Website</strong>
          ") and our practices for collecting, using, maintaining, protecting,
          and disclosing that information.
        </StyledBody>
        <StyledBody>This policy applies to information we collect:</StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px", flexDirection: "column" }}>
            <li>On this Website.</li>
            <li>
              In email, text, chat bots, and other electronic messages between
              you and this Website and/or other services provided by the Company
              or made available to you.
            </li>
            <li>
              Through mobile and desktop applications you download from this
              Website, which provide dedicated non-browser-based interaction
              between you and this Website and/or other services provided by the
              Company or made available to you.
            </li>
            <li>
              When you interact with our advertising and applications on
              third-party websites and services, if those applications or
              advertising include links to this policy.
            </li>
          </ul>
        </StyledBody>
        <StyledBody>It does not apply to information collected by:</StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              Us offline or through any other means, including on any other
              Website operated by Company or any third party (including our
              affiliates and subsidiaries); or
            </li>
            <li>
              Any third party (including our affiliates and subsidiaries),
              including through any application or content (including
              advertising) that may link to or be accessible from or on the
              Website.
            </li>
          </ul>
        </StyledBody>
        <StyledBody>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time (see{" "}
          <u>Changes to Our Privacy Policy</u>). Your continued use of this
          Website after we make changes is deemed to be acceptance of those
          changes, so please check the policy periodically for updates.
        </StyledBody>
        <StyledBody>This Policy applies to:</StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              All visitors (“<strong>Site Visitors”</strong>) to the Website;
            </li>
            <li>
              Anyone who downloads and uses (
              <strong>“Platform Visitors”</strong>) the Company's web or
              software platform (the <strong>“Platform”</strong>);
            </li>
            <li>
              Anyone who enrolls for the <strong>“Services”</strong> of the
              Company (<strong>“Clients”</strong>).
            </li>
          </ul>
        </StyledBody>
        <StyledBody>
          Site Visitors, Platform Visitors, and Clients are referred to
          collectively as “Users” or “you”, interchangeably.
        </StyledBody>
        <StyledBody>
          In order to ensure the free, informed, and unambiguous consent of our
          Users to the collection and use of personal data, the Company requires
          all Users to read this Policy and accept the terms and conditions
          contained herein, together with consent to release personal data
          subject to such terms and conditions.
        </StyledBody>
        <br></br>
        <StyledBody>
          Capitalized terms used but not otherwise defined shall have the
          respective meanings ascribed thereto in the Company's Terms of Use
          located at{" "}
          <a href="https://allstar.gg/legal/terms-of-use">
            https://allstar.gg/legal/terms-of-use
          </a>{" "}
          (the <strong>“User Agreement”</strong>).
        </StyledBody>
        <StyledBody>
          <br></br>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          Policy. This Policy may change from time to time (see Changes to Our
          Privacy Policy). Your continued use of this Website after we make
          changes is deemed to be acceptance of those changes, so please check
          the Policy periodically for updates.{" "}
        </StyledBody>
        <StyledBody>&nbsp;</StyledBody>
        <StyledBody>
          <strong>
            <u>Children Under the Age of 13</u>
          </strong>
        </StyledBody>
        <StyledBody>
          Our Website is not intended for children under 13 years of age. No one
          under age 13 may provide any information to or on the Website. We do
          not knowingly collect personal information from children under 13. If
          you are under 13, do not use or provide any information on this
          Website or through any of its features, register on the Website, make
          any purchases through the Website, use any of the interactive or
          public comment features of this Website, or provide any information
          about yourself to us, including your name, address, telephone number,
          email address, or any screen name or user name you may use. If we
          learn we have collected or received personal information from a child
          under 13 without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a child under 13, please contact us at support@allstar.gg.
        </StyledBody>
        <StyledBody>
          California residents under 13 years of age may have additional rights
          regarding the collection and sale of their personal information.
          Please see <u>Your California Privacy Rights</u> (below) for more
          information.
        </StyledBody>
        <StyledBody>&nbsp;</StyledBody>
        <StyledBody>
          <strong>
            <u>Topics</u>
          </strong>
        </StyledBody>
        <StyledBody>This Policy addresses the following topics:</StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>What data do we collect?</li>
            <li>How do we collect your data?</li>
            <li>How will we use your data?</li>
            <li>How will we store your data?</li>
            <li>Data retention and deletion</li>
            <li>Data usage and processing</li>
            <li>Data sharing and disclosure</li>
            <li>Choices About How We Use and Disclose Your Information</li>
            <li>Marketing</li>
            <li>What are your data protection rights?</li>
            <li>California Residents</li>
            <li>What are cookies?</li>
            <li>How do we use cookies?</li>
            <li>What types of cookies and other tracking do we use?</li>
            <li>How to manage your cookies</li>
            <li>Privacy policies of other websites</li>
            <li>Changes to our privacy policy</li>
            <li>How to contact us</li>
            <li>How to contact the appropriate authorities</li>
          </ul>
        </StyledBody>
        <StyledBody>
          <strong>
            <u>What Data We Collect</u>
          </strong>
        </StyledBody>
        <StyledBody>
          We collect some Personal Information about you from our Website to
          maintain the Website and to tailor the Website to your needs,
          including information:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              Information that you provide by filling in forms on our Website.
              Records and copies of your correspondence (including email
              addresses), if you contact us;
            </li>
            <li>
              Your responses to surveys that we might ask you to complete for
              research purposes;{" "}
            </li>
            <li>
              Details of transactions you carry out through our Website and of
              the fulfillment of your orders. You may be required to provide
              financial information before placing an order through our Website;
            </li>
            <li>Your search queries on the Website; and</li>
            <li>
              Metadata and other information associated with other files stored
              on your device. This may include, for example, photographs, audio
              and video clips.
            </li>
          </ul>
        </StyledBody>
        <StyledBody>
          You also may provide information to be published or displayed
          (hereinafter, <strong>"posted"</strong>) on public areas of the
          Website, or transmitted to other users of the Website or third parties
          (collectively, <strong>"User Contributions"</strong>). Your User
          Contributions are posted on and transmitted to others at your own
          risk. Although we limit access to certain pages, please be aware that
          no security measures are perfect or impenetrable. Additionally, we
          cannot control the actions of other users of the Website with whom you
          may choose to share your User Contributions. Therefore, we cannot and
          do not guarantee that your User Contributions will not be viewed by
          unauthorized persons.
        </StyledBody>
        <StyledBody>
          The information we collect on or through our Website may include:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              Information that you provide by filling in forms on our Website.
              Records and copies of your correspondence (including email
              addresses), if you contact us.
            </li>
            <li>
              Your responses to surveys that we might ask you to complete for
              research purposes.
            </li>
            <li>
              Details of transactions you carry out through our Website and of
              the fulfillment of your orders. You may be required to provide
              financial information before placing an order through our Website.
            </li>
            <li>Your search queries on the Website.</li>
            <li>
              Metadata and other information associated with other files stored
              on your device. This may include, for example, photographs, audio
              and video clips,
            </li>
          </ul>
        </StyledBody>
        <StyledBody>
          You also may provide information to be published or displayed
          (hereinafter, "<strong>posted</strong>") on public areas of the
          Website, or transmitted to other users of the Website or third parties
          (collectively, "<strong>User Contributions</strong>"). Your User
          Contributions are posted on and transmitted to others at your own
          risk. Although we limit access to certain pages, please be aware that
          no security measures are perfect or impenetrable. Additionally, we
          cannot control the actions of other users of the Website with whom you
          may choose to share your User Contributions. Therefore, we cannot and
          do not guarantee that your User Contributions will not be viewed by
          unauthorized persons.
        </StyledBody>
        <StyledBody>&nbsp;</StyledBody>
        <StyledBody>
          <strong>
            <u>How Do We Collect Your Data</u>
          </strong>
        </StyledBody>
        <StyledBody>We collect this information:</StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>Directly from you when you provide it to us.</li>
            <li>
              Automatically as you navigate through the site. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies, web beacons, and other
              tracking technologies.
            </li>
            <li>
              Register online or place an order for any of our products or
              Services
            </li>
            <li>
              Voluntarily complete a customer survey or provide feedback on any
              of our message boards or via email
            </li>
            <li>Use or view the Website via your browser's cookies</li>
            <li>Download or use the Platform</li>
            <li>
              Submit content to the Website, like reviews, messages, or comments
            </li>
            <li>
              Enable access to their device's location-tracking functionality,
              whether the Website or Platform is running in the foreground
              (Platform or Website open and on-screen) or background (Platform
              or Website open but not on-screen). Certain functionality may be
              affected while location data is turned off.
            </li>
          </ul>
        </StyledBody>

        <StyledBody>
          Clients expressly and directly provide personal data, including
          payment information, to the Company via the Website when enrolling for
          the Services. Clients expressly acknowledge and agree that the data is
          necessary for the Company to provide the Services and that without the
          data the Company is unable to provide such Services.
        </StyledBody>
        <StyledBody>
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              Details of your visits to our Website, including traffic data,
              location data, logs, and other communication data and the
              resources that you access and use on the Website.
            </li>
            <li>
              Information about your computer and internet connection, including
              your IP address, operating system, and browser type.
            </li>
            <li>
              Device and network configuration, software for gaming and usage,
              game data (including data from gaming sessions), and system
              performance, all of which may be collected by Allstar's desktop
              software to learn about user's gaming habits and preferences to
              optimize Allstar's future offerings.
            </li>
          </ul>
        </StyledBody>
        <StyledBody>
          The information we collect automatically may contain statistical data,
          but we may maintain it or associate it with Personal Information we
          collect in other ways or receive from third parties. It helps us to
          improve our Website and to deliver a better and more personalized
          service, including by enabling us to:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>Estimate our audience size and usage patterns.</li>
            <li>
              Store information about your preferences, allowing us to customize
              our Website according to your individual interests.
            </li>
            <li>Speed up your searches.</li>
            <li>Recognize you when you return to our Website.</li>
          </ul>
        </StyledBody>
        <StyledBody>
          The technologies we use for this automatic data collection may
          include:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              <strong>Cookies (or browser cookies)</strong>. A cookie is a small
              file placed on the hard drive of your computer or mobile device.
              You may refuse to accept browser cookies by activating the
              appropriate setting on your browser. They are widely used in order
              to make websites and software applications work, or work in a
              better, more efficient way. They can do this because websites and
              software applications can read and write these files, enabling
              them to recognize you and remember important information that will
              make your use of a website or software application more convenient
              (e.g., by remembering your user preferences). However, if you
              select this setting you may be unable to access certain parts of
              our Website. Unless you have adjusted your browser setting so that
              it will refuse cookies, our system will issue cookies when you
              direct your browser to our Website.{" "}
            </li>
            <li>
              <strong>Web Beacons.</strong> Pages of our Website and our e-mails
              may contain small electronic files known as web beacons (also
              referred to as clear gifs, pixel tags, and single-pixel gifs) that
              permit the Company, for example, to count users who have visited
              those pages or opened an email and for other related website
              statistics (for example, recording the popularity of certain
              website content and verifying system and server integrity).
            </li>
          </ul>
        </StyledBody>
        <StyledBody>
          We do not collect Personal Information automatically, but we may tie
          this information to Personal Information about you that we collect
          from other sources or you provide to us.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>How Will We Use Your Data</u>
          </strong>
        </StyledBody>
        <StyledBody>
          We use the information that we collect about you or that you provide
          to us, including any Personal Information:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>To present our Website and its contents to you.</li>
            <li>
              To provide you with information, products, or services that you
              request from us.
            </li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>
              To carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection.
            </li>
            <li>
              To notify you about changes to our Website, Platform or any
              products or services we offer or provide through the foregoing.
            </li>
            <li>
              In any other way we may describe when you provide the information.
            </li>
            <li>
              If you have any sanctions on your account or your account is
              banned due to not complying with our Terms of Services.
            </li>
            <li>For any other purpose with your consent.</li>
          </ul>
        </StyledBody>
        <StyledBody>
          We may also use your information to contact you about our own and
          third parties' goods and services that may be of interest to you. If
          you do not want us to use your information in this way, please use the
          unsubscribe button in on your user profile page. For more information,
          see Choices About How We Use and Disclose Your Information (below).
        </StyledBody>
        <br></br>
        <StyledBody>
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers' target audiences. Even
          though we do not disclose your Personal Information for these purposes
          without your consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria. <strong>Disclosure of Your Information</strong>
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>How Do We Store Your Data</u>
          </strong>
        </StyledBody>
        <StyledBody>
          The Company securely stores your data on servers leased from a
          reputable third party hosting service located in the United States.
          Data is retained in accordance with the Company's User Agreement.
        </StyledBody>
        <br></br>
        <StyledBody>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.
        </StyledBody>
        <br></br>
        <StyledBody>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your Personal
          Information, we cannot guarantee the security of your Personal
          Information transmitted to our Website or Platform. Any transmission
          of Personal Information is at your own risk. We are not responsible
          for circumvention of any privacy settings or security measures
          contained on the Website.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>Data Retention and Deletion</u>
          </strong>
        </StyledBody>
        <StyledBody>
          The Company retains user profile, transaction, and other information
          for as long as a user maintains their account.
        </StyledBody>
        <br></br>
        <StyledBody>
          Users may request deletion of their account at any time. Following
          such requests, the Company deletes the data that it is not required to
          retain for purposes of regulatory, tax, insurance, litigation, or
          other legal requirements. For example, the Company retains location,
          device, and usage data for these purposes for a minimum of 7 years;
          while it retains such data, it may also use it for purposes of safety,
          security, fraud prevention and detection, and research and
          development. In certain circumstances, the Company may be unable to
          delete a User's account, such as if there's an outstanding credit on
          the account or an unresolved claim or dispute. Upon resolution of the
          issue preventing deletion, the Company will delete the account as
          described above.
        </StyledBody>
        <br></br>
        <StyledBody>
          The Company may also retain certain information, if necessary, for
          purposes of safety, security, and fraud prevention. When you use our
          Services, we ask that you comply with our Terms of Services associated
          with our Services which are intended to protect our users, employees,
          and more generally the Company's rights. For example, if you do not
          abide by our policies, you may be sanctioned and the Company may
          deactivate your account including in the event of unsafe behavior or
          security incidents. The Company will retain certain information and
          Data in accordance with the sanctions on the account to prevent that
          user from opening a new account in the future. This Data may include
          for example your Account identifier, your game data, your IP address,
          the identifier of your device, and your chat history
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>Data Sharing and Disclosure</u>
          </strong>
        </StyledBody>
        <StyledBody>
          We may disclose aggregated information about our Users, and
          information that does not identify any individual, without
          restriction. We may disclose Personal Information that we collect or
          you provide as described in this privacy policy:
        </StyledBody>

        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>To our subsidiaries and affiliates.</li>
            <li>
              To contractors, service providers, and other third parties we use
              to support our business and who are bound by contractual
              obligations to keep Personal Information confidential and use it
              only for the purposes for which we disclose it to them.
            </li>
            <li>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of ALLSTAR GAMING, INC.’s assets,
              whether as a going concern or as part of bankruptcy, liquidation,
              or similar proceeding, in which Personal Information held by
              ALLSTAR GAMING, INC. about our Users is among the assets
              transferred.{" "}
            </li>
            <li>To fulfill the purpose for which you provide it. </li>
            <li>
              For any other purpose disclosed by us when you provide the
              information.{" "}
            </li>
            <li>With your consent. </li>
          </ul>
        </StyledBody>
        <br></br>
        <StyledBody>We may also disclose your Personal Information:</StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request.
            </li>
            <li>
              To enforce or apply our terms of use located at{" "}
              <Link
                to={
                  isMobileApp
                    ? "/mobile-app-link/terms-of-use"
                    : "/legal/terms-of-use"
                }
              >
                https://allstar.gg/legal/terms-of-use
              </Link>{" "}
              and other agreements, including for billing and collection
              purposes.
            </li>
            <li>
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others. This includes exchanging information with other companies
              and organizations for the purposes of fraud protection and credit
              risk reduction.{" "}
            </li>
          </ul>
        </StyledBody>

        <br></br>
        <StyledBody>
          <strong>
            <u>Choices About How We Use and Disclose Your Information</u>
          </strong>
        </StyledBody>
        <StyledBody>
          We strive to provide you with choices regarding the Personal
          Information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              Tracking Technologies and Advertising. You can set your browser to
              refuse all or some browser cookies, or to alert you when cookies
              are being sent. To learn how you can manage your Flash cookie
              settings, visit the Flash player settings page on Adobe's Website.
              If you disable or refuse cookies, please note that some parts of
              this site may then be inaccessible or not function properly.
            </li>
            <li>
              Disclosure of Your Information for Third-Party Advertising. If you
              do not want us to share your Personal Information with
              unaffiliated or non-agent third parties for promotional purposes,
              you can opt-out by sending an e-mail to{" "}
              <a href="mailto:support@allstar.gg">support@allstar.gg</a>
            </li>
            <li>
              Targeted Advertising. If you do not want us to use information
              that we collect or that you provide to us to deliver
              advertisements according to our advertisers' target audience
              preferences, you can opt-out by sending an e-mail to{" "}
              <a href="mailto:support@allstar.gg">support@allstar.gg</a>
            </li>
          </ul>
        </StyledBody>
        <StyledBody>
          We do not control third parties' collection or use of your information
          to serve interest-based advertising. However, these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative ("NAI") on the NAI's
          website.
        </StyledBody>

        <br></br>
        <StyledBody>
          <strong>
            <u>Marketing</u>
          </strong>
        </StyledBody>
        <StyledBody>
          The Company may send its Users information about products and services
          of the Company, always with the consent of the User. Clients consent
          to marketing and promotional communication when they enroll for the
          Services pursuant to the User Agreement.
        </StyledBody>
        <br></br>
        <StyledBody>
          If you have agreed to receive such marketing materials, you may always
          opt out at a later date. You have the right at any time to stop the
          Company from contacting you for marketing purposes.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>What Are Your Data Protection Rights?</u>
          </strong>
        </StyledBody>
        <StyledBody>
          <strong>Your GDPR Privacy Rights.</strong> The Company is committed to
          making you fully aware of all of your data protection rights. Every
          User is entitled to the following:
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>The right to access</strong> - You have the right to request
          from the Company copies of your personal data. We may charge you a
          small fee for this service.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>The right to rectification</strong> - You have the right to
          request that the Company correct any information you believe is
          inaccurate. You also have the right to request the Company to complete
          information you believe is incomplete.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>The right to erasure</strong> - You have the right to request
          that the Company erase your personal data, under certain conditions.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>The right to restrict processing</strong> - You have the right
          to request that the Company restrict the processing of your personal
          data, under certain conditions.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>The right to object to processing</strong> - You have the
          right to object to the Company's processing of your personal data,
          under certain conditions.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>The right to data portability</strong> - You have the right to
          request that the Company transfer the data collected to another
          organization, or directly to you, under certain conditions.
        </StyledBody>
        <br></br>
        <StyledBody>
          Users who voluntarily provide their personal data in order for the
          Company to provide Services do so pursuant to the Company's User
          Agreement. The retention, return, transfer, and destruction of
          personal data provided for such purposes is subject to the terms of
          such User Agreement. If you make a request, the Company has one month
          to respond to you. If you would like to exercise any of these rights,
          please contact us at the information provided below.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>Your California Privacy Rights</u>
          </strong>
        </StyledBody>
        <StyledBody>
          <strong>Summary</strong> -
          <a href="leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1798.83.">
            California Civil Code Section 1798.83
          </a>{" "}
          permits residents of the State of California to request from certain
          businesses with whom the California resident has an established
          business relationship a list of all third parties to which the
          business, during the immediately preceding calendar year, has
          disclosed certain personally identifiable information for direct
          marketing purposes. We are required to respond to a customer request
          only once during any calendar year. To make such a request you should
          contact support@allstar.gg. In your request, please attest to the fact
          that you are a California resident and provide a current California
          address for our response. Please be aware that not all information
          sharing is covered by the California Privacy Rights requirements and
          only information sharing that is covered will be included in our
          response.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            Right to Know About Information Collected, Disclosed or Sold
          </strong>{" "}
          - You have the right to request that we disclose what personal
          information we collect, use, disclose, and sell. To submit a
          verifiable request, please visit{" "}
          <Link to="/legal/do-not-sell-my-personal-information">
            https://allstar.gg/legal/do-not-sell-my-personal-information
          </Link>{" "}
          or email <a href="mailto:support@allstar.gg">support@allstar.gg</a> If
          you have an account with us, we will verify the request by confirming
          the email address used to make the request is the same as the email
          address on file for the account. If you do not have an account with
          us, we will verify the request by sending an email to the email
          address used to make the request. We may ask for additional
          information reasonably related to the request to verify the request.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Collection of Personal Information</strong> - The categories
          of California consumers Personal Information we may collect include
          the following: personal details, demographic information, contact
          details, consent records, location information, purchase and payment
          details, employer details, views and opinions. We also collect other
          kinds of information from you or other sources, which may include, but
          is not limited to, information about your use of, visits to and
          interaction and engagement with the Services, content and ads on the
          Website and/or Platform, all of which may be aggregated; browsing
          history; and searches for and interactions with e-commerce
          opportunities, such as merchants and offers contained in the Services.
        </StyledBody>
        <br></br>
        <StyledBody>
          We may collect California consumers' Personal Information from the
          sources listed in the Section titled, “How Do We Collect Your Data?”
          As defined in that section, this may include:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>Directly from you when you provide it to us.</li>
            <li>
              Automatically as you navigate through the site. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies, web beacons, and other
              tracking technologies.
            </li>
            <li>
              Register online or place an order for any of our products or
              Services
            </li>
            <li>
              Voluntarily complete a customer survey or provide feedback on any
              of our message boards or via email
            </li>
            <li>Use or view the Website via your browser's cookies</li>
            <li>Download or use the Platform</li>
            <li>
              Submit content to the Website, like reviews, messages, or comments
            </li>
            <li>
              Enable access to their device’s location-tracking functionality,
              whether the Website or Platform is running in the foreground
              (Platform or Website open and on-screen) or background (Platform
              or Website open but not on-screen). Certain functionality may be
              affected while location data is turned off.
            </li>
          </ul>
        </StyledBody>
        <br></br>
        <StyledBody>
          We may obtain personal information from the following sources as well:
          GeoIP Data; ID; IDFA; IP address; ISP; SSID; IMSI; IMEI; Log files;
          Web Beacons; Pixel Tags; Local Shared Objects, such as Flash cookies,
          and Local Storage, such as HTML5, and equivalents. We use this data
          for the purposes listed above in the section titled, “How Will We Use
          Your Data” or for direct marketing as outlined in the section titled,
          “Marketing.”
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Disclosure or Sale of Personal Information</strong> - We may
          have disclosed or sold the categories of Personal Information listed
          within this Policy for a business or commercial purpose. We may have
          disclosed such Personal Information to the following categories of
          third parties for a business purpose:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              other entities within the Company or its affiliate(s) for a
              legitimate business purpose such as operating the Services and
              providing the Services to you;
            </li>
            <li>
              third-party processors for the purpose of providing the Services
              to you;
            </li>
            <li>
              third-party providers where our Services use third-party
              advertising, plugins or content;
            </li>
            <li>
              third-party providers to assist with the collection, storage and
              segmentation of online data. These third-party providers may
              collect user information from our Services for their own purposes,
              including but not limited to, monitoring fraud around the web;
            </li>
            <li>
              third parties for the purpose of recognizing our users and
              delivering interest-based content and advertisements to them. We
              may share your user information with our partners such as your
              name, postal address, email, or other identifier. Our partners may
              also: (i) collect information directly from your device, such as
              your IP address, device ID, advertising ID, and information about
              your browser or operating system; (ii) combine user information
              about you received from the Company with information about you
              from other sites or services; and (iii) place or recognize a
              unique cookie on your browser; and
            </li>
            <li>
              third party vendors involved in the administration of contests,
              competitions, sweepstakes, prize draws, or webcasts.
            </li>
          </ul>
        </StyledBody>
        <br></br>
        <StyledBody>
          We may have disclosed or sold Personal Information to the following
          categories of third parties for a business purpose or a commercial
          purpose:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>
              third-party providers where our Services use third-party
              advertising, plugins or content. If you choose to interact with
              any such advertising, plugins or content, information about your
              activities on the Services and Website to provide you targeted
              advertising based upon your interests may be shared with the
              relevant third-party provider. If you click or tap on or otherwise
              interact with an advertisement, the advertiser may assume that you
              meet its target criteria;
            </li>
            <li>
              third parties for the purpose of recognizing our users and
              delivering interest-based content and advertisements to them. We
              may share your user information with our partners such as your
              name, postal address, email, or other identifiers. Our partners
              may also: (i) collect information directly from your device, such
              as your IP address, device ID, advertising ID, and information
              about your browser or operating system; (ii) combine user
              information about you received from the Company with information
              about you from other sites or services; and (iii) place or
              recognize a unique cookie on your browser;
            </li>
            <li>
              third parties such as advertisers who will use online data to
              serve ads that they believe are relevant to your interests, and
              who agree to maintain the confidentiality of this information.
              Some of these third parties may combine the online data with their
              own data about you to form a more detailed picture;
            </li>
            <li>
              third-party partners such as marketing and advertising companies
              and agencies, content publishers, retailers, third-party providers
              of goods and services; or service providers whose services are
              embedded into and/or appear within the Services when you use a
              co-branded service (a service operated with a partner of the
              Company), or register or otherwise provide information on such
              co-branded site. This information is only passed on if and when
              you grant us permission to pass the collected information back to
              that third party;
            </li>
            <li>
              Any sponsors of contests, competitions, sweepstakes and prize
              draws, or white papers, webcasts or other downloads, the
              registration data for such events is to be used by us as part of
              user information, and may be shared with the sponsor and others,
              subject to this Policy and the sponsor's privacy policy.
            </li>
            <li>
              third party providers of goods and services on a rental or other
              basis for some of our Services when information (such as the name
              of our users, their mailing address, phone number, email address -
              and in certain circumstances their employer's name, company size
              and other industry data) is provided in connection with webcasts,
              white papers or other sponsored downloads.
            </li>
            <li>
              If you run a speed test, ISPs and mobile carriers may receive full
              IP addresses and/or network hardware or devices identifiers for
              such ISP or mobile carrier's customers upon request. Equipment
              manufacturers and core network or wholesale providers may also
              receive full IP addresses and/or network, hardware and device
              identifiers on a case-by-case basis for network research purposes.
              Third parties may receive GeoIP Data and/or precise location data.
            </li>
          </ul>
        </StyledBody>
        <br></br>
        <StyledBody>
          The Services are not intended for use by minors. We do not sell
          personal information of minors under 16 years of age without
          affirmative authorization.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Right to Request Deletion of Personal Information</strong> -
          You have the right to request deletion of Personal Information
          collected or maintained. To do so, please submit a request via{" "}
          <Link to="/legal/do-not-sell-my-personal-information">
            https://allstar.gg/legal/do-not-sell-my-personal-information
          </Link>{" "}
          or email <a href="mailto:support@allstar.gg">support@allstar.gg</a>.
          If submitting a request by email, we may ask for additional
          information to verify your request.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Right to Opt-Out of the Sale of Personal Information</strong>{" "}
          - You have the right to opt-out of the sale of Personal Information by
          a business. To do so, please submit a request via{" "}
          <Link to="/legal/do-not-sell-my-personal-information">
            https://allstar.gg/legal/do-not-sell-my-personal-information
          </Link>{" "}
          or email <a href="mailto:support@allstar.gg">support@allstar.gg</a>.
          If submitting a request by email, we may ask for additional
          information to verify your request.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            Right to Non-Discrimination for the Exercise of a Consumer's Privacy
            Rights
          </strong>{" "}
          - You have the right not to receive discriminatory treatment by the
          business for exercising your privacy rights conferred to you by the
          California Consumer Privacy Act.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Authorized Agent</strong> - To make a request under the
          California Consumer Privacy Act on a consumer's behalf, we require a
          signed authorization letter from the consumer to{" "}
          <a href="mailto:support@allstar.gg">support@allstar.gg</a>.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Contact for More Information</strong> - If you have any
          questions or concerns about our privacy policies and practices, you
          may contact us via{" "}
          <Link to="/legal/do-not-sell-my-personal-information">
            https://allstar.gg/legal/do-not-sell-my-personal-information
          </Link>{" "}
          or email <a href="mailto:support@allstar.gg">support@allstar.gg</a>.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>Date Privacy Policy Last Updated</strong> - Our Privacy Policy
          was lasted updated as of the date indicated at the beginning of the
          Policy.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>What Are Cookies?</u>
          </strong>
        </StyledBody>
        <StyledBody>
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit the Website, we may collect information from you automatically
          through cookies or similar technology.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>How Do We Use Cookies?</u>
          </strong>
        </StyledBody>
        <StyledBody>
          The Company uses cookies and similar technologies for purposes such
          as:
        </StyledBody>
        <StyledBody>
          <ul style={{ marginLeft: "30px" }}>
            <li>Authenticating Users</li>
            <li>Remembering User preferences and settings</li>
            <li>Determining the popularity of content</li>
            <li>
              Delivering and measuring the effectiveness of advertising
              campaigns
            </li>
            <li>
              Analyzing site traffic and trends, and generally understanding the
              online behaviors and interests of people who interact with our
              services
            </li>
          </ul>
        </StyledBody>
        <br></br>
        <StyledBody>
          We may also allow others to provide audience measurement and analytics
          services for us, to serve advertisements on our behalf across the
          Internet, and to track and report on the performance of those.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>What Types of Cookies And Other Tracking Do We Use?</u>
          </strong>
        </StyledBody>
        <StyledBody>
          Some content or applications, including advertisements, on the Website
          are served by third-parties, including advertisers, ad networks and
          servers, content providers, and application providers. These third
          parties may use cookies alone or in conjunction with web beacons or
          other tracking technologies to collect information about you when you
          use our Website. The information they collect may be associated with
          your Personal Information or they may collect information, including
          Personal Information, about your online activities over time and
          across different websites and other online services. They may use this
          information to provide you with interest-based (behavioral)
          advertising or other targeted content.
        </StyledBody>
        <br></br>
        <StyledBody>
          This site uses Google Analytics which is one of the most widespread
          and trusted analytics solutions on the web for helping us to
          understand how you use the site and ways that we can improve your
          experience. These cookies may track things such as how long you spend
          on the site and the pages that you visit so we can continue to produce
          engaging content. For more information on Google Analytics cookies,
          see the official Google Analytics page.{" "}
        </StyledBody>
        <br></br>
        <StyledBody>
          We do not control these third parties' tracking technologies or how
          they may be used. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider
          directly. For information about how you can opt out of receiving
          targeted advertising from many providers, see Choices About How We Use
          and Disclose Your Information (below).{" "}
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>How To Manage Your Cookies?</u>
          </strong>
        </StyledBody>
        <StyledBody>
          You can set your browser not to accept cookies, and you can remove
          cookies from your browser. However, in a few cases, some of the
          Website features may not function as a result.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>Privacy Policies of Other Websites</u>
          </strong>
        </StyledBody>
        <StyledBody>
          The Website contains links to other Websites. This Policy applies only
          to the Website. If you navigate to any other websites from a link on
          the Website, you should review the privacy policy of such websites.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>YouTube and Google</u>
          </strong>
        </StyledBody>
        <StyledBody>
          You may connect a Google account to Allstar to utilize our YouTube
          sharing functionality. To allow Users to connect a Google account and
          utilize our YouTube sharing functionality, Allstar's API utilize
          YouTube API Services which are covered by{" "}
          <a href="www.google.com/policies/privacy">
            {" "}
            Google's privacy policy found here.
          </a>{" "}
          By connecting your Google account to Allstar, you are agreeing to be
          bound by the{" "}
          <a href="https://www.youtube.com/t/terms">
            YouTube Terms of Service which can be found here.
          </a>
        </StyledBody>
        <br></br>
        <StyledBody>
          If you wish to revoke any access to your Google Data via the Allstar
          API for any reason, you may do so via your{" "}
          <a href="myaccount.google.com/permissions">
            {" "}
            Google security settings page located here
          </a>
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>Changes to Our Privacy Policy</u>
          </strong>
        </StyledBody>
        <StyledBody>
          It is our policy to post any changes we make to the Policy on this
          page. If we make material changes to how we treat our users' Personal
          Information, through a notice on the Website home page. The date the
          Policy was last revised is identified at the top of the page. You are
          responsible for ensuring we have an up-to-date active and deliverable
          email address for you, and for periodically visiting our Website and
          this Policy to check for any changes.
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>How To Contact Us </u>
          </strong>
        </StyledBody>
        <StyledBody>
          To ask questions or comment about this Policy and our privacy
          practices, contact us at:
        </StyledBody>
        <br></br>
        <StyledBody>Allstar Gaming, Inc.</StyledBody>
        <StyledBody>
          99 Wall Street, Suite 2337 New York, New York 10005
        </StyledBody>
        <StyledBody>
          E-mail: <a href="mailto:support@allstar.gg">support@allstar.gg </a>
        </StyledBody>
        <StyledBody>
          To register a complaint or concern, please contact:{" "}
          <a href="mailto:support@allstar.gg">support@allstar.gg </a>
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>How To Contact The Appropriate Authorities</u>
          </strong>
        </StyledBody>
        <StyledBody>
          Should you wish to report a complaint or if you feel that the Company
          has not addressed your concern in a satisfactory manner, you may
          contact your local Data Protection Authority.
        </StyledBody>
        <br></br>
        <StyledBody>
          <a href="https://edpb.europa.eu/about-edpb/board/members_en">
            Click here for a list of jurisdictions, together with their
            respective Data Protection Authority and related contact
            information.
          </a>
        </StyledBody>
        <br></br>
      </div>
    </Legal>
  );
}
