import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { BiSolidChevronRight } from "react-icons/bi";

import { Button, Link } from "../../Components/Button";
import { Headline, Large2, Large3 } from "../../Components/Text";
import { ReactComponent as Empty } from "../../Assets/MatchHistory/empty.svg";
import { ReactComponent as EmptyFinished } from "../../Assets/MatchHistory/empty-finished.svg";
import { Modal } from "../../Components/Wrappers";
import { Column } from "../MatchHistory/styledComponents";
import { Error } from "../../Components/Inputs";

export const Separator = styled("span")`
  display: inline-block;
  padding: 0 8px;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    padding: 0 2px;
  }

  :before {
    content: "/";
  }
`;

export const MatchHistoryPlatformIcon = styled.div<{ img: string }>`
  ${(props) => `background-image: url(${props.img});`}
  background-repeat: no-repeat;
  background-size: contain;
  height: 18px;
  position: absolute;
  top: 2px;
  right: 3px;
  width: 18px;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    height: 14px;
    width: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    height: 10px;
    width: 10px;
  }
`;

export const MatchHistoryMapIcon = styled("div")<{ img: string }>`
  position: relative;
  background-size: contain;
  border-radius: 4px;
  height: 100px;
  width: 61px;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    background-size: contain;
    height: 50px;
    width: 30px;
  }

  ${(props) => `background: url(${props.img}) no-repeat;`}
`;

export const MapName = styled("p")`
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.text.subheader.fontSize};
  }
`;

export const RoundedButton = styled(Button)`
  border-radius: 20px;
  width: 100px;
`;

export const RefreshMatchesButton = styled(Button)`
  border-radius: 20px;
  font-size: ${({ theme }) => theme.text.subheader.fontSize};
  width: auto;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-right: 0;
  }
`;

export const RefreshMatchesButtonComplete = styled(RefreshMatchesButton)`
  padding-left: 22px;
  padding-right: 18px;
  :disabled,
  :hover:disabled {
    background-color: ${({ theme }) => theme.colors.envy};
    color: ${({ theme }) => theme.colors.ice};
    cursor: not-allowed;
  }
`;

export const GetMatchButton = styled(Button)`
  width: 100px;
  border-radius: 20px;
  margin-top: 30px;
`;

export const StyledEmpty = styled(EmptyFinished)`
  width: 1120px;
  max-width: 100%;
  height: auto;
  align-self: start;
`;

export const StyledEmptyFirst = styled(Empty)`
  width: 1020px;
  max-width: 100%;
  height: auto;
  align-self: start;
`;

export const StyledChevron = styled(BiSolidChevronRight)`
  color: ${({ theme }) => theme.colors.chalk};
`;

export const Heading2 = styled(Large2)`
  all: unset;
  color: #f7fcfd;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    font-size: 18px;
  }
`;

export const SubHeading = styled(Large3)`
  color: ${(props) => props.theme.colors.ice};
  font-weight: 600;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: 24px;
  }
`;

export const MomentHeader = styled(Headline)`
  all: unset;
  display: flex;
  gap: 6px;
  width: 100%;
  justify-content: start;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
`;

export const StaticColumn = styled(Flex)`
  flex-direction: column;
  flex-basis: 60%;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 2;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    flex-basis: 70%;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-basis: 100%;
  }
`;

export const AvatarRow = styled(Flex)`
  flex-direction: row;
  width: 100%;
  gap: 10px;
`;

export const AvatarRowCentered = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  width: 70%;
  gap: 4px;
  flex-wrap: wrap;
`;

export const Main = styled(Flex)`
  min-height: 100vh;
  justify-content: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    height: 100%;
    flex-direction: column;
    justify-content: start;
  }
`;

export const RightSideColumn = styled(Flex)`
  height: 100%;
  flex-direction: column;
  align-content: start;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding-bottom: 50px;
  width: calc(100vw - 50vw);

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    width: 100vw;
  }
`;

/* The browser specific CSS is to avoid z-index bugs in Safari for our modals,
   with the trade-off being losing independent column scrolling  */
export const RightSideScroll = styled(Flex)<{ isSafari: boolean }>`
  position: ${({ isSafari }) => (isSafari ? "initial" : "fixed")};
  width: ${({ isSafari }) => (isSafari ? "100%" : "40%")};
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;
  background: #18252d;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const PlayOfTheGameHeader = styled(Flex)`
  height: auto;
  flex-direction: column;
  padding: 50px 50px 25px 50px;

  @media (max-width: 1500px) {
    padding: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    padding: 20px;
  }
`;

export const HighlightColumn = styled(Flex)`
  height: auto;
  flex-direction: column;
  padding: 0px 50px 12px 50px;
  @media (max-width: 1500px) {
    padding: 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    padding: 20px;
  }
`;

export const PlatformWrapper = styled(Flex)`
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background: ${(props) => props.theme.colors.ice};
`;

export const POTGWrapper = styled(Column)`
  width: fit-content;
  flex-basis: auto;
`;

export const StyledSteam = styled(Flex)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.championAlpha70};
  border: 2px solid ${({ theme }) => theme.colors.championAlpha90};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

export const FlashBannerWrapper = styled(Flex)`
  width: 100%;
`;

const FlashBanner = styled(Box)`
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.navy};
  display: flex;
  font-size: ${({ theme }) => theme.text.footnote.fontSize};
  font-weight: 500;
  margin: 22px 20px 21px 0;
  padding: 6px 12px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-right: 0;
  }
`;

export const FlashBannerSuccess = styled(FlashBanner)`
  border: 1px solid ${({ theme }) => theme.colors.envy};
  background-color: ${({ theme }) => theme.colors.flashSuccess};
`;

export const FlashBannerWarning = styled(FlashBanner)`
  border: 1px solid ${({ theme }) => theme.colors.error};
  background-color: ${({ theme }) => theme.colors.flashWarning};
`;

export const MatchPreviewContent = styled(Flex)<{ backgroundImage: string }>`
  width: 100%;
  background: url(${(props) => props.backgroundImage}) 47px 52px no-repeat;
  background-size: 380px;
  position: absolute;
  top: 160px;
  min-height: 380px;
  flex-direction: column;
  align-items: center;
  justify-content: middle;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    background-image: none;
  }
`;

export const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.alert};
  font-size: ${({ theme }) => theme.text.headline.fontSize};
  margin-top: 20px;
`;

export const SuccessText = styled(ErrorText)`
  color: ${({ theme }) => theme.colors.envy};
`;

export const DropdownContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
`;

export const Dropdown = styled(Flex)`
  flex-direction: column;
  color: ${(props) => props.theme.colors.ice};
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.navy};
  border-radius: 8px;
  padding: 10px;
`;

export const DropdownTopSection = styled(Flex)`
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  text-align: left;
  min-width: 75px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  height: 35px;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.midnightAlpha50};
    color: ${(props) => props.theme.colors.envy};
  }
`;

export const StyledModal = styled(Modal)`
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledError = styled(Error)`
  width: 80%;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 100%;
  }
`;
