import React from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import theme from "../../../../../theme/themeThree";
import { OverlayShare } from "../../../../Components/Button/PlayerOverlay";

const StyledShare = styled(OverlayShare)`
  height: 55px;
  width: 55px;
  padding-top: 0px;

  @media (max-width: 450px) {
    padding-top: 6px;
  }
`;

const DefaultBorder: any = styled(Box)`
  position: absolute;
  transform: translateX(-50px);
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  opacity: 0;

  ${(props: any) => {
    //if props.display, unset right and set position to relative
    if (props.display) {
      return `
            opacity:1;
            transform: translateX(${props.index * -60}px);
            @media (max-width: 650px) {
              transform: translateX(${props.index * -45}px);
            }
            @media (max-height: 330px) {
              transform: translateX(${props.index * 45}px);
            }
        `;
    }
  }}
`;
//right:${props.index * 50}px;
export const SharePopout = ({
  overlayConfig,
  display,
  setAttemptedAction,
}: any) => {
  return (
    <>
      <PopoutFlex display={display}>
        {" "}
        <DefaultBorder
          sx={{ ...theme.borders["overlayDefault"] }}
          display={display}
          index={1}
        >
          <StyledShare
            classification={"overlayDefault"}
            size={"overlayOption"}
            href={overlayConfig.share.link}
            type={"discord"}
            onClick={() => {
              try {
                window.rudderanalytics.track("Overlay - Share Click", {
                  knownUser: overlayConfig?.knownUser,
                  shareId: overlayConfig?.shareId,
                  partner: overlayConfig?.partner?.name,
                  useCase: overlayConfig?.partner?.useCase,
                  type: "Discord",
                });
              } catch (e) {}
              if (!overlayConfig.knownUser) {
                setAttemptedAction("discord");
              } else {
                window.open(overlayConfig.share.discordShare, "_blank");
              }
            }}
          />
        </DefaultBorder>
        <DefaultBorder
          sx={{ ...theme.borders["overlayDefault"] }}
          display={display}
          index={2}
        >
          <StyledShare
            classification={"overlayDefault"}
            size={"overlayOption"}
            href={overlayConfig.share.link}
            type={"youtube"}
            onClick={() => {
              try {
                window.rudderanalytics.track("Overlay - Share Click", {
                  knownUser: overlayConfig?.knownUser,
                  shareId: overlayConfig?.shareId,
                  partner: overlayConfig?.partner?.name,
                  useCase: overlayConfig?.partner?.useCase,
                  type: "Youtube",
                });
              } catch (e) {}
              if (!overlayConfig.knownUser) {
                setAttemptedAction("youtube");
              } else {
                window.open(overlayConfig.share.youtubeShare, "_blank");
              }
            }}
          />
        </DefaultBorder>
        <DefaultBorder
          sx={{ ...theme.borders["overlayDefault"] }}
          display={display}
          index={3}
        >
          <StyledShare
            classification={"overlayDefault"}
            size={"overlayOption"}
            href={overlayConfig.share.link}
            type={"tiktok"}
            onClick={() => {
              try {
                window.rudderanalytics.track("Overlay - Share Click", {
                  knownUser: overlayConfig?.knownUser,
                  shareId: overlayConfig?.shareId,
                  partner: overlayConfig?.partner?.name,
                  useCase: overlayConfig?.partner?.useCase,
                  type: "TikTok",
                });
              } catch (e) {}
              if (!overlayConfig.knownUser) {
                setAttemptedAction("tiktok");
              } else {
                window.open(overlayConfig.share.tiktokShare, "_blank");
              }
            }}
          />
        </DefaultBorder>
        <DefaultBorder
          sx={{ ...theme.borders["overlayDefault"] }}
          display={display}
          index={4}
        >
          <StyledShare
            classification={"overlayDefault"}
            size={"overlayOption"}
            href={overlayConfig.share.link}
            type={"link"}
            onClick={() => {}}
          />
        </DefaultBorder>
      </PopoutFlex>
    </>
  );
};

const PopoutFlex = styled(Flex)`
  position: absolute;
  top: 0px;
  transform: translateX(50px);
  flex-direction: row;
  gap: 5px;
  z-index: -10;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  ${(props: any) => {
    //if props.display, unset right and set position to relative
    if (props.display) {
      return `
            transform: translateX(0px);
        `;
    }
  }}
  @media (max-height: 330px) {
    position: absolute;
    transform: translateX(-10px);
    top: 45px;
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    ${(props: any) => {
      //if props.display, unset right and set position to relative
      if (props.display) {
        return `
              transform: translateX(-45px);
          `;
      }
    }}
  }
`;
