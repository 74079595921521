import AgencyMap from "../../Assets/MatchHistory/Maps/Agency.png";
import AncientMap from "../../Assets/MatchHistory/Maps/Ancient.png";
import CacheMap from "../../Assets/MatchHistory/Maps/Cache.png";
import DustIIMap from "../../Assets/MatchHistory/Maps/DustII.png";
import InfernoMap from "../../Assets/MatchHistory/Maps/Inferno.png";
import ItalyMap from "../../Assets/MatchHistory/Maps/Italy.png";
import MirageMap from "../../Assets/MatchHistory/Maps/Mirage.png";
import NukeMap from "../../Assets/MatchHistory/Maps/Nuke.png";
import OfficeMap from "../../Assets/MatchHistory/Maps/Office.png";
import OverpassMap from "../../Assets/MatchHistory/Maps/Overpass.png";
import VertigoMap from "../../Assets/MatchHistory/Maps/Vertigo.png";
import TrainMap from "../../Assets/MatchHistory/Maps/Train.png";
import TuscanMap from "../../Assets/MatchHistory/Maps/Tuscan.png";
import DefaultMap from "../../Assets/MatchHistory/Maps/Default.png";

import EsportalIcon from "../../Assets/Platforms/esportal.svg";
import FaceitIcon from "../../Assets/Platforms/faceit.svg";
import GamersclubIcon from "../../Assets/Platforms/gc.svg";
import LeetifyIcon from "../../Assets/Platforms/leetify.svg";
import ScopeIcon from "../../Assets/Platforms/scope.svg";
import SteamIcon from "../../Assets/Platforms/steam.svg";
import { getMapThumbnail } from "../../Utilities/Clip";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

interface MapsType {
  [key: string]: string;
}

export const maps: MapsType = {
  Agency: AgencyMap,
  Ancient: AncientMap,
  Cache: CacheMap,
  "Dust 2": DustIIMap,
  Inferno: InfernoMap,
  Italy: ItalyMap,
  Mirage: MirageMap,
  Nuke: NukeMap,
  Office: OfficeMap,
  Overpass: OverpassMap,
  Train: TrainMap,
  Tuscan: TuscanMap,
  Vertigo: VertigoMap,
  Default: DefaultMap,
};

export const platformIcons = (platform: string) => {
  switch (platform.toLowerCase()) {
    case "esportal":
      return EsportalIcon;
    case "faceit":
      return FaceitIcon;
    case "gc":
      return GamersclubIcon;
    case "leetify":
      return LeetifyIcon;
    case "scope":
      return ScopeIcon;
    case "steam":
      return SteamIcon;
    case "valve":
      return SteamIcon;
    default:
      return SteamIcon;
  }
};

export const clipImage = (clip: TClip, mapName: string) => {
  if (clip?.clipImageThumb) {
    return `${REACT_APP_S3_CLIP_BUCKET}/${clip.clipImageThumb}`;
  } else {
    return getMapThumbnail(mapName);
  }
};

export const getOpacityMapThumbnail = (map: string | undefined) => {
  const MAP_URL = `${REACT_APP_S3_CLIP_BUCKET}/static/matchHistory/matchPreviewMaps`;

  switch (map) {
    case "Dust 2":
      return `${MAP_URL}/dust_1.webp`;
    case "Mirage":
      return `${MAP_URL}/mirage_1.webp`;
    case "Inferno":
      return `${MAP_URL}/inferno_1.webp`;
    case "Overpass":
      return `${MAP_URL}/overpass_1.webp`;
    case "Nuke":
      return `${MAP_URL}/nuke_1.webp`;
    case "Train":
      return `${MAP_URL}/train_1.webp`;
    case "Vertigo":
      return `${MAP_URL}/vertigo_1.webp`;
    case "Ancient":
      return `${MAP_URL}/ancient_1.webp`;
    case "Anubis":
      return `${MAP_URL}/anubis_1.webp`;
    case "Cache":
      return `${MAP_URL}/cache_1.webp`;
    case "Agency":
      return `${MAP_URL}/agency_1.webp`;
    case "Office":
      return `${MAP_URL}/office_1.webp`;
    case "Tuscan":
      return `${MAP_URL}/tuscan_1.webp`;
    default:
      return `${MAP_URL}/dust_1.webp`;
  }
};
