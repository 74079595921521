import { useEffect, useState } from "react";
import { useStatsigClient, useStatsigUser } from "@statsig/react-bindings";

import { User } from "../../Model/User";

const useInitializeExperiment = () => {
  const [experimentLoading, setExperimentLoading] = useState(true);
  const { getExperiment } = useStatsigClient();
  const { user, updateUserAsync } = useStatsigUser();

  useEffect(() => {
    const initialize = async () => {
      const userModel = User.getInstance();
      const decoded = await userModel.DecodeToken();

      await updateUserAsync({
        ...user,
        email: decoded?.sub,
        userID: decoded?._id,
      });
      setExperimentLoading(false);
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    experimentLoading,
    getExperiment,
  };
};

export { useInitializeExperiment };
