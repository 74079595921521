import React from "react";
import CreatorFx from "./sections/creatorfx";
import Frequency from "./sections/frequency";
import styled from "styled-components";
import { PageHeader } from "../../components/molecules/pageHeader";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { Box } from "rebass/styled-components";
import { ReactComponent as Badge } from "../../assets/images/badges/studio_icon.svg";

const Page = styled(PaddedWrapper)`
  width: 100%;
  padding-left: 140px;
  margin: auto;
  column-gap: ${(props) => props.theme.space[9]}px;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    column-gap: ${(props) => props.theme.space[7]}px;
    padding-left: 140px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: column;
    padding-left: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    padding-left: 15px;
  }
`;

const PanelContainer = styled.div`
  overflow: hidden;
  transform: translateZ(0);
  position: relative;
  z-index: 2;
`;

const StudioMarketingPage = () => {
  return (
    <>
      <PageHeader
        image={<Box as={Badge} fill="currentColor" width="130px" />}
        title={
          <>
            Allstar Studio.
            <br />
          </>
        }
        subtitle="Say hello to creator tools... from the future"
      />
      <Page>
        <PanelContainer>
          <CreatorFx />
          <Frequency />
        </PanelContainer>
      </Page>
    </>
  );
};

export default StudioMarketingPage;
