import React, { useEffect, memo } from "react";
import { withRouter } from "react-router-dom";
// import BackgroundImageFortniteClipsDark from "../assets/images/backgrounds/fortnite-dark.jpg";
// import BackgroundImageValorantClipsDark from "../assets/images/backgrounds/valorant-dark.jpg";
// import BackgroundImageLeagueoflegendsClipsDark from "../assets/images/backgrounds/leaguelegends-dark.jpg";
// import BackgroundImageRainbow6ClipsDark from "../assets/images/backgrounds/rainbow6-dark.jpg";
// import BackgroundImageDotaDark from "../assets/images/backgrounds/dota-dark.jpg";
// import BackgroundAllstarBotDark from "../assets/images/backgrounds/allstarbot-dark.jpg";
// import BackgroundImageAllstar from "../assets/images/backgrounds/brand.jpg";
// import BackgroundImageSuperstar from "../assets/images/backgrounds/superstar.jpg";
// import BackgroundImageLegal from "../assets/images/backgrounds/legal.jpg";
// import BackgroundImageBrand from "../assets/images/backgrounds/brand.jpg";
import { createGlobalStyle } from "styled-components";

/* The URI-encoded image is a tinier version of the actual image, which the browser displays while the main image is loading */
let BackgroundGlobalStyle = createGlobalStyle`
  ${() => {
    let styles = `
      body {
        background-color: #1c2a34;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .page-pro {
        align-items: center;
        justify-content: center;
        height: 80vh;
        background-size: cover;
      }

      .page-mobile-app-link {
        background: rgb(12,19,24);
      }

      .page-reset {
        background: linear-gradient(90deg, rgba(26,40,48,1) 0%, rgba(26,42,51,1) 47%, rgba(24,37,45,1) 100%);
      }

      .page-pro {
        background: rgb(35,60,65);
        background: radial-gradient(circle, rgba(35,60,65,1) 0%, rgba(31,46,57,1) 47%, rgba(24,37,45,1) 100%);
      }

      .page-adminstudio {
        background: rgb(35,60,65);
        background: radial-gradient(circle, rgba(35,60,65,1) 0%, rgba(31,46,57,1) 47%, rgba(24,37,45,1) 100%);
      }

      .page-soon {
        background: rgb(35,60,65);
        background: radial-gradient(circle, rgba(35,60,65,1) 0%, rgba(31,46,57,1) 47%, rgba(24,37,45,1) 100%);
      }

      .page-referred {
        background: rgb(28,42,52);
        background: linear-gradient(90deg, rgba(26,40,48,1) 0%, rgba(26,42,51,1) 47%, rgba(24,37,45,1) 100%);
      }

      .page-leetifysignup {
        background: rgb(28,42,52);
        background: linear-gradient(90deg, rgba(26,40,48,1) 0%, rgba(26,42,51,1) 47%, rgba(24,37,45,1) 100%);
      }

      .page-montage {
        background: rgb(28,42,52);
        background: linear-gradient(90deg, rgba(26,40,48,1) 0%, rgba(26,42,51,1) 47%, rgba(24,37,45,1) 100%);
      }
      .page-overwolf {
        background: #0b0c18;
      }
    `;

    return styles;
  }}
`;

const Background = memo(() => {
  useEffect(() => {
    let segment = window.location.pathname.split("/")[1];

    document.body.classList.add(`page-${segment}`);
    return () => {
      document.body.classList.remove(`page-${segment}`);
    };
  });

  return <BackgroundGlobalStyle />;
});

export default withRouter(Background);
