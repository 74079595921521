import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";

export type TRequirement = {
  text: string;
  icon: string;
};

export type TMontageQualify = {
  passed: boolean; // Can we make the montage
  message: string; // Return message
  failedFromCredits: boolean; // Did we fail from no credits? We can also fail for other things, so we should use this to show Upgrade modal...
  balance: number; // users montage balance
};

export type TSequence = {
  sequenceType: string;
  description: string;
  image: string;
  requires: TRequirement[];
  video: string;
  qualify: string;
  buildDoc: {
    amsTiming: number;
    minKills: number;
    maxKills: number;
  };
  comingSoon: boolean;
};

type TGameNumbers = 730 | 7302;

export const GAMES = {
  CounterStrike2: 7302 as TGameNumbers,
  CSGO: 730 as TGameNumbers,
};
export type TQualifyFn = (sequence: TSequence) => Promise<void>;

export type TSequenceData = {
  sequencesLoading: boolean;
  sequenceData: TSequenceQuery | undefined;
  checkSequenceQualify: TQualifyFn;
  setProFeatureModal: (modal: { body: string; isOpen: boolean }) => void;
};

export type TFinalTouchesData = {
  finalTouchesLoading: boolean;
  finalTouchesData: TFinalTouch[];
  setProFeatureModal: (modal: { body: string; isOpen: boolean }) => void;
};

export type TSequenceQuery = {
  sequences: TSequence[];
};

export type TFinalTouch = {
  description: string;
  cards: TMontageCreatorCard[];
  pro: boolean;
  game: TGameNumbers[];
};

export type TSequenceModalState = {
  isOpen: boolean;
  sequence: TSequence | undefined;
};

export type TSequenceProps = {
  sequence: TSequence;
  selected: TSequence;
  qualify: string;
  qualifyFunction: TQualifyFn;
  onSelect: (sequence: any) => void;
  setModal: any;
  disabledFromQualify: boolean;
  disabledFromCredits?: boolean;
  proRequired?: boolean;
};

export type TDisplayCard = {
  ui: {
    image: string;
  };
};

export type TMontageCreatorCard = {
  _id: string;
  ui: {
    image: string;
    hoverEffect: string;
    hoverEffectSrc: string;
    title: string;
    explanation: string;
    rarity: number;
  };
  scopeUse: string[];
  facet: {
    category: string[];
    series: string[];
    type: string[];
  };
};

export type TLoadout = {
  name: string;
  description: string;
  image: string;
  hoverEffect: string;
  badge: string;
  hoverEffectSrc: string;
  displayCards: TDisplayCard[];
  cards: TMontageCreatorCard[];
};

export interface ILoadoutData {
  loadoutsLoading: Boolean;
  loadoutsData: TLoadoutsQuery | undefined;
}

export type TLoadoutsQuery = {
  loadouts: TLoadout[];
};

export type TMontageCreatorCardProps = {
  card: TMontageCreatorCard;
  handleCardClick?: (card: TMontageCreatorCard) => {};
  isModal?: boolean;
};

export type TClipProps = {
  clip: TMontageClip;
  index: number;
  isSelected: boolean;
  selected?: any[];
  isFinale?: boolean;
  willExceedKillLimit: boolean;
  handleSelect: (clip: TMontageClip) => void;
  setModal: (modal: { body: string; isOpen: boolean }) => void;
  hideViewClipButton?: boolean;
  forceColumnDisplay: boolean;
};

export type TMontageClip = {
  clipTitle: string;
  _id: string;
  shareId: string;
  timeline: {
    event: string;
  }[];
  userClipTitle: string;
  views: number;
  createdDate: string;
  clipImageThumb: string;
  clipProperties: any[];
  personalizations: {
    data: {
      kills: any[]; // any array because all we use it for is length.
    };
  };
  cf: {
    hls: string;
    readyToStream: boolean;
    downloadUrl: string;
  };
};

export type TClipsQuery = {
  createMontageClips: TMontageClip[];
};

export interface IClipsData {
  clipsLoading: Boolean;
  clipsData: TClipsQuery | undefined;
  getClips: LazyQueryExecFunction<any, OperationVariables>;
}

export interface TCountProps {
  count: number;
  max: number;
  canProceed: boolean;
}

export interface TCountIndicator extends TCountProps {
  heading: string;
}

export type TMusicQuery = {
  facets: { cards: TMontageCreatorCard[] };
};

export interface IMusicData {
  musicLoading: Boolean;
  musicData: TMusicQuery | undefined;
}

export interface IMusicProps extends IMusicData {
  setModal: (modal: { type: string | number; data?: any }) => void;
}
