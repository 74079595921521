import React, { createContext } from "react";
import { useRecoilValue } from "recoil";
import { AllstarUser } from "./MVVM/State";
import { useUser } from "./MVVM/Hooks/useUser";

export const userStore = createContext();

const { Provider } = userStore;

export const UserStateProvider = ({ children }) => {
  const { allstarUser, setAllstarUser } = useUser();
  return (
    <Provider value={{ payload: allstarUser, setPayload: setAllstarUser }}>
      {children}
    </Provider>
  );
};

export const useGetUser = () => {
  const allstarUser = useRecoilValue(AllstarUser);
  return allstarUser?.loggedIn ? allstarUser.user : null;
};
