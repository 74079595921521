import React from "react";
import { Flex, Box } from "rebass/styled-components";
import { Large3, Title2 } from "../text";
import styled from "styled-components";

const StyledLarge3 = styled(Large3)`
  font-weight: 600;
`;

const StyledPage = styled(Flex)`
  margin: 76px auto 20px;
  padding-left: 80px;
  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding-left: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-left: 0px;
  }
`;

export function PageHeader({ z1, ...props }) {
  return (
    <StyledPage justifyContent="center" style={z1 ? { zIndex: 1 } : undefined}>
      <Box
        textAlign="center"
        maxWidth={6}
        px={6}
        pt={props.pt || [7, 7, 9]}
        pb={props.pb ? props.pb : [5, 5, 8]}
        mb={props.mb ? props.mb : [7, 7, 9]}
      >
        {props.image}
        <StyledLarge3 my={5}>{props.title}</StyledLarge3>
        {props.subtitle ? <Title2>{props.subtitle}</Title2> : <></>}
        {props.posttext ? props.posttext : null}
      </Box>
    </StyledPage>
  );
}
