import React from "react";

import { ESupportedGames, EGameSelectorHash } from "../../@types";
import { ILeagueMatch, ILeagueMatchMoment } from "../MatchHistoryLOL/@types";
import { TMatchHistory, IMatchMoment } from "../MatchHistoryCS2/@types";

type TMatch = ILeagueMatch | TMatchHistory;
type TMoment = ILeagueMatchMoment | IMatchMoment;

export const changeGame = (game: ESupportedGames) => {
  switch (game) {
    case ESupportedGames.CS2:
      return (window.location.hash = EGameSelectorHash.CS2);
    case ESupportedGames.LOL:
      return (window.location.hash = EGameSelectorHash.LOL);
    default:
      return (window.location.hash = EGameSelectorHash.CS2);
  }
};

export const formatDate = (date: string) =>
  new Date(parseInt(date)).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

export const formatTime = (time: number) => {
  const date = new Date(time * 1000).toISOString();
  return time > 3600 ? date.substring(11, 19) : date.substring(14, 19);
};

export const processingClips = (match: TMatch) =>
  match.matchMoments.filter(
    (moment: TMoment) =>
      moment.hasClip && ["Queued", "Submitted"].includes(moment.clip.status),
  ).length;

export const MomentsTotal = ({ match }: { match: TMatch }) => {
  if (match.status === "Preview") return <span>-</span>;

  return <span>{match.matchMoments.length}</span>;
};

export const LeagueMomentsProcessed = ({ match }: { match: TMatch }) => (
  <span className="positive">
    {match.matchMoments.filter((moment: TMoment) => !!moment.clip).length}
  </span>
);

export const MomentsProcessed = ({ match }: { match: TMatch }) => {
  if (match.status === "Preview") return <span>-</span>;

  return (
    <span className="positive">
      {
        match.matchMoments.filter(
          (moment: TMoment) =>
            moment.hasClip &&
            ["Processed", "Ready"].includes(moment.clip?.status),
        ).length
      }
    </span>
  );
};
