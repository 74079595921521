import { luminance, rgba } from "../utils";

const colors = {
  ice: "#f7fcfd",
  darkerIce: "#f6f7fa",
  darkerIceAlpha90: rgba("#f6f7fa", 0.9),
  darkerIceAlpha80: rgba("#f6f7fa", 0.8),
  iceAlpha40: rgba("#f7fcfd", 0.4),
  iceAlpha60: rgba("#f7fcfd", 0.6),
  iceAlpha75: rgba("#f7fcfd", 0.75),
  chalk: "#8fa9af",
  chalkAlpha10: rgba("#8fa9af", 0.1),
  chalkAlpha20: rgba("#8fa9af", 0.2),
  chalkAlpha40: rgba("#8fa9af", 0.4),
  chalkAlpha60: rgba("#8fa9af", 0.6),
  chalkAlpha80: rgba("#8fa9af", 0.8),
  discord: "#5865f2",
  faceit: "#ff935d",
  navy: "#253a49",
  navyAlpha90: rgba("#253a49", 0.9),
  navyAlpha70: rgba("#253a49", 0.7),
  navyAlpha50: rgba("#253a49", 0.5),
  navyAlpha20: rgba("#253a49", 0.2),
  darkerNavy: luminance("#253a49", -0.1),
  midnight: "#0c1318",
  midnightAlpha30: rgba("#0c1318", 0.3),
  midnightAlpha40: rgba("#0c1318", 0.4),
  midnightAlpha50: rgba("#0c1318", 0.5),
  midnightAlpha60: rgba("#0c1318", 0.6),
  midnightAlpha70: rgba("#0c1318", 0.7),
  midnightAlpha80: rgba("#0c1318", 0.8),
  midnightAlpha90: rgba("#0c1318", 0.9),
  mythic: rgba("#C7DAF6", 1),
  mythicAlpha10: rgba("#C7DAF6", 0.1),
  mythicAlpha20: rgba("#C7DAF6", 0.2),
  mythicAlpha40: rgba("#C7DAF6", 0.4),
  mythicAlpha80: rgba("#C7DAF6", 0.8),
  legendary: rgba("#8582E9", 1),
  legendaryAlpha10: rgba("#8582E9", 0.1),
  legendaryAlpha20: rgba("#8582E9", 0.2),
  legendaryAlpha40: rgba("#8582E9", 0.4),
  legendaryAlpha80: rgba("#8582E9", 0.8),
  rare: rgba("#D06AAE", 1),
  rareAlpha10: rgba("#D06AAE", 0.1),
  rareAlpha20: rgba("#D06AAE", 0.2),
  rareAlpha40: rgba("#D06AAE", 0.4),
  rareAlpha80: rgba("#D06AAE", 0.8),
  premium: rgba("#4AB4A7", 1),
  premiumAlpha10: rgba("#4AB4A7", 0.1),
  premiumAlpha20: rgba("#4AB4A7", 0.2),
  premiumAlpha40: rgba("#4AB4A7", 0.4),
  premiumAlpha80: rgba("#4AB4A7", 0.8),
  standard: rgba("#41A575", 1),
  standardAlpha10: rgba("#41A575", 0.1),
  standardAlpha20: rgba("#41A575", 0.2),
  standardAlpha40: rgba("#41A575", 0.4),
  standardAlpha80: rgba("#41A575", 0.8),
  alert: "#F95370",
  alertAlpha20: rgba("#F95370", 0.2),
  alertAlpha80: rgba("#F95370", 0.8),
  error: "#ffa87d",
  errorAlpha05: rgba("#ffa87d", 0.05),
  errorAlpha10: rgba("#ffa87d", 0.1),
  errorAlpha50: rgba("#ffa87d", 0.5),
  darkerError: luminance("#ffa87d", -0.1),
  envy: "#4dbd94",
  credits: "#FB6F75",
  envyAlpha05: rgba("#4dbd94", 0.05),
  envyAlpha10: rgba("#4dbd94", 0.1),
  envyAlpha20: rgba("#4dbd94", 0.2),
  envyAlpha30: rgba("#4dbd94", 0.3),
  envyAlpha40: rgba("#4dbd94", 0.4),
  envyAlpha50: rgba("#4dbd94", 0.5),
  envyAlpha80: rgba("#4dbd94", 0.8),
  envyAlpha90: rgba("#4dbd94", 0.9),
  darkerEnvy: luminance("#4dbd94", -0.2),
  champion: "#1278a1",
  championAlpha70: rgba("#1278a1", 0.7),
  championAlpha90: rgba("#1278a1", 0.9),
  darkerChampion: luminance("#1278a1", -0.1),
  royalty: "#72439a",
  specialBackgroundColor: "#034b8f",
  ned: "#1c2a34",
  nedAlpha50: rgba("#1c2a34", 0.5),
  nedLight: "#1f2d38",
  turbo: "#71AAFF",
  turboAlpha50: rgba("#71AAFF", 0.5),
  turboAlpha20: rgba("#71AAFF", 0.2),
  turboAlpha10: rgba("#71AAFF", 0.1),
  darkerNed: "#18252D",
  darkerNedAlpha20: rgba("#18252D", 0.2),
  darkerNedAlpha50: rgba("#18252D", 0.5),
  darkerNedAlpha80: rgba("#18252D", 0.8),
  flashSuccess: "#E8F8F1",
  flashWarning: "#FFF8F4",
  modalBg: "#10161a",
  gray850: "#141E26",
  grey850: "#141E26",
};

export { colors };
