import React from "react";
import { HiLink, HiOutlineMail } from "react-icons/hi";
import { RiShareForwardFill } from "react-icons/ri";
import {
  FaFacebookF,
  FaRedditAlien,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Box } from "rebass";
import styled from "styled-components";
import { Text } from "rebass/styled-components";
import { Button } from "..";
import { TClipShareProps, TEmailShareProps } from "../@types";
import {
  trackClipSocialShare,
  trackCreatorCardShare,
} from "../../../Analytics";
import { Tooltip } from "../../Tooltip";
import { Subheader } from "../../Text";

const StyledShare = styled(RiShareForwardFill)`
  position: relative;
`;

const DropdownContainer = styled(Tooltip)`
  display: flex;
  min-width: 38px;
  z-index: 1;
  &:hover {
    cursor: default;
  }
`;

export function CopyLink({
  classification,
  clipLink,
  clip,
  card,
  children,
  size,
  hideOnMobile,
  mr,
  isMontage,
  isProfile,
}: any) {
  const EncodedURI = encodeURI(clipLink);
  classification = classification ? classification : "secondaryRounded";

  return (
    <>
      <DropdownContainer
        position={{
          top: "-42px",
          padding: "10px",
          right: "-30px",
        }}
        type="click"
        timeout={true}
        theme="helpTooltip"
        align="right"
        tooltipContent={
          <Box name="url-copied-tooltip">URL copied to clipboard!</Box>
        }
      >
        <Button
          title="Copy Link"
          display="flex"
          size="social"
          width="100%"
          hideOnMobile={hideOnMobile}
          name="clip-options-copy-link"
          classification="secondaryRounded"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          onClick={() => {
            if (clip) {
              trackClipSocialShare({
                platform: "Clipboard",
                isMontage: isMontage,
                clip,
              });
            } else if (card) {
              trackCreatorCardShare({ ...card });
            }
            navigator.clipboard.writeText(EncodedURI);
          }}
        >
          <StyledCopyLink size={20} />
          {children}
        </Button>
      </DropdownContainer>
    </>
  );
}

const StyledCopyLink = styled(HiLink)`
  position: relative;
`;

export function ShareButton(props: any) {
  return <Button {...props}>{props.children}</Button>;
}

export function ClipShare({
  size,
  children,
  classification,
  mr,
  clip,
  onClick,
  hideOnDesktop,
  ...props
}: any) {
  classification = classification ? classification : "secondaryRounded";

  return (
    <>
      <Button
        width="100%"
        hideOnDesktop={hideOnDesktop}
        display="flex"
        size="social"
        classification="secondaryRounded"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        onClick={() => {
          onClick();
        }}
        {...props}
      >
        <StyledShare size={20} />

        {children}
      </Button>
    </>
  );
}

//TODO: Lots of duplicate code here. can be one component
export function EmailShare({ subject, link, children }: TEmailShareProps) {
  //   const subject = card ? card.ui.title : getClipTitle(clip);

  return (
    <ShareButton
      title="Share to Email"
      name="share-modal-email"
      justifyContent={"center"}
      alignItems="center"
      flex={true}
      display="flex"
      target="_blank"
      classification="email"
      href={`mailto:?subject=${subject}&body=${link}`}
    >
      <HiOutlineMail size={24} color="#f8fcfd"></HiOutlineMail>
      {children}
    </ShareButton>
  );
}

export function RedditShare({
  link,
  clip,
  card,
  title,
  children,
}: TClipShareProps) {
  return (
    <>
      <ShareButton
        title="Share to Reddit"
        name="share-modal-reddit"
        justifyContent={"center"}
        alignItems="center"
        flex={true}
        display="flex"
        href={`https://www.reddit.com/submit?url=${encodeURIComponent(link)}${
          title ? `&title=${title}` : ``
        }`}
        target="_blank"
        classification="reddit"
        onClick={() => {
          if (clip) {
            trackClipSocialShare({
              platform: "Reddit",
              clip,
            });
          } else if (card) {
            trackCreatorCardShare(card);
          }
        }}
      >
        <FaRedditAlien size={24} color={"#f8fcfd"}></FaRedditAlien>
        {children}
      </ShareButton>
    </>
  );
}

export function TelegramShare({
  link,
  clip,
  card,
  title,
  children,
}: TClipShareProps) {
  return (
    <>
      <ShareButton
        title="Share to Telegram"
        name="share-modal-telegram"
        justifyContent={"center"}
        alignItems="center"
        flex={true}
        display="flex"
        href={`https://t.me/share/url?url=${link}&text=${title}`}
        target="_blank"
        classification="telegram"
        onClick={() => {
          if (clip) {
            trackClipSocialShare({
              platform: "Telegram",
              clip,
            });
          } else if (card) {
            trackCreatorCardShare(card);
          }
        }}
      >
        <FaTelegramPlane size={24} color={"#f8fcfd"}></FaTelegramPlane>

        {children}
      </ShareButton>
    </>
  );
}

export function WhatsAppShare({ link, clip, card, children }: TClipShareProps) {
  return (
    <>
      <ShareButton
        title="Share to WhatsApp"
        name="share-modal-whatsapp"
        justifyContent={"center"}
        alignItems="center"
        flex={true}
        display="flex"
        href={`https://api.whatsapp.com/send/?phone&text=${link}`}
        target="_blank"
        classification="whatsApp"
        onClick={() => {
          if (clip) {
            trackClipSocialShare({
              platform: "WhatsApp",
              clip,
            });
          } else if (card) {
            trackCreatorCardShare(card);
          }
        }}
      >
        <FaWhatsapp size={24} color={"#f8fcfd"}></FaWhatsapp>

        {children}
      </ShareButton>
    </>
  );
}
export function TwitterShare({
  link,
  clip,
  card,
  title,
  children,
}: TClipShareProps) {
  return (
    <>
      <ShareButton
        title="Share to Twitter"
        name="share-modal-twitter"
        justifyContent={"center"}
        alignItems="center"
        flex={true}
        display="flex"
        href={`https://twitter.com/intent/tweet?url=${link}&text=${title}`}
        target="_blank"
        classification="twitter"
        onClick={() => {
          if (clip) {
            trackClipSocialShare({
              platform: "Twitter",
              clip,
            });
          } else if (card) {
            trackCreatorCardShare(card);
          }
        }}
      >
        <FaTwitter size={24} color={"#f8fcfd"}></FaTwitter>

        {children}
      </ShareButton>
    </>
  );
}

export function FacebookShare({
  link,
  clip,
  card,

  children,
}: TClipShareProps) {
  const EncodedURI = encodeURI(link);

  return (
    <>
      <ShareButton
        title="Share to Facebook"
        name="share-modal-facebook"
        justifyContent={"center"}
        alignItems="center"
        flex={true}
        display="flex"
        href={`https://www.facebook.com/dialog/share?app_id=595195531196882&display=popup&href=${EncodedURI}&redirect_uri=${EncodedURI}`}
        target="_blank"
        classification="facebook"
        onClick={() => {
          if (clip) {
            trackClipSocialShare({
              platform: "Facebook",
              clip,
            });
          } else if (card) {
            trackCreatorCardShare(card);
          }
        }}
      >
        <FaFacebookF size={24} color={"#f8fcfd"}></FaFacebookF>

        {children}
      </ShareButton>
    </>
  );
}

export function ClipboardShare({ link, clip }: TClipShareProps) {
  const EncodedURI = encodeURI(link);

  return (
    <>
      <Tooltip
        position={{
          top: "-42px",
          padding: "10px",
          right: "-30px",
        }}
        type="click"
        timeout={true}
        theme="helpTooltip"
        align="right"
        name="share-modal-clipboard"
        tooltipContent="URL copied to clipboard!"
      >
        <ShareButton
          flex={false}
          width="100%"
          size="publish"
          justifyContent="space-between"
          classification="modalShare"
          alignItems="center"
          onClick={() => {
            trackClipSocialShare({
              platform: "Clipboard",
              clip,
            });

            navigator.clipboard.writeText(EncodedURI);
          }}
        >
          <UriString>{EncodedURI}</UriString>
          <CopyButton>COPY LINK</CopyButton>
        </ShareButton>
      </Tooltip>
    </>
  );
}
const UriString = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.chalk};
`;

const CopyButton = styled(Subheader)`
  border-radius: 4px;
  padding: 4px;
`;
