import React, { forwardRef, useEffect, useState, useMemo } from "react";
import { Box } from "rebass/styled-components";
import { FiEye, FiEyeOff, FiDownloadCloud, FiInfo } from "react-icons/fi";
import { Subheader } from "../Text";
import { Input } from "@rebass/forms/styled-components";
import theme from "../../../theme/themeThree";
import { Flex } from "rebass/styled-components";
import { GoAlert } from "react-icons/go";
import countryList from "react-select-country-list";
import { Select as RebassSelect } from "@rebass/forms/styled-components";
import { space } from "styled-system";

import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
const iconStyles: any = {
  color: (props: any) => props.theme.colors.chalk,
  fontSize: "22px",
};

const StyledEye = styled(FiEye)({ ...iconStyles });

const StyledOffEye = styled(FiEyeOff)({ ...iconStyles });

const PasswordToggle = styled(Box)`
  cursor: pointer;
  position: absolute;
  bottom: 11px;
  right: 30px;
`;

const FormRow = styled(Box)`
  position: relative;
`;

export const Label: any = (props: any) => {
  return <Subheader mb={props.mb ?? 3}>{props.children}</Subheader>;
};

export function Validation(props: any) {
  return (
    <Subheader
      color={props.color ? props.color : "error"}
      mb={props.mb}
      mt={props.mt}
    >
      {props.children}
    </Subheader>
  );
}

export function InputWithLabel(props: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [modifiedInputProps, setModifiedInputProps] = useState(props.input);

  useEffect(() => {
    if (props.input.type !== "password") return;

    setModifiedInputProps((modifiedInputProps: any) => {
      return {
        ...modifiedInputProps,
        type: showPassword ? "text" : "password",
      };
    });
  }, [showPassword, props.input.type]);

  return (
    <FormRow variant={props.applySpace ? "form" : null}>
      {props.error ? (
        <Validation mb={3}>
          {props.label} -{" "}
          {props.error.message ? props.error.message : "Required"}
        </Validation>
      ) : props.label ? (
        <Label>{props.label}</Label>
      ) : (
        <></>
      )}
      <InputText {...modifiedInputProps} error={props.error} />
      {props.input.type === "password" ? (
        <PasswordToggle
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? <StyledOffEye /> : <StyledEye />}
        </PasswordToggle>
      ) : null}
    </FormRow>
  );
}

const StyledInput = styled(Input)((props: any) => {
  return props.error
    ? {
        borderColor: theme.colors.error,
        ":hover": {
          borderColor: theme.colors.error,
        },
        ":active": {
          borderColor: theme.colors.error,
        },
        ":focus": {
          borderColor: theme.colors.error,
        },
      }
    : {};
});

export const InputText = forwardRef((props: any, ref: any) => {
  let size = props.size ? props.size : "default";
  return (
    <>
      <StyledInput
        sx={{
          ...theme.input,
          ...theme.variants.input[size as keyof typeof theme.variants.input],
        }}
        ref={ref}
        type={props.type ? props.type : "text"}
        {...props}
      />
    </>
  );
});

const AlertIcon = styled(GoAlert)(space);
const InfoIcon = styled(FiInfo)(space);
const DownloadIcon = styled(FiDownloadCloud)(space);

const iconType: any = {
  notify: DownloadIcon,
  info: InfoIcon,
  alert: AlertIcon,
};

export function Error(props: any) {
  const showIcon = props.showIcon ?? props?.children?.length < 90;

  const Icon = iconType[props.type] || AlertIcon;

  return (
    <Flex variant="formError" my={3} {...props}>
      {showIcon ? <Icon size={22} mr={4} style={{ marginTop: -2 }} /> : null}

      <Subheader color={props.color ? props.color : "navy"}>
        {props.children}
      </Subheader>
      {props.remove}
    </Flex>
  );
}

const SuccessIcon = styled<any>(FaCheckCircle)(space);

export function Success(props: any) {
  return (
    <Flex variant="formSuccess" my={3} {...props}>
      <SuccessIcon size={22} mr={4} />
      <Subheader mb={1} color={props.color ? props.color : "navy"}>
        {props.children}
      </Subheader>
    </Flex>
  );
}

export function SelectWithLabel(props: any) {
  return (
    <Box variant={props.applySpace ? "form" : null}>
      {props.error ? (
        <Validation mb={3}>
          {props.label} -{" "}
          {props.error.message ? props.error.message : "Required"}
        </Validation>
      ) : (
        <Label>{props.label}</Label>
      )}
      <Select {...props.input} error={props.error} variant={props.variant} />
    </Box>
  );
}

const Select = forwardRef(
  ({ options, placeholder, variant, ...props }: any, ref) => {
    let [hasSelected, setHasSelected] = useState(
      !!options.filter((o: any) => {
        return !!o.selected;
      }).length,
    );

    return (
      <>
        <StyledSelect
          sx={{
            ...theme.input,
            ...(variant ?? theme.variants.select.small),
          }}
          ref={ref}
          onChange={(e: any) => {
            props.handleSelected();
            setHasSelected(!!e.currentTarget.value.length);
          }}
          value={props.value}
          showingPlaceholder={!hasSelected}
          {...props}
        >
          {placeholder ? (
            <option value="" disabled selected={!hasSelected}>
              {placeholder}
            </option>
          ) : null}

          {options.map((option: any, i: any) => {
            return (
              <option value={option.value} key={`option-${i}`}>
                {option.label}
              </option>
            );
          })}
        </StyledSelect>
      </>
    );
  },
);

const StyledSelect = styled(RebassSelect)<any>({
  appearance: "none",
  "-webkit-appearance": "none",
  "-moz-appearance": "none",
  "+ svg": {
    //@ts-ignore
    fill: (props: any) => props.theme.colors.envy,
    // marginLeft: (props) => `-${props.theme.space[7]}px`,
  },
  //@ts-ignore
  color: (props: any) =>
    props.showingPlaceholder ? props.theme.colors.chalk : null,
  ...(props) => {
    return props.error
      ? {
          borderColor: theme.colors.error,
          ":hover": {
            borderColor: theme.colors.error,
          },
          ":active": {
            borderColor: theme.colors.error,
          },
          ":focus": {
            borderColor: theme.colors.error,
          },
        }
      : {};
  },
});

const SelectFakeLabel = styled.div<any>`
  position: absolute;
  color: ${(props) =>
    props.theme.colors[props.theme.input["::placeholder"].color]};
  opacity: 0;
  visibility: hidden;
  line-height: ${(props) => props.theme.variants.input.default.lineHeight};
  transition:
    font-size ${(props) => props.theme.transitionDuration},
    top ${(props) => props.theme.transitionDuration},
    line-height ${(props) => props.theme.transitionDuration},
    background-color ${(props) => props.theme.transitionDuration};
  top: 0;
  padding: 0 5px;
  left: ${(props) =>
    props.theme.space[props.theme.variants.input.default.px] - 5}px;

  .filled & {
    background: ${(props) => props.theme.input.bg};
    visibility: visible;
    font-size: 0.8em;
    opacity: 1;
    top: -0.8em;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 3px;
    line-height: 1em;
    box-shadow: 0px 1px 4px
      ${(props) => props.theme.colors[props.theme.input["::boxshadow"].color]};
  }
`;

const LabeledSelect: any = forwardRef(
  ({ options, placeholder, input, ...props }: any, ref) => {
    let [hasSelected, setHasSelected] = useState(
      !!options.filter((o: any) => {
        return !!o.selected;
      }).length,
    );
    let [isFocused, setIsFocused] = useState(false);

    return (
      <div
        style={{ position: "relative" }}
        className={
          hasSelected || isFocused || props.defaultValue ? "filled" : ""
        }
      >
        <StyledSelect
          sx={{
            ...theme.input,
            ...theme.labeledInput,
            ...theme.variants.select.default,
          }}
          ref={ref}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          defaultValue=""
          onChange={(e: any) => {
            setHasSelected(!!e.currentTarget.value.length);
          }}
          showingPlaceholder={!hasSelected}
          {...props}
          {...input}
        >
          <option value="" disabled selected={!hasSelected}>
            {placeholder}
          </option>
          <>
            {props.topList ? (
              <>
                {props.topList.map((option: any, i: any) => {
                  return (
                    <option value={option.value} key={`option-${i}`}>
                      {option.label}
                    </option>
                  );
                })}
                <option value="--------------" disabled>
                  -----------------------
                </option>
              </>
            ) : null}
          </>
          {options.map((option: any, i: any) => {
            return (
              <option value={option.value} key={`option-${i}`}>
                {option.label}
              </option>
            );
          })}
        </StyledSelect>
        <SelectFakeLabel>{placeholder}</SelectFakeLabel>
      </div>
    );
  },
);

LabeledSelect.defaultProps = {
  selectCallback: () => {},
};

export function CountrySelector(props: any) {
  const options = useMemo(() => countryList().getData(), []);
  const [, setValue] = useState(props.defaultValue);

  function extractTopList(options: any) {
    const COUNTRIES = [
      "Bangladesh",
      "Brazil",
      "India",
      "Pakistan",
      "Philippines",
      "United States",
    ];
    let topList: any = [];

    options.forEach((country: any) => {
      if (COUNTRIES.includes(country.label)) {
        topList.push(country);
      }
    });
    return topList;
  }

  return (
    <LabeledSelect
      placeholder={"Country"}
      input={props.input}
      options={options}
      defaultValue={props.defaultValue}
      selectCallback={(value: string) => {
        setValue(value);
      }}
      topList={extractTopList(options)}
    />
  );
}

export const LabeledInputText = forwardRef(
  ({ placeholder, input, children, width, ...props }: any, ref) => {
    let [filled, setIsFilled] = useState(input.defaultValue ? true : false);
    let [focused, setIsFocused] = useState(false);

    return (
      <>
        {props.error ? (
          <Validation mb={4}>
            {props.error.message ? props.error.message : ""}
          </Validation>
        ) : (
          <Box mb={4}></Box>
        )}
        <Box style={{ position: "relative" }} width={width}>
          <StyledInput
            sx={{
              ...theme.input,
              ...theme.labeledInput,
              ...theme.variants.input.default,
            }}
            ref={ref}
            type={props.type ? props.type : "text"}
            className={filled ? "filled" : ""}
            onChange={(e) => {
              setIsFilled(e.target.value !== "");
            }}
            onFocus={(e) => {
              setIsFocused(true);
            }}
            onBlur={(e) => {
              setIsFocused(false);
            }}
            placeholder={focused || filled ? "" : placeholder}
            {...input}
            {...props}
          />
          <TextFakeLabel error={props.error}>{placeholder}</TextFakeLabel>
          {children}
        </Box>
      </>
    );
  },
);
const TextFakeLabel = styled.div<any>`
  position: absolute;
  color: ${(props) =>
    props.error
      ? props.theme.colors.error
      : props.theme.colors[props.theme.input["::placeholder"].color]};
  opacity: 0;
  visibility: hidden;
  line-height: ${(props) => props.theme.variants.input.default.lineHeight};
  transition:
    font-size ${(props) => props.theme.transitionDuration},
    top ${(props) => props.theme.transitionDuration},
    line-height ${(props) => props.theme.transitionDuration},
    background-color ${(props) => props.theme.transitionDuration};
  top: 0;
  padding: 0 5px;
  left: ${(props) =>
    props.theme.space[props.theme.variants.input.default.px] - 5}px;

  ${StyledInput}:focus + &,
  ${StyledInput}.filled + & {
    background: ${(props) => props.theme.input.bg};
    visibility: visible;
    font-size: 0.8em;
    opacity: 1;
    top: -0.8em;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 3px;
    line-height: 1em;
    box-shadow: 0px 1px 4px
      ${(props) => props.theme.colors[props.theme.input["::boxshadow"].color]};
  }
`;
