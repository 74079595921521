import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import SVG from "react-inlinesvg";

import { Body } from "../../Text";

export const SubText = styled(Body)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #8fa9af;
`;

export const SubTextStrike = styled(SubText)`
  font-size: 18px;
  line-height: 28px;

  // text-decoration-line: line-through;
`;
interface IHighlights {
  highlight?: boolean;
}
const Pointers = styled(SVG)<IHighlights>`
  position: absolute;
  height: 40px;
  width: 160px;
  right: 110px;
  top: 180px;
`;

const BuyButton = styled.button<IHighlights>`
  border: 0;
  border-radius: 8px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0016em;
  color: #f7fcfd;
  padding: 2px 60px 4px 60px;
  background: ${(props) =>
    props.highlight
      ? "linear-gradient(226.68deg, #71D1DD 12.99%, #71ABEE 43.08%)"
      : "#253a49"};
  &:hover {
    cursor: pointer;
    background: #71abee;
  }
  transition: all ease 0.35s;
`;

const CardGrid = styled(Flex)`
  gap: 20px;
  margin-top: 72px;
  margin-bottom: 51px;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Card = styled.div<IHighlights>`
  width: 220px;
  height: 235px;
  border-radius: 20px;
  border: 1px solid transparent;
  position: relative;
  background: ${(props) =>
    props.highlight
      ? `linear-gradient(#1f2d38, #1f2d38) padding-box,
    linear-gradient(to bottom, #71d1dd, #71abee) border-box`
      : "#1f2d38"};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  z-index: 5;
  position: relative;
`;

const CardBackGround = styled.div<IHighlights>`
  position: absolute;
  display: ${(props) => (props.highlight ? "block" : "none")};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  background: linear-gradient(
    226.68deg,
    rgba(113, 209, 221, 0.2) 12.99%,
    rgba(113, 171, 238, 0.2) 43.08%
  );
  z-index: -1;
`;

const ModalBody = styled(Body)`
  text-align: center;
  max-width: 845px;
  color: #8fa9af;
`;

const ModalFooter = styled(Body)`
  text-align: center;
  max-width: 845px;
  color: #8fa9af;
  font-size: 14px;
`;

const ModalTitle = styled.h1<IHighlights>`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0016em;
  color: #f7fcfd;
  ${(props) =>
    props.highlight
      ? `
  background-image: linear-gradient(226.68deg, #71D1DD 12.99%, #71ABEE 43.08%);

  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  `
      : ""};
`;

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 5;
  position: relative;
`;

export {
  BuyButton,
  Pointers,
  Card,
  CardGrid,
  CardBackGround,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Wrap,
};
