import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Flex, Image } from "rebass/styled-components";
import Countdown from "react-countdown";
import styled from "styled-components";
import { HiArrowSmRight, HiSparkles, HiArrowSmDown } from "react-icons/hi";
import { MdStorage, MdOutlineDesignServices } from "react-icons/md";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  CardTitle,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Wrap,
  LeftSide,
  RightSide,
  TopHalf,
  BottomHalf,
  Banner,
  StyledBody,
} from "../styledComponents";
import { Link } from "../../../Button";
import Users from "../../../../Assets/Paywall/userRow.png";
import { ReactComponent as RemixIcon } from "../../../../Assets/Paywall/remixLarge.svg";
import { ReactComponent as StarsIcon } from "../../../../Assets/Paywall/starsRow.svg";

import { ReactComponent as DownloadIcon } from "../../../../Assets/Paywall/downloadLarge.svg";
import { ReactComponent as HistoryIcon } from "../../../../Assets/Paywall/historyLarge.svg";
import { ReactComponent as MobileIcon } from "../../../../Assets/Paywall/mobileLarge.svg";
import { Headline, Subheader, Body, Large2 } from "../../../Text";
import { EPaywallType } from "..";
import { PromoSession } from "../../../../State/promoSession";
import { useIsMobileApp } from "../../../../Hooks/useIsMobileApp";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";
import { UpgradeCta } from "../UpgradeCTA";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const renderer = ({ days, hours, minutes, seconds }: any) => {
  if (hours < 10) hours = `0${hours}`;
  if (days < 10) days = `0${days}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return (
    <>
      <CountdownDate flexDirection={"row"} justifyContent="space-between">
        <DateBackground
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
          width="50px"
        >
          <Flex flexDirection={"row"} justifyContent="center">
            <StyledClockBody>{days}</StyledClockBody>
          </Flex>
          <Subheader>days</Subheader>
        </DateBackground>
        <DateBackground
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
          width="50px"
        >
          <Flex flexDirection={"row"} justifyContent="center">
            <StyledClockBody>{hours}</StyledClockBody>
          </Flex>
          <Subheader>hours</Subheader>
        </DateBackground>
        <DateBackground
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
          width="50px"
        >
          <Flex flexDirection={"row"} justifyContent="center">
            <StyledClockBody>{minutes}</StyledClockBody>
          </Flex>
          <Subheader>mins</Subheader>
        </DateBackground>
        <DateBackground
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
          width="50px"
        >
          <Flex flexDirection={"row"} justifyContent="center">
            <StyledClockBody>{seconds}</StyledClockBody>
          </Flex>
          <Subheader>secs</Subheader>
        </DateBackground>
      </CountdownDate>
    </>
  );
};

export const NoticeBanner = ({ className, children }: any) => {
  return (
    <Banner className={className}>
      <StyledBody>{children}</StyledBody>
    </Banner>
  );
};

export const Paywall_Items = [
  // {
  //   quantity: "5 remixes",
  //   heading: "Upgrade your plan for more Remixes",
  //   meta: { SVG: RemixIcon },
  // },
  // {
  //   quantity: "7 downloads",
  //   heading: "Upgrade your plan for more Downloads",
  //   meta: { SVG: DownloadIcon },
  // },
  {
    quantity: "5",
    heading: "Upgrade to create more Mobile Clips",
    meta: { SVG: MobileIcon },
  },
];

const getPaywallCopy = (allstarModalState: {
  data: {
    tier?: string;
    type?: EPaywallType;
  };
}) => {
  switch (allstarModalState?.data?.type) {
    case EPaywallType.remix:
      return {
        body: "Free accounts are limited to 5 remixes a calendar month. We do this to make sure Allstar stays free, fair and available to everyone.",
        heading: "Upgrade your plan for more Remixes",
        meta: { SVG: <Icon as={RemixIcon} /> },
      };
    case EPaywallType.mobile:
      return {
        body: "Free accounts are limited to 5 mobile clips a calendar month. We do this to make sure Allstar stays free, fair and available to everyone.",
        heading: "Upgrade your plan for more Mobile Clips",
        meta: { SVG: <Icon as={MobileIcon} /> },
      };
    case EPaywallType.download:
      return {
        body: "Free accounts are limited to 7 downloads a calendar month. We do this to make sure Allstar stays free, fair and available to everyone.",
        heading: "Upgrade your plan for more Downloads",
        meta: { SVG: <Icon as={DownloadIcon} /> },
      };

    case EPaywallType.matchHistory:
      return {
        body: "Subscribers currently get Early Access to Match History. Not subscribed? Upgrade today to view and create your past clips!",
        heading: "Upgrade to access your Match History.",
        meta: { SVG: <Icon as={HistoryIcon} /> },
      };

    case EPaywallType.signature:
      return {
        body: "Subscribers get access to lots of bonus perks, like special Signature themes and styles. Become a subscriber now to use this Signature setting!",
        heading: `The style you’ve selected is for Allstar ${allstarModalState?.data?.tier} subscribers.`,
        meta: {
          SVG: (
            <Image
              src={`${REACT_APP_S3_CLIP_BUCKET}/static/loadouts/sig.svg`}
            />
          ),
        },
      };

    case EPaywallType.matchMontage:
      return {
        heading: "Squad Montages are in Early Access",
        body: "This feature is currently in Early Access so that we can address fan feedback and make it better for the entire community before launch. To get Early Access to new features, become an Allstar Subscriber!",
      };

    default:
      return {
        quantity: "7 downloads",
        heading: "Upgrade your plan for more Downloads",
        meta: { SVG: <Icon as={DownloadIcon} /> },
      };
  }
};

type TPaywallProps = {
  type: EPaywallType;
};

export const Paywalls = ({ type }: TPaywallProps) => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const paywallcopy = getPaywallCopy(allstarModalState);
  const { isMobileApp } = useIsMobileApp();

  const promoSession = useRecoilValue(PromoSession);
  const displayPromo = promoSession?.activeSitePaywallPromo;
  const shouldDisplayPromo = Boolean(displayPromo?.endDate);
  const [date, setDate] = useState<any>(() => {
    let converted: any = new Date(displayPromo?.endDate);

    if (converted.toString() === "Invalid Date") {
      const parsedEndDate = parseInt(displayPromo?.endDate);
      if (!isNaN(parsedEndDate)) {
        converted = new Date(parsedEndDate);
      }
    }

    return converted;
  });
  useEffect(() => {
    window.rudderanalytics.track("Paywall - User Paywalled", {
      feature: allstarModalState?.data?.type,
    });
  }, []);
  useEffect(() => {
    let converted: any = new Date(displayPromo?.endDate);

    if (converted.toString() === "Invalid Date") {
      const parsedEndDate = parseInt(displayPromo?.endDate);
      if (!isNaN(parsedEndDate)) {
        converted = new Date(parsedEndDate);
      }
    }

    setDate(converted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayPromo?.endDate]);

  return (
    <Wrap>
      {shouldDisplayPromo && !isMobileApp && (
        <Flex flexDirection={"row"} width="100%">
          <NoticeBanner className="js-sticky-top" hideOnMobile={false}>
            <HideOnMobile flexWrap="wrap">
              <Link
                to={promoSession?.activeSitePaywallPromo?.link}
                onClick={() => {
                  window.rudderanalytics.track("Paywall - Button Click", {
                    type: "upgradeBanner",
                    feature: type,
                  });
                  setAllstarModalState({
                    isOpen: ModalType.None,
                    data: {},
                    functions: {},
                  });
                }}
              >
                {promoSession?.activeSitePaywallPromo?.topBannerCopy}
              </Link>
            </HideOnMobile>
          </NoticeBanner>
        </Flex>
      )}

      <TopHalf>
        <LeftSide>
          <Flex
            flexDirection={["row", "column"]}
            justifyContent={["center", "flex-start"]}
            alignItems={["center", "flex-start"]}
          >
            <ModalTitle>{paywallcopy.heading}</ModalTitle>
          </Flex>
          <ModalBody>{paywallcopy.body}</ModalBody>
          <UserReview>
            <UserRow src={Users} />
            <StarsRow>
              <Stars as={StarsIcon} />
              <StarsText>Loved by 1000's of Allstar Users</StarsText>
            </StarsRow>
          </UserReview>
          {isMobileApp && (
            <UpgradeText>
              Subscriptions are always available at our website Allstar.gg
            </UpgradeText>
          )}
          {!isMobileApp && (
            <ModalFooter>
              No Contract, Cancel any time.{" "}
              <Link
                variant="link.title"
                to="/support"
                onClick={() => {
                  setAllstarModalState({
                    isOpen: ModalType.None,
                    data: {},
                    functions: {},
                  });
                }}
                style={{ color: "#CAD1D3" }}
              >
                Contact us
              </Link>{" "}
              for any feedback.{" "}
            </ModalFooter>
          )}
        </LeftSide>
        {!isMobileApp && (
          <RightSide>
            <UpgradeCta />
          </RightSide>
        )}
      </TopHalf>
      <BottomHalf flexDirection="column">
        <Flex justifyContent={"center"} alignItems="center" mb={3}>
          <ModalFooter>All plans include </ModalFooter>
          <StyledDownArrow />
        </Flex>
        <StyledBG>
          <StyledCenterText>
            <StyledStorage />
            <StyledHeader>Quality & Speed</StyledHeader>
            <StyledSubheader>
              <li>1080P60 Quality Clips</li>
              <li>Priority Fastlane</li>
              <li>Remove Ads in App</li>
            </StyledSubheader>
          </StyledCenterText>
          <StyledCenterText>
            <StyledSparkles />
            <StyledHeader>Perks</StyledHeader>
            <StyledSubheader>
              <li>Profile Badge / Flair</li>
              <li>Private Discord Channel</li>
              <li>Priority Help Service</li>
            </StyledSubheader>
          </StyledCenterText>
          <StyledCenterText>
            <StyledDesign />
            <StyledHeader>Premium Edits</StyledHeader>
            <StyledSubheader>
              <li>Custom Montage Music</li>
              <li>Premium Creator Cards</li>
              <li>Select Montage Loadouts</li>
            </StyledSubheader>
          </StyledCenterText>
        </StyledBG>
      </BottomHalf>
    </Wrap>
  );
};

const CountdownDate = styled(Flex)`
  color: #fff;
  z-index: 2;
  flex-grow: 1;
  width: 60%;
`;

const DateBackground = styled(Flex)`
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 10px;
  &:last-child {
    margin-right: 0px;
  }
`;

const StyledClockBody = styled(Large2)`
  font-weight: 600;
  font-size: 40px;
  color: #65c9d1;
  padding-left: 10px;
  padding-right: 10px;
`;

const HideOnMobile = styled(Flex)`
  border-radius: 10px;
  display: flex;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

const StyledDesign = styled(MdOutlineDesignServices)<any>`
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
  color: #4dbd94;
`;

const StyledSparkles = styled(HiSparkles)<any>`
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
  color: #4dbd94;
`;

const StyledStorage = styled(MdStorage)<any>`
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
  color: #4dbd94;
`;

const StyledHeader = styled(Headline)`
  font-weight: 600;
  color: #f7fcfd;
`;

const StyledSubheader = styled(Subheader)`
  font-weight: 500;
  color: #8fa9af;
  text-align: center;
  list-style-type: none;
  margin-top: 10px;
  line-height: 1.8;
`;

const StyledBG = styled(Flex)`
  background: #18252d;
  width: 100%;
  padding: 30px;
  justify-content: space-between;
  border-radius: 10px;
`;

const StyledCenterText = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const UserReview = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  @media (max-width: 990px) {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }
  @media (max-width: 640px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const StarsRow = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-left: 20px;
  height: 46px;
  @media (max-width: 990px) {
    padding-left: 0px;
    padding-top: 10px;
  }
`;

const UserRow = styled(Image)`
  height: 50px;
  width: 200px;
`;

const MonthText = styled(CardTitle)`
  text-align: left;
  color: #8fa9af;
  font-size: 22px;
  margin-bottom: 2px;
  margin-left: 5px;
`;

const StarsText = styled(ModalFooter)`
  text-align: left;
  color: #cad1d3;
`;

const UpgradeText = styled(ModalFooter)`
  text-align: left;
  color: #d9e3e9;
  font-weight: 500;
`;
const ProText = styled(ModalFooter)`
  text-align: left;
  color: #d9e3e9;
  font-size: 14px;
  margin-bottom: 15px;
  text-decoration: #336b63 wavy underline;
`;

const Icon = styled.svg`
  max-width: 62px;
  max-height: 62px;
`;

const Pro = styled.svg`
  min-width: 28px;
  min-height: 28px;
`;

const Stars = styled.svg`
  min-width: 112px;
  min-height: 21px;
`;

const StyledRightArrow = styled(HiArrowSmRight)<any>`
  height: 14px;
  width: 14px;
  margin-left: 5px;
  margin-top: 2px;
`;

const StyledDownArrow = styled(HiArrowSmDown)<any>`
  height: 14px;
  width: 14px;
  margin-left: 5px;
  margin-top: 2px;
  color: #8fa9af;
`;
