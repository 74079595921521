import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import axios from "axios";
import { useRecoilState } from "recoil";

import { useUser } from "../../Hooks/useUser";
import {
  MatchHistorySession,
  TClipsRequested,
  TMontageRequest,
} from "../../State/matchHistory";
import {
  CHECK_FOR_CLIP,
  GET_MATCH,
  MATCH_HISTORY,
  REFRESH_FACEIT,
  ROLL_SHARE_CODES,
  STEAM_FRIENDS,
} from "../../GraphQL/matchHistory";
import { GET_MY_SIGNATURE } from "../../GraphQL/loadoutSelector";
import { getQueryParam } from "../../Utilities/QueryParam";

export const CS2MatchHistoryViewModel = () => {
  const [endOfList, setEndOfList] = useState(false);
  const [noMatches, setNoMatches] = useState(false);
  const [loading, setLoading] = useState(true);
  const page = getQueryParam("page");
  const [matchHistoryState, setMatchHistoryState] =
    useRecoilState(MatchHistorySession);

  const { allstarUser } = useUser();
  const [getMatchHistory] = useLazyQuery(MATCH_HISTORY, {
    fetchPolicy: "cache-and-network",
  });
  const [getSteamFriends] = useLazyQuery(STEAM_FRIENDS, {
    fetchPolicy: "cache-and-network",
  });
  const [getClip] = useLazyQuery(CHECK_FOR_CLIP, {
    fetchPolicy: "network-only",
  });
  // admin can pass variable "simulate" to simulate any status
  const [rollShareCodes, { loading: rollShareCodesLoading }] = useLazyQuery(
    ROLL_SHARE_CODES,
    { fetchPolicy: "network-only" },
  );
  const [refreshFaceit, { loading: refreshFaceitLoading }] = useMutation(
    REFRESH_FACEIT,
    { fetchPolicy: "network-only" },
  );
  const [getMatch, { loading: getMatchLoading }] = useLazyQuery(GET_MATCH, {
    fetchPolicy: "network-only",
  });
  const [getMySignature] = useLazyQuery(GET_MY_SIGNATURE, {
    fetchPolicy: "network-only",
  });
  const [noSteamError, setNoSteamError] = useState(false);

  useEffect(() => {
    if (!allstarUser?.user?.steam?.steamid) {
      setLoading(false);
      return setNoSteamError(true);
    } else fetch(page ? parseInt(page) : 1);
  }, [page, allstarUser]);

  useEffect(() => {
    if (loading) return;
    getMySignature({
      variables: {
        user: allstarUser?.user?._id,
        signature: true,
      },
    }).then(({ data }) => {
      setMatchHistoryState((prev) => {
        return {
          ...prev,
          hasASignature: !!data?.getLoadouts,
        };
      });
    });
  }, [loading, allstarUser?.user?._id, getMySignature, setMatchHistoryState]);

  const fetch = async (
    page: number,
    filteredFinishedClipFromRequested?: TClipsRequested[],
  ) => {
    if (!matchHistoryState.clipsRequested.length) setLoading(true);

    const { data } = await getMatchHistory({
      variables: {
        steamId: allstarUser?.user?.steam?.steamid,
        page: page,
      },
    });

    const steamFriendsResponse = await getSteamFriends();

    const totalAvailableClips = data.matchHistory.moments.reduce(
      (prev: number, moment: any) => moment.matchMoments.length + prev,
      0,
    );

    if (!data.matchHistory.moments.length) setEndOfList(true);
    if (data.matchHistory.moments.length) setEndOfList(false);
    if (data.matchHistory.matchCount === 0) setNoMatches(true);

    setMatchHistoryState({
      ...matchHistoryState,
      totalMatches: data.matchHistory.matchCount,
      totalAvailableClips,
      currentPageHistory: data.matchHistory.moments,
      clipsRequested: filteredFinishedClipFromRequested ?? [],
      selected: matchHistoryState?.indexOfSelected
        ? data.matchHistory.moments[matchHistoryState?.indexOfSelected]
        : data.matchHistory.moments[0],
      page,
      steamFriends: steamFriendsResponse.data?.steamFriends ?? [],
    });
    setLoading(false);
  };

  const requestClip = async (
    matchId: string,
    steamId: string,
    round: number,
  ) => {
    const clipReq = `${process.env.REACT_APP_AWS_SERVERLESS}/webhook/clip-request`;
    const clipRequestState = { matchId, steamId, round, ts: Date.now() };

    try {
      await axios.post(`${clipReq}?token=${localStorage.getItem("token")}`, {
        matchId,
        steamId: allstarUser?.user.steam?.steamid,
        round,
      });
    } catch (error) {
      console.log("error, requestClip", error);
    }

    setMatchHistoryState({
      ...matchHistoryState,
      clipsRequested: [...matchHistoryState.clipsRequested, clipRequestState],
    });
  };

  const onRequestedMontage = async (request: TMontageRequest) => {
    setMatchHistoryState({
      ...matchHistoryState,
      montagesRequested: [...matchHistoryState.montagesRequested, request],
    });
  };

  const clipCompletedProcessing = ({
    filteredFinishedClipFromRequested,
  }: any) => {
    fetch(page ? parseInt(page) : 1, filteredFinishedClipFromRequested);
  };

  return {
    allstarUser,
    clipCompletedProcessing,
    endOfList,
    fetch,
    getClip,
    getMatch,
    getMatchLoading,
    loading,
    noMatches,
    noSteamError,
    page,
    refreshFaceit,
    refreshFaceitLoading,
    requestClip,
    onRequestedMontage,
    rollShareCodes,
    rollShareCodesLoading,
  };
};
