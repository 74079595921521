import React from "react";
import { useHistory } from "react-router-dom";
import { Headline, Title1, Title2 } from "../../text";
import { LoadingPlayButton } from "./loadingPlayButton";
import { Avatar } from "../../atoms/avatar";
import { Flex } from "rebass/styled-components";
import styled from "styled-components";
import { getClipTitle, getSnapshotPath } from "../../molecules/clip/utils";
import { getAvatarImage } from "../../../MVVM/Utilities/User";

let Clip = styled(Flex)`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  border-radius: 4px;
  width: 400px;
  height: 244px;
  padding: ${(props) => props.theme.space[3]}px;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    background: none;
  }
`;

const NextClipLayover = styled(Flex)`
  position: absolute;
  background: rgba(0, 0, 0, 0.84);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
  z-index: 2;

  &.nextClipSoon {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s, visibility 0s 0s;
  }
`;

const Cancel = styled(Title2)`
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

const UpNextTitle = styled(Title1)`
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

const UserRow = styled(Flex)`
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

export function UpNextPreview({
  clip,
  nextClipSoon,
  setNextClipSoon,
  ...props
}) {
  let history = useHistory();

  return (
    <NextClipLayover className={nextClipSoon ? "nextClipSoon" : false}>
      {nextClipSoon ? (
        <Flex flexDirection="column" alignItems="center" {...props.className}>
          <UpNextTitle mb={7}>Up Next</UpNextTitle>
          <Clip backgroundImage={getSnapshotPath(clip.clipImageThumb)} mb={5}>
            <UserRow>
              <Avatar size="tiny" image={getAvatarImage(clip.user)} mr={3} />
              <Headline>{getClipTitle(clip)}</Headline>
            </UserRow>
            <Flex flex="1" justifyContent="center" alignItems="center">
              <LoadingPlayButton
                onClick={() => {
                  history.push(`/clip?clip=${clip.shareId}`);
                }}
              />
            </Flex>
          </Clip>
          <Cancel
            onClick={() => {
              return setNextClipSoon(false);
            }}
          >
            Cancel
          </Cancel>
        </Flex>
      ) : (
        <></>
      )}
    </NextClipLayover>
  );
}
